import React, {CSSProperties, ReactElement, useEffect, useState} from 'react'
import PageLoader from '../../../common/component/PageLoader'
import {apiRequest, PATH_RACE_DELETE, PATH_RACE_GET_BY_EVENT} from '../../../common/backend'
import M from 'materialize-css'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ML from '../../../common/multilang'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography} from '@mui/material'
import {Add, DeleteForever, Edit} from '@mui/icons-material'
import LocalizedString from "../../../common/component/LocalizedString";

interface RaceRowProps {
    race: Race,
    toRemove: () => void
}

const RaceRow = ({race, toRemove}: RaceRowProps): ReactElement => {
    const {t} = useTranslation()
    const status: string[] = [
        'race_body.status.draft',
        'race_body.status.active',
        'race_body.status.blocked',
        'race_body.status.ended'
    ]
    const statusText: string = race.deleted_by === -1 ? status[race.status] : 'race_body.status.deleted'
    const tooltipText: ReactElement | null = race.deleted_by === -1 ? null :
        <Typography>{t('race_body.status.deleted')}</Typography>

    const deletedStyle: CSSProperties = {}
    if (race.deleted_by !== -1) {
        deletedStyle.backgroundColor = '#ab0d0d'
        deletedStyle.borderRadius = '10px'
        deletedStyle.padding = '10px'
    }

    return <tr>
        <td>
            <LocalizedString str={race.name}/>
        </td>
        <td style={deletedStyle}>{t(statusText)}</td>
        <td>{race.date_start}</td>
        <td className="center">
            <IconButton size="large" color="primary" component={Link} to={`/admin/events/race/edit/${race.id}`}>
                <Edit/>
            </IconButton>
        </td>
        <Tooltip title={tooltipText} placement='top'>
            <td className="center">
                <IconButton disabled={race.deleted_by !== -1} size='large' color='error' onClick={toRemove}>
                    <DeleteForever/>
                </IconButton>
            </td>
        </Tooltip>
    </tr>
}

interface RacesPageProps {
    eventId: number
}

const RacesPage = ({eventId}: RacesPageProps): ReactElement => {
    const [removeRace, setRemoveRace] = useState<Race | null>(null)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [races, setRaces] = useState<Race[] | null>(null)

    const {t, i18n} = useTranslation()

    const loadRaces = (): void => {
        apiRequest(PATH_RACE_GET_BY_EVENT, (res: { data: { races: Race[] } }) => {
            setRaces(res.data.races)
            setLoading(false)
            // @ts-ignore
        }, undefined, {'target-event': eventId})
    }

    const removeRaceAction = (): void => {
        setLoading(true)
        apiRequest(PATH_RACE_DELETE + removeRace!!.id, (): void => {
            loadRaces()
            M.toast({
                html: t('event.races.delete_modal.race_deleted', {
                    name: ML.getStringForLanguage({
                        multilangString: removeRace!!.name,
                        returnFirst: true,
                        language: i18n.language
                    })
                }),
                classes: 'rounded green large'
            })
            // @ts-ignore
        }, (): void => {
            setLoading(false)
            M.toast({
                html: t('event.races.delete_modal.race_delete_error', {
                    name: ML.getStringForLanguage({
                        multilangString: removeRace!!.name,
                        returnFirst: true,
                        language: i18n.language
                    })
                }), classes: 'rounded red large'
            })
        }, undefined, 'delete')
    }

    useEffect(() => {
        if (races === null) {
            loadRaces()
        }
    }, [eventId])

    if (races === null) {
        return <PageLoader/>
    }

    return <>
        {isLoading ? <PageLoader/> : null}
        <Dialog open={removeRace !== null} onClose={() => setRemoveRace(null)}>
            <DialogTitle>
                {t('event.races.delete_modal.header')}
            </DialogTitle>
            <DialogContent>
                {t('event.races.delete_modal.text')} <LocalizedString str={removeRace?.name ?? ''}/>?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setRemoveRace(null)}>
                    {t('event.races.delete_modal.cancel_btn')}
                </Button>
                <Button onClick={removeRaceAction}>
                    {t('event.races.delete_modal.delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
        <div style={{
            maxWidth: '100%',
            width: '70%',
            margin: 'auto'
        }}>
            <div className="row">
                <div className="col s12" style={{textAlign: 'center'}}>
                    <h4>{t('event.races.header')}</h4>
                    <h4>
                        <IconButton component={Link} to={`/admin/events/race/create/${eventId}`}>
                            <Add/>
                        </IconButton>
                    </h4>
                    <table>
                        <thead>
                        <tr>
                            <th>{t('event.races.table.name')}</th>
                            <th>{t('event.races.table.status')}</th>
                            <th>{t('event.races.table.date_start_at')}</th>
                            <th className="center">{t('event.races.table.edit')}</th>
                            <th className="center">{t('event.races.table.delete')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {races?.map((it: Race): ReactElement => {
                            return <RaceRow key={it.id} race={it} toRemove={(): void => setRemoveRace(it)}/>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}

export default RacesPage