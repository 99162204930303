import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import RacesPage from './inner_page/RacesPage'
import EditPage from './EditPage'
import PromocodePage from './inner_page/promocode/PromocodePage'
import List from '../participant/list/List'
import { useTranslation } from 'react-i18next'

/**
 * @typedef Page
 * @property {string} name
 * @property {function} component
 */
/**
 * @typedef {Object.<string, Page>} PageList
 */

/**
 * @type PageList
 */
const page = {
  '': { name: 'event', component: EditPage },
  'races': { name: 'races', component: RacesPage },
  'promo': { name: 'promocodes', component: PromocodePage },
  'participant': { name: 'participants', component: List }
}

const Event = () => {
  const targetEventId = useParams().id
  const { t } = useTranslation()

  let { hash } = useLocation()
  hash = hash.replace('#', '')

  const [currentPage, setCurrentPage] = useState(null)
  useEffect(() => {
    const Page = page[hash].component
    setCurrentPage(<Page eventId={parseInt(targetEventId)}/>)
  }, [hash])

  return <div className="center">
    <div style={{
      maxWidth: '100%',
      width: '80%',
      margin: 'auto'
    }}>
      <h4>{t('event.edit.header', { id: String(targetEventId) })}</h4>
      <br/>
      <nav className="nav-extended">
        <div className="nav-content">
          <ul className="tabs tabs-transparent">
            {Object.entries(page).map(([link, el]) => <li key={link} className={link === hash ? 'tab active' : 'tab'}>
              <a className={link === hash ? 'active' : ''} href={'#' + link}>{t('event.nav.' + el.name)}</a>
            </li>)}
            {/*<li key="mailing" className="tab">
              <Link to="">Рассылка</Link>
            </li>*/}
          </ul>
        </div>
      </nav>
      <br/>
      {currentPage}
    </div>
  </div>
}

export default Event
