import {ChangeEvent, useEffect, useState} from 'react'
import M from 'materialize-css'
import {CommonInputProps} from "./DefaultInput";

const EmailInput = ({change, value, immutable}: CommonInputProps) => {

    return <>
        <i className="material-icons prefix">textsms</i>
        <input readOnly={immutable} onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            change('email', e.target.value)
        }} autoComplete="none" required={true}
               id={`input-email-${immutable ? 'edit' : 'create'}`} type="email" defaultValue={value as string}/>
        <label className={immutable ? 'active' : ''}
               htmlFor={`input-email-${immutable ? 'edit' : 'create'}`}>Email</label>
    </>
}
export default EmailInput