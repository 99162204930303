import Body from './Body'
import $ from 'jquery'
import React, { useEffect, useRef, useState } from 'react'
import {
  apiRequest,
  backendAddress,
  getErrorMessage,
  PATH_EVENT_UPDATE,
  PATH_EVENT_UPDATE_IMAGE,
  PATH_EVENT_UPDATE_REGULATION,
  PATH_EVENT_VIEW
} from '../../common/backend'
import M from 'materialize-css'
import axios from 'axios'
import PageLoader from '../../common/component/PageLoader'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { isString } from 'lodash'
import useML from '../../hook/UseML'

const EditPage = (props) => {
  const targetEventId = props.eventId

  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()

  const state = useRef(null)
  const image = useRef({
    image: '/no_image.png',
    background: '/no_image.png',
    background_mobile: '/no_image.png'
  })
  const descriptionRef = useRef()
  const scheduleRef = useRef()

  const errRefs = {
    'name': useRef(),
    'sponsor': useRef(),
    'description': useRef(),
    'city': useRef(),
    'address': useRef(),
    'date_register_start': useRef(),
    'date_register_end': useRef(),
    'date_start_at': useRef()
  }

  const loadEventData = () => {
    apiRequest(PATH_EVENT_VIEW + targetEventId, (response) => {
        if (response.data['image']) {
          image.current = {
            image: response.data['image'],
            background: response.data.background || image.current.background,
            background_mobile: response.data.background_mobile || image.current.background_mobile,
          }
        }
        delete response.data['image']
        delete response.data['background']
        delete response.data['background_mobile']
        state.current = response.data
        setLoading(false)
      }, () => {
        setLoading(false)
      }, undefined, 'get'
    )
  }

  const handleUpdate = (e) => {
    e.preventDefault()

    setLoading(true)
    const promises = Promise.all([descriptionRef.current.save(null, null, state.current.race), scheduleRef.current.save(null, null, state.current.race)])
    promises.then(() => {
      // state.current.description = el.innerHTML
      const data = qs.stringify(state.current)
      apiRequest(PATH_EVENT_UPDATE + targetEventId, () => {
        $('#event-body input').removeClass('valid').removeClass('invalid')
        setLoading(false)
        M.toast({ html: t('event.edit.saving_success'), classes: 'rounded green large' })
      }, r => {
        for (const err of r.response.data) {
          console.log(err['field'] + ' - ' + err['message'])
          $(errRefs[err['field']].current).attr('data-error', err['message'])
          $('#event-body input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
        }
        setLoading(false)
        M.toast({ html: t('event.edit.saving_fail'), classes: 'rounded red large' })
      }, data, 'put', { 'content-type': 'application/x-www-form-urlencoded' })
    })
  }

  // noinspection DuplicatedCode
  const handleChange = (name, e) => {
    let value = isString(e) ? e : $(e.target).val()
    if (name.includes('date')) {
      value = value + ' ' + state.current[name].split(' ')[1]
    }
    if (name.includes('time')) {
      name = name.replace('time', 'date')
      value = state.current[name].split(' ')[0] + ' ' + value + ':00'
    }

    state.current[name] = value
  }

  const setImage = (e) => {
    setLoading(true)
    const ratios = {
      image: ['4/3', 4 / 3],
      background: ['16/9', 16 / 9],
      background_mobile: ['9/16', 9 / 16]
    }
    const file = e.target.files[0]
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      const aspectRatio = img.width / img.height
      const isAspectRatioValid = Math.abs(aspectRatio - (ratios[e.target.name][1])) < 0.01
      if (isAspectRatioValid) {
        uploadImage(file, e.target.name)
      } else {
        setLoading(false)
        M.toast({
          html: t('event.edit.image.aspect_ratio_error', { ratio: ratios[e.target.name][0] }),
          classes: 'rounded red large'
        })
      }
      img.remove()
    }
  }

  const setRegulations = (e) => {
    setLoading(true)

    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('language', language)
    setLoading(true)
    axios.post(backendAddress + PATH_EVENT_UPDATE_REGULATION + targetEventId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('api-key')
      }
    }).then(res => {
      state.current.regulation = res.data.link
      M.toast({ html: t('event.edit.regulations_uploading.success'), classes: 'rounded green large' })
    }).catch(e => {
      M.toast({
        html: t('event.edit.regulations_uploading.error') + ': ' + getErrorMessage(e),
        classes: 'rounded red large'
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const uploadImage = (file, type) => {
    const formData = new FormData()
    formData.append(type, file)
    setLoading(true)
    axios.post(backendAddress + PATH_EVENT_UPDATE_IMAGE + targetEventId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('api-key')
      }
    }).then(res => {
      setLoading(false)
      if (res.status === 200) {
        M.toast({ html: t('event.edit.image_uploading.success'), classes: 'rounded green large' })
        image.current = {
          image: res.data.images.image,
          background: res.data.images.background || image.current.background,
          background_mobile: res.data.images.background_mobile || image.current.background_mobile,
        }
      } else {
        M.toast({ html: t('event.edit.image_uploading.error'), classes: 'rounded red large' })
      }
    }).catch((e) => {
      console.log(e)
      setLoading(false)
      M.toast({ html: t('event.edit.image_uploading.error'), classes: 'rounded red large' })
    }).finally(() => {
      document.querySelector(`img#${type}-img`).src = image.current[type] + '?' + new Date().getTime()
    })
  }

  useEffect(() => {
    if (state.current === null) {
      loadEventData()
    }
  })

  const { availableLanguages, language, setLanguage } = useML()

  // Нужно для корректной работы defaultValue: не рендерить элемент пока его значение неизвестно
  if (state.current === null || isLoading || availableLanguages === null) {
    return <PageLoader/>
  }

  return <form id="event-body" onSubmit={handleUpdate.bind(this)} action="#">
    <span>
      <select className="browser-default" value={language} onChange={e => setLanguage(e.target.value)}>
        {(availableLanguages || []).map(it => <option value={it} key={it}>{it}</option>)}
      </select>
    </span>
    <Body language={language} scheduleRef={scheduleRef} descriptionRef={descriptionRef} state={state} errRefs={errRefs}
          handleChange={handleChange}/>
    <br/>
    <div className="row">
      <div className="input-field col s12">
        <div className="input-field col s2">
          <select className="browser-default" id="visibility" name="visibility"
                  onChange={handleChange.bind(this, 'visibility')} defaultValue={state.current['visibility']}>
            <option key="status-1" value={0}>{t('event.edit.visibility.everywhere')}</option>
            <option key="status-2" value={1}>{t('event.edit.visibility.only_site')}</option>
            <option key="status-0" value={2}>{t('event.edit.visibility.only_app')}</option>
          </select>
          <label htmlFor="visibility" className="active">{t('event.edit.visibility')}</label>
        </div>
        <div className="input-field col s2">
          <select className="browser-default" id="status" name="status"
                  onChange={handleChange.bind(this, 'status')} defaultValue={state.current['status']}>
            <option key="status-1" value={0}>{t('event.edit.status.draft')}</option>
            <option key="status-2" value={1}>{t('event.edit.status.active')}</option>
            <option key="status-0" value={2}>{t('event.edit.status.blocked')}</option>
          </select>
          <label htmlFor="status" className="active">{t('event.edit.status')}</label>
        </div>
        <div className="input-field col s2">
          <input onChange={setRegulations} accept="application/pdf" type="file" name="regulations" id="regulations"/>
          <label htmlFor="regulations" className="active">{t('event.edit.regulations')}</label>
        </div>
        <div className="col s4">
          {state.current.regulation[language] ? <><span>{t('event.edit.regulations.current_file')} </span>
              <a target="_blank"
                 href={state.current.regulation[language]}>{t('event.edit.regulations.open_current_file_btn')}</a>
            </> :
            <span>{t('event.edit.regulations.not_loaded')}</span>
          }
        </div>
      </div>
    </div>
    <div className="row">
      <div className="input-field col s4">
        <p>{t('event.edit.image.main')}</p>
        <img id="image-img" onClick={() => $('#select-image').click()}
             src={image.current.image + '?' + new Date().getTime()}
             alt={t('event.edit.image.main')}
             style={{ width: 'inherit', cursor: 'pointer' }}/>
        <input hidden="hidden" id="select-image" onChange={setImage.bind(this)} name="image" type="file"
               accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"/>
      </div>
      <div className="input-field col s4">
        <p>{t('event.edit.image.background')}</p>
        <img id="background-img" onClick={() => $('#select-background').click()}
             src={image.current.background + '?' + new Date().getTime()}
             alt={t('event.edit.image.background')}
             style={{ width: 'inherit', cursor: 'pointer' }}/>
        <input hidden="hidden" id="select-background" onChange={setImage.bind(this)} name="background" type="file"
               accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"/>
      </div>
      <div className="input-field col s4">
        <p>{t('event.edit.image.background_mobile')}</p>
        <img id="background_mobile-img" onClick={() => $('#select-background_mobile').click()}
             src={image.current.background_mobile + '?' + new Date().getTime()}
             alt={t('event.edit.image.background_mobile')}
             style={{ width: 'inherit', cursor: 'pointer' }}/>
        <input hidden="hidden" id="select-background_mobile" onChange={setImage.bind(this)} name="background_mobile"
               type="file"
               accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"/>
      </div>
    </div>
    <div className="input-field">
      <button type="submit" className="btn btn-large btn-register waves-effect waves-light"
              name="action">
        {t('event.edit.save_btn')}
        <i className="material-icons right">done</i>
      </button>
    </div>
  </form>
}
export default EditPage
