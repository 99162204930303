import {Pair} from "../common/crypt";

export default (): void => {
    // @ts-ignore
    if (typeof importScripts === 'function') {
        // @ts-ignore
        importScripts('https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.2.0/crypto-js.min.js');
    }

    // eslint-disable-next-line no-restricted-globals
    addEventListener('message', ({data}: MessageEvent<{ key: Pair<string, string>, data: string }>): void => {
        const base64 = CryptoJS.enc.Base64

        const decryptedData: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
            data.data,
            base64.parse(data.key.first),
            {iv: base64.parse(data.key.second)}
        )
        postMessage({decryptedData: base64.stringify(decryptedData)})
    })
}

export type {}
export {}
