import React, {Context, ReactElement, useContext, useEffect, useState} from 'react'
import {apiRequest, PATH_MY_NAME} from '../common/backend'

const AuthContext: Context<AuthContext | null> = React.createContext<AuthContext | null>(null)

const setAPIKey = (key: string): void => localStorage.setItem('api-key', key)

const resetAPIKey = (): void => localStorage.removeItem('api-key')

const getAPIKey = (): string | null => localStorage.getItem('api-key')

const useAuth = (): AuthContext => {
    const context: AuthContext | null = useContext(AuthContext)
    if (!context) throw new Error('useAuth must be used inside AuthContext')
    return context
}

interface AuthContextProviderProps {
    children: ReactElement | ReactElement[]
}

interface AuthState {
    authorized: boolean | null,
    user: User | null
}

const AuthContextProvider = ({children}: AuthContextProviderProps): ReactElement => {
    const [authState, setAuthState2] = useState<AuthState>({authorized: null, user: null})

    useEffect((): void => {
        checkLogin()
    }, [])

    const setAuthState = (state: AuthState): void => {
        if (state.user !== null) {
            state.user.isAdmin = state.user.role !== 0
        }
        setAuthState2(state)
    }

    const checkLogin = (): void => {
        if (getAPIKey() === null) {
            setAuthState({authorized: false, user: null})
            return
        }
        if (authState.authorized === false) {
            return
        }
        apiRequest(PATH_MY_NAME, (res: { data: { success: any; user: any } }): void => {
            if (res.data.success) {
                setAuthState({authorized: true, user: res.data.user})
            }
            // @ts-ignore
        }, e => {
            if (e.status === 403) {
                resetAPIKey()
            }
            setAuthState({authorized: false, user: null})
        })
    }

    const logout = (): void => {
        resetAPIKey()
        checkLogin()
    }

    return <AuthContext.Provider value={{...authState, logout, checkLogin}} children={children}/>
}

export default AuthContextProvider
export {
    useAuth,
    setAPIKey,
    getAPIKey,
    resetAPIKey
}
