import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEBUG, npGetAreas, npGetCities, npGetWarehouses } from '../backend'
import $ from 'jquery'
import { isEmpty } from 'lodash'

const Selector = ({ list, defaultValue, onChange, name, required }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
    if (list !== null) {
      onChange({ target: { value: isEmpty(value) ? defaultValue : value }, preventDefault: () => {} })
    }
  }, [list])

  return <select key={list?.length ?? '0'} name={t(`novaposhta.${name}`)} onChange={(e) => {
    setValue(e.target.value)
    onChange(e)
  }} value={value} required={required} className="browser-default">
    <option key="" value="">{list === null ? 'Loading...' : t(`novaposhta.${name}`)}</option>
    {list ?? <></>}
  </select>
}

const NovaposhtaSelect = (props) => {
  const { t } = useTranslation()

  const [areas, setAreas] = useState(null)
  const [cities, setCities] = useState(null)
  const [warehouses, setWarehouses] = useState(null)
  const loading = useRef(false)

  const loadAreas = () => {
    loading.current = true
    npGetAreas(function (data) {
      let list = []
      for (const [ref, name] of Object.entries(data).sort(([, a], [, b]) => a.localeCompare(b))) {
        list.push(<option key={ref} value={ref}>{name}</option>)
      }
      setAreas(list)
      loading.current = false
      if (props.areaDefault) {
        areaChange({ target: document.querySelector('select[name="np-area"]') })
      }
    })
  }

  useEffect(() => {
    if (areas === null && !loading.current) loadAreas()
  })

  const areaChange = (e) => {
    console.log('Area', e.target.value)
    if (loading.current) {
      e.preventDefault()
      if (DEBUG) console.log('Trying to change AREA while loading')
      return
    }
    $('select[name="np-city"]').css({ pointerEvents: 'none' })
    loading.current = true
    setWarehouses(null)
    setCities(null)

    npGetCities(function (data) {
      let list = []
      for (const [key, value] of Object.entries(data)) {
        list.push(<option key={key} value={key}>{value}</option>)
      }
      setCities(list)
      $('select[name="np-city"]').css({ pointerEvents: 'auto' })
      loading.current = false
      if ($('option[value="' + props.cityDefault + '"]').length > 0) {
        cityChange({ target: document.getElementsByName('np-city')[0] })
      }
    }, e.target.value)
    props.areaOnChange(e)
  }

  const cityChange = (e) => {
    console.log('City', e.target.value)
    if (loading.current) {
      e.preventDefault()
      if (DEBUG) console.log('Trying to change CITY while loading')
      return
    }
    $('select[name="np-warehouses"]').css({ pointerEvents: 'none' })
    loading.current = true
    setWarehouses(null)
    npGetWarehouses(function (data) {
      let list = []
      // console.log(Object.entries(data))
      for (const [key, value] of Object.entries(data)) {
        list.push(<option key={key} value={key}>{value}</option>)
      }
      setWarehouses(list)
      $('select[name="np-warehouses"]').css({ pointerEvents: 'auto' })
      loading.current = false
    }, e.target.value)
    props.cityOnChange(e)
  }

  return (
    <div className="row">
      <div className="col s12">
        {t('novaposhta.novaposhta')}
      </div>

      <div className="input-field col s4">
        <Selector list={areas} required={props.required ?? true} onChange={areaChange} name="region"
                  defaultValue={props.areaDefault ?? ''}/>
      </div>
      <div className="input-field col s4">
        <Selector list={cities} required={props.required ?? true} onChange={cityChange} name="city"
                  defaultValue={props.cityDefault ?? ''}/>
      </div>
      <div className="input-field col s4">
        <Selector list={warehouses} required={props.required ?? true} onChange={props.warehouseOnChange}
                  name="postal_office"
                  defaultValue={props.warehouseDefault ?? ''}/>
      </div>
    </div>
  )
}

export default NovaposhtaSelect
