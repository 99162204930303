import React, { useEffect } from 'react'
import $ from 'jquery'
import M from 'materialize-css'
import { useTranslation } from 'react-i18next'
import { useCurrencyList } from '../../context/CurrencyListContext'
import TranslatableTextInput from '../../common/component/TranslatableTextInput'

/* Базовые инпуты для страницы создания/редактирования забега */
const Body = (props) => {
  const { t } = useTranslation()
  /**
   * В запросе к при указании даты передается строка вида 2000-12-31 24:00:00
   * @return часы и минуты от строки с датой
   */
  const getTime = (str) => {
    return str.split(' ')[1]
  }

  const currencies = useCurrencyList()

  useEffect(() => {
    /** https://materializecss.com/pickers.html */
    $('.datepicker').each((_, it) => {
      M.Datepicker.init($(it), {
        format: 'yyyy-mm-dd',
        defaultDate: new Date(props.state.current[$(it).attr('name')].split(' ')[0]),
        setDefaultDate: true,
        minDate: new Date(),
        i18n: {
          months: [t('month.january'), t('month.february'), t('month.march'), t('month.april'), t('month.may'), t('month.june'), t('month.july'), t('month.august'), t('month.september'), t('month.october'), t('month.november'), t('month.december')],
          monthsShort: [t('month.january.short'), t('month.february.short'), t('month.march.short'), t('month.april.short'), t('month.may.short'), t('month.june.short'), t('month.july.short'), t('month.august.short'), t('month.september.short'), t('month.october.short'), t('month.november.short'), t('month.december.short')],
          weekdays: [t('weekday.sunday'), t('weekday.monday'), t('weekday.tuesday'), t('weekday.wednesday'), t('weekday.thursday'), t('weekday.friday'), t('weekday.saturday')],
          weekdaysShort: [t('weekday.sunday.short'), t('weekday.monday.short'), t('weekday.tuesday.short'), t('weekday.wednesday.short'), t('weekday.thursday.short'), t('weekday.friday.short'), t('weekday.saturday.short')],
          weekdaysAbbrev: [t('weekday.sunday.abbrev'), t('weekday.monday.abbrev'), t('weekday.tuesday.abbrev'), t('weekday.wednesday.abbrev'), t('weekday.thursday.abbrev'), t('weekday.friday.abbrev'), t('weekday.saturday.abbrev')],
          cancel: t('cancel')
        }
      })
      //onChange that was set in the html element below don`t work
      $(it).change((event) => props.handleChange($(it).attr('name'), event))
    })
  })

  const language = props.language

  return <div style={{
    maxWidth: '100%',
    margin: 'auto'
  }}>
    <div className="row">
      {/*<input onChange={props.handleChange.bind(this, 'name')} minLength="3" maxLength="255"
             className="validate" required="required" type="text"
             id="name" name="name" defaultValue={props.state.current['name']}/>
      <label htmlFor="name" className="active">{t('race_body.name')}</label>
      <span ref={props.errRefs['name']} className="helper-text"
            data-error={t('race_body.name_error')}/>*/}
      <TranslatableTextInput initialValue={props.state.current['name'] ?? ''}
                             classes="input-field col s4 center-align"
                             update={(it) => props.state.current['name'] = it}
                             language={language} name={t('race_body.name')}
      />
      <TranslatableTextInput initialValue={props.state.current['name_short'] ?? ''}
                             classes="input-field col s2 center-align"
                             update={(it) => props.state.current['name_short'] = it}
                             language={language} name={t('race_body.name_short')}
      />
      <div className="input-field col s2 center-align">
        <input onChange={props.handleChange.bind(this, 'distance')} maxLength="255"
               className="validate" type="number" step="0.00001" min="0"
               id="distance" name="distance" defaultValue={props.state.current['distance']}/>
        <label htmlFor="distance" className="active">{t('race_body.distance')}</label>
        <span ref={props.errRefs['distance']} className="helper-text"
              data-error={t('race_body.distance_error')}/>
      </div>
      <div className="input-field col s2 center-align">
        <input onChange={props.handleChange.bind(this, 'member_prefix')} minLength="3" maxLength="255"
               className="validate" required="required" type="number" min="0"
               id="member_prefix" name="member_prefix" defaultValue={props.state.current['member_prefix']}/>
        <label htmlFor="member_prefix" className="active">{t('race_body.participant_prefix')}</label>
        <span ref={props.errRefs['member_prefix']} className="helper-text"
              data-error={t('race_body.participant_prefix_error')}/>
      </div>
    </div>
    <br/>
    <div className="row">
      <div className="input-field col s2 center-align">
        <label>
          <input onChange={e => {
            if (e.target.checked) {
              $('div#team-max').show()
              $('input#team_max').val(2).attr('min', '2').addClass('validate').attr('required', 'required').change()
            } else {
              $('div#team-max').hide()
              $('input#team_max').val(-1).removeClass('validate').removeAttr('required').removeAttr('min').change()
            }
            props.handleChange('team_max', { target: document.querySelector('input#team_max') })
          }} value="1" type="checkbox" id="is_team"
                 name="is_team" defaultChecked={props.state.current['team_max'] > 1}/>
          <span>{t('race_body.is_team_race')}</span>
        </label>
      </div>
      <div id="team-max" className="input-field col s2 center-align"
           style={props.state.current['team_max'] > 1 ? {} : { display: 'none' }}>
        <input onChange={function (e) {
          if (parseInt(e.target.value) !== -1 && (parseInt(e.target.value) < 2 || !parseInt(e.target.value))) {
            e.target.value = '2'
          }
          return props.handleChange('team_max', e)
        }}
               minLength="1" maxLength="255"
               className="validate" required="required" type="number"
               id="team_max" name="team_max" defaultValue={props.state.current['team_max']}/>
        <label htmlFor="team_max" className="active">{t('race_body.team_size')}</label>
        <span ref={props.errRefs['team_max']} className="helper-text"
              data-error={t('race_body.team_size_error')}/>
      </div>
      <div className="input-field col s2 center-align">
        <label>
          <input {...(props.edit ?? false ? { checked: props.state.current['is_child'] } : { defaultChecked: props.state.current['is_child'] })}
                 onChange={e => {
                   if (props.edit ?? false) {
                     e.preventDefault()
                     return
                   }
                   props.handleChange('is_child', e.target.checked)
                 }} type="checkbox" id="is_child"
                 name="is_child"/>
          <span>{t('race_body.is_child')}</span>
        </label>
      </div>
    </div>
    <br/>
    <div className="row">
      <div className="input-field col s2">
        <input type="number" min="1" onChange={props.handleChange.bind(this, 'members_max')} required="required"
               className="validate"
               id="members_max" name="members_max" defaultValue={props.state.current['members_max']}/>
        <label htmlFor="members_max" className="active">{t('race_body.max_participants')}</label>
        <span ref={props.errRefs['members_max']} className="helper-text"
              data-error={t('race_body.max_participants_error')}/>
      </div>
      <div className="col s2">
        <span>{t('race_body.promo_price')}</span>
        {currencies.map((it) => {
          return <div key={it} className="input-field">
            <input type="number" min="0" onChange={event => {
              const current = JSON.parse(props.state.current['price_promo'])
              current[it] = event.target.value
              props.handleChange('price_promo', JSON.stringify(current))
            }} className="validate" id="price_promo" name="price_promo"
                   defaultValue={JSON.parse(props.state.current['price_promo'])[it]}/>
            <label htmlFor="price_promo" className="active">{it}</label>
            <span ref={props.errRefs['price_promo']} className="helper-text"
                  data-error={t('race_body.promo_price_error')}/>
          </div>
        })}
      </div>
      <div className="input-field col s2">
        <input onChange={props.handleChange.bind(this, 'date_start')} minLength="3" maxLength="255"
               className="validate" required="required"
               type="date"
               id="date_start" name="date_start"
               defaultValue={props.state.current['date_start'].split(' ')[0]}/>
        <label htmlFor="date_start" className="active">{t('race_body.start_date')}</label>
        <span ref={props.errRefs['date_start']} className="helper-text"
              data-error={t('race_body.start_date_error')}/>
      </div>
      <div className="input-field col s1">
        <input onChange={props.handleChange.bind(this, 'time_start')} minLength="3" maxLength="255"
               className="validate" required="required"
               id="time_start" name="time_start"
               defaultValue={getTime(props.state.current['date_start'])}/>
      </div>
      <div className="input-field col s1"/>
      <div className="input-field col s4">
        <select defaultValue={props.state.current['status']} onChange={props.handleChange.bind(this, 'status')}
                className="validate browser-default" required="required"
                id="status" name="status">
          <option value={0}>{t('race_body.status.draft')}</option>
          <option value={1}>{t('race_body.status.active')}</option>
          <option value={2}>{t('race_body.status.blocked')}</option>
          <option value={3}>{t('race_body.status.ended')}</option>
        </select>
        <label htmlFor="status" className="active">{t('race_body.status')}</label>
        <span ref={props.errRefs['status']} className="helper-text"
              data-error={t('race_body.status_error')}/>
      </div>
    </div>
  </div>
}

export default Body
