import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import PageLoader from '../../common/component/PageLoader'
import {
  apiRequest,
  getErrorMessage,
  PATH_RACE_UPDATE,
  PATH_RACE_VIEW,
  PATH_TEXT_BLOCK_GET_BY_RACE
} from '../../common/backend'
import { useLocation, useParams } from 'react-router'
import M from 'materialize-css'
import TextPage from './inner_page/TextPage'
import AgeRangePage from './inner_page/age/AgeRangePage'
import PricePage from './inner_page/PricePage'
import MerchandisePage from './inner_page/MerchandisePage'
import { Link } from 'react-router-dom'
import { clone, isBoolean, isObject, isString } from 'lodash'
import EditPage from './inner_page/EditPage'
import { useTranslation } from 'react-i18next'
import LocalizedString from '../../common/component/LocalizedString'
import RequiredFieldsPage from './inner_page/RequiredFieldsPage'

/**
 * @typedef Page
 * @property {string|JSX.Element} name
 * @property {function} component
 */
/**
 * @typedef {Object.<string, Page>} PageList
 */
/**
 * @type {PageList}
 */
const pages = {
  '': { name: 'race', component: EditPage },
  'age-group': { name: 'groups', component: AgeRangePage },
  'price': { name: 'price', component: PricePage },
  'merchandise': { name: 'merchandise', component: MerchandisePage },
  'fields': { name: 'fields', component: RequiredFieldsPage },
}

const Tr = ({ text }) => {
  const { t } = useTranslation()
  //console.log(t(text))
  return t(text)
}

//Страница редактирования забега
const Edit = () => {
  const targetRaceId = useParams().id

  let { hash } = useLocation()
  hash = hash.replace('#', '')

  const [isLoading, setLoading] = useState(true)

  //Страницы текстовых блоков
  const [textBlocks, setTextBlocks] = useState(/** @type {PageList} */{})

  //Текущая страница
  const [CurrentPage, setCurrentPage] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (isLoading) {
      return
    }

    let page = (pages[hash] || {}).component
    if (!page) {
      page = (textBlocks[hash] || {}).component
    }
    if (!page) {
      return
    }
    setCurrentPage(() => page)
  }, [hash, textBlocks, targetRaceId])

  //Рефы для элементов span в которые записывается текст ошибок. Свой у каждого инпута
  const errRefs = {
    'name': useRef(),
    'date_start': useRef(),
    'member_prefix': useRef(),
    'members_max': useRef(),
    'status': useRef()
  }

  const state = useRef(null)

  const handleUpdate = (e) => {
    e.preventDefault()

    setLoading(true)
    apiRequest(PATH_RACE_UPDATE + targetRaceId, () => {
      $('#race-body input').removeClass('valid').removeClass('invalid')
      setLoading(false)
      M.toast({ html: t('race_edit.saved'), classes: 'rounded green large' })
    }, r => {
      setLoading(false)
      for (const err of r.response.data) {
        if (!errRefs[err['field']]) {
          M.toast({ html: getErrorMessage(r), classes: 'rounded red large' })
          continue
        }
        $(errRefs[err['field']].current).attr('data-error', err['message'])
        $('#race-body input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
      }
      M.toast({ html: t('race_edit.error'), classes: 'rounded red large' })
    }, state.current, 'put')
  }

  const handleChange = (name, e) => {
    const value = isString(e) || isBoolean(e) ? e : $(e.target).val()
    if (name.includes('date')) {
      state.current[name] = value + ' ' + state.current[name].split(' ')[1]
      return
    }
    if (name.includes('time')) {
      name = name.replace('time', 'date')
      state.current[name] = state.current[name].split(' ')[0] + ' ' + value
      return
    }
    state.current[name] = value
  }

  const addTextBlock = (block) => {
    const newBlocks = Object.entries(textBlocks)
    newBlocks.splice(newBlocks.length - 1, 0, ['text-' + block.id, {
      name: <LocalizedString str={block.name}/>,
      component: ({ remove }) => <TextPage remove={remove} id={block.id}/>
    }])
    setTextBlocks(Object.fromEntries(newBlocks))
    window.location.hash = 'text-' + block.id
  }

  const removeTextBlock = (id) => {
    const newBlocks = clone(textBlocks)
    delete newBlocks['text-' + id]
    setTextBlocks(newBlocks)
    window.location.hash = ''
  }

  const loadTextBlocks = () => {
    return apiRequest(PATH_TEXT_BLOCK_GET_BY_RACE, (res) => {
      /**
       * @type {PageList}
       */
      const blocks = {}
      for (const textBlock of res.data['text-blocks']) {
        const name = textBlock.immutable ? <Tr text={`race.nav.${textBlock.name}`}/> : <LocalizedString
          str={textBlock.name}/>
        blocks['text-' + textBlock.id] = {
          name,
          component: ({ remove }) => <TextPage remove={remove} id={textBlock.id}/>
        }
      }
      blocks['new-text'] = {
        name: <i className="material-icons" style={{ lineHeight: '50px' }}>add</i>,
        component: ({ add }) => <TextPage add={add} id="new"/>
      }
      setTextBlocks(blocks)
    }, () => {}, { 'target-race': targetRaceId })
  }

  useEffect(() => {
    if (state.current === null) {
      const promises = []
      promises.push(
        apiRequest(PATH_RACE_VIEW + targetRaceId, (response) => {
            delete response.data['years_range']
            delete response.data['price']
            state.current = response.data
            setCurrentPage(() => EditPage)
            setLoading(false)
          }, () => {
            setLoading(false)
          }, undefined, 'get'
        ))
      promises.push(loadTextBlocks())
      Promise.all(promises).finally(() => setLoading(false))
    }
  })

  return <div className="center">
    {isLoading ? <PageLoader/> : null}
    <div style={{
      maxWidth: '100%',
      width: '80%',
      margin: 'auto'
    }}>
      <div className="row">
        <div className="col s1 center">
          <h5>
            <Link to={`/admin/events/edit/${(state.current || {}).event}`}>
              {t('race_edit.go_to_event')}
            </Link>
          </h5>
        </div>
        <div className="col s10" style={{ textAlign: 'center' }}>
          <h4>
            {t('race_edit.current_race_id', { id: String(targetRaceId) })}
          </h4>
        </div>
      </div>
      <nav className="nav-extended">
        <div className="nav-content">
          <ul className="tabs tabs-transparent">
            {Object.entries({ ...pages, ...textBlocks }).map(([link, el]) => {
              return <li key={link} className={link === hash ? 'tab active' : 'tab'}>
                <a className={link === hash ? 'active' : ''} href={'#' + link}>
                  {isObject(el.name) ? el.name : t('race.nav.' + el.name, el.name)}
                </a>
              </li>
            })}
          </ul>
        </div>
      </nav>
      <br/>
      {CurrentPage ? <CurrentPage handleChange={handleChange} handleUpdate={handleUpdate} state={state}
                                  errRefs={errRefs} add={addTextBlock} remove={removeTextBlock}
                                  raceId={targetRaceId}/> : null}
    </div>
  </div>
}

export default Edit
