import React, {CSSProperties, ReactElement, useEffect, useRef, useState} from 'react'
import {apiRequest, PATH_EVENT_DELETE, PATH_EVENT_INDEX} from '../../../common/backend'
import {Link} from 'react-router-dom'
import M from 'materialize-css'
import $ from 'jquery'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography} from '@mui/material'
import {DeleteForever, Edit} from '@mui/icons-material'
import LocalizedString from "../../../common/component/LocalizedString";
import PageLoader from "../../../common/component/PageLoader";
import {useAuth} from "../../../context/AuthContext";

interface EventRowProps {
    event: RaceEvent,
    toRemove: (event: RaceEvent) => void
}

const EventRow = ({event, toRemove}: EventRowProps): JSX.Element => {
    const {t} = useTranslation()
    const status: string[] = [
        'event.edit.status.draft',
        'event.edit.status.active',
        'event.edit.status.blocked'
    ]

    const deletedStyle: CSSProperties = {}
    if (event.deleted_by !== -1) {
        deletedStyle.backgroundColor = '#ab0d0d'
        deletedStyle.borderRadius = '10px'
        deletedStyle.padding = '10px'
    }

    const tooltipText: JSX.Element | null = event.deleted_by !== -1 ?
        <Typography>{t('event.edit.status.removed')}</Typography>
        : null

    const statusText: string = event.deleted_by !== -1 ? 'event.edit.status.removed' : status[event.status]

    return <tr>
        <td>
            <img src={event.image!!} alt="" style={{maxWidth: '100%'}}/>
        </td>
        <td className="title">
            <LocalizedString str={event.name}/>
        </td>
        <td><span style={deletedStyle}>{t(statusText)}</span></td>
        <td>{event.date_start_at}</td>
        <td>{event.date_register_end}</td>
        <td className="center">
            <IconButton component={Link} to={`/admin/events/edit/${event.id}`} color="primary"
                        size="large">
                <Edit/>
            </IconButton>
        </td>
        <Tooltip placement='top' title={tooltipText}>
            <td className="center">
                <IconButton onClick={(): void => toRemove(event)} size="large" color="error"
                            disabled={event.deleted_by !== -1}>
                    <DeleteForever/>
                </IconButton>
            </td>
        </Tooltip>
    </tr>
}

// Страница со списком ивентов для редактирования
const IndexPage = () => {
    const [events, setEvents] = useState<RaceEvent[] | null>(null)
    const page = useRef<number>(-1)
    const [removeEvent, setRemoveEvent] = useState<RaceEvent | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const {t} = useTranslation()
    const role = useAuth().user?.role

    useEffect((): void => {
        if (page.current === -1) {
            page.current = 1
            loadPage()
        }
    })

    const loadPage = (): void => {
        apiRequest(PATH_EVENT_INDEX + (role === 2 ? '?my=1' : ''), (r: { data: RaceEvent[] }) => {
            r.data.forEach((it: RaceEvent): void => {
                it.image = it.image ?? '/no_image.png'
            })
            setEvents(r.data)
        }, undefined, undefined, 'get')
    }

    const removeEventAction = (): void => {
        setLoading(true)
        apiRequest(PATH_EVENT_DELETE + removeEvent!!.id, (res: { data: { message: any } }) => {
            loadPage()
            M.toast({
                html: res.data.message,
                classes: 'rounded green large'
            })
            // @ts-ignore
        }, () => {
            // setLoading(false)
            M.toast({
                html: t('event.list.removal_success', {name: $('#remove-event-name').text()}),
                classes: 'rounded red large'
            })
        }, undefined, 'delete')!!.finally((): void => {
            setLoading(false)
            setRemoveEvent(null)
        })
    }

    return <>
        {loading && <PageLoader zIndex={9000}/>}
        <Dialog open={removeEvent !== null} onClose={(): void => setRemoveEvent(null)}>
            <DialogTitle>
                {t('event.list.remove_modal.header')}
            </DialogTitle>
            <DialogContent>
                {t('event.list.remove_modal.text')} <LocalizedString str={removeEvent?.name ?? ''}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(): void => setRemoveEvent(null)}>
                    {t('event.list.remove_modal.cancel_btn')}
                </Button>
                <Button onClick={removeEventAction}>
                    {t('event.list.remove_modal.delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
        <ul className="collection with-header" style={{
            maxWidth: '100%',
            width: '80%',
            margin: 'auto'
        }}>
            <h4 className="center">
                {t('event.list.header')}
            </h4>
            <h4 className="center">
                <Button size='large' color='primary' component={Link} to="/admin/events/create">
                    {t('event.list.create_event_btn')}
                </Button>
            </h4>
            <table className="table-bordered" style={{tableLayout: 'fixed'}}>
                <thead>
                <tr>
                    <th className="center">{t('event.list.table.image')}</th>
                    <th>{t('event.list.table.name')}</th>
                    <th>{t('event.list.table.status')}</th>
                    <th>{t('event.list.table.date_start_at')}</th>
                    <th>{t('event.list.table.date_register_end_at')}</th>
                    <th className="center">{t('event.list.table.edit')}</th>
                    <th className="center">{t('event.list.table.delete')}</th>
                </tr>
                </thead>
                <tbody>
                {events?.map((it: RaceEvent): ReactElement => {
                    return <EventRow key={it.id} event={it} toRemove={(it: RaceEvent): void => setRemoveEvent(it)}/>
                })}
                </tbody>
            </table>
        </ul>
    </>
}

export default IndexPage
