//Страница создания забега
import React, { useRef, useState } from 'react'
import { apiRequest, PATH_RACE_CREATE } from '../../common/backend'
import $ from 'jquery'
import PageLoader from '../../common/component/PageLoader'
import { useNavigate, useParams } from 'react-router'
import Body from './Body'
import { getCurrentDateTime } from '../../common/commons'
import { useTranslation } from 'react-i18next'
import { isBoolean, isString } from 'lodash'

const Create = () => {

  const targetEventId = useParams().eventId
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  const errRefs = {
    'name': useRef(),
    'name_short': useRef(),
    'distance': useRef(),
    'date_start': useRef(),
    'member_prefix': useRef(),
    'members_max': useRef(),
    'price_promo': useRef(),
    'status': useRef()
  }

  const state = useRef({
    event: targetEventId,
    status: 0,
    name: '',
    name_short: '',
    distance: '',
    price_promo: '{}',
    date_start: getCurrentDateTime(),
    member_prefix: '-1',
    members_max: '-1',
    team_max: '-1',
    years_range: '{"0":[],"1":[]}',
    is_child: false
  })

  const nav = useNavigate()

  const handleCreate = (e) => {
    e.preventDefault()

    setLoading(true)
    state.current['event'] = targetEventId
    apiRequest(PATH_RACE_CREATE, res => {
      $('#create-race input').removeClass('valid').removeClass('invalid')
      setLoading(false)
      nav('/admin/events/race/edit/' + res.data['id'])
    }, r => {
      for (const err of r.response.data) {
        console.log(err['field'] + ' - ' + err['message'])
        $(errRefs[err['field']].current).attr('data-error', err['message'])
        $('#create-race input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
      }
      setLoading(false)
    }, state.current, 'post')
  }

  const handleChange = (name, e) => {
    const value = isString(e) || isBoolean(e) ? e : $(e.target).val()
    if (name.includes('date')) {
      state.current[name] = value + ' ' + state.current[name].split(' ')[1]
      return
    }
    if (name.includes('time')) {
      name = name.replace('time', 'date')
      state.current[name] = state.current[name].split(' ')[0] + ' ' + value
      return
    }
    state.current[name] = value
  }

  const language = useTranslation().i18n.language

  return <>
    {isLoading && <PageLoader/>}
    <div className="center">
      <h4>{t('race_create_modal.header')}</h4>
      <br/>
      <form id="create-race" onSubmit={handleCreate.bind(this)} action="#">
        <Body language={language} state={state} errRefs={errRefs} handleChange={handleChange}/>
        <input type="hidden" value={targetEventId}/>
        <div className="input-field">
          <button type="submit" className="btn btn-large btn-register waves-effect waves-light"
                  name="action">
            {t('race_create_modal.create_btn')}
            <i className="material-icons right">done</i>
          </button>
        </div>
      </form>
    </div>
  </>
}
export default Create
