import React, { useLayoutEffect, useState } from 'react'
import PageLoader from '../../common/component/PageLoader'
import {
  apiRequest,
  backendAddress,
  getErrorMessage,
  PATH_CORPORATE_SIGNUP_REQUEST_LIST
} from '../../common/backend'
import M from 'materialize-css'
import CorporateSignupRequest from './CorporateSignupRequest'
import { ceil, clone, flatten, isEmpty, isNumber } from 'lodash'
import { range } from 'lodash/util'
import { paginateArray } from '../../common/commons'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import ML from '../../common/multilang'
import i18n from 'i18next'
import $ from 'jquery'
import { Button } from '@mui/material'
import { Add, Download } from '@mui/icons-material'
import { useAuth } from '../../context/AuthContext'

const List = ({ eventId = null }) => {
  const [loading, setLoading] = useState(true)

  const [list, setList] = useState(/** @type CorporateSignupRequest[] */[])

  const [page, setPage] = useState(1)
  const perPage = 35

  const { t } = useTranslation()

  useLayoutEffect(() => {
    let path = PATH_CORPORATE_SIGNUP_REQUEST_LIST
    if (eventId) {
      path += '&event_id=' + eventId
    }
    const promises = []

    promises.push(
      apiRequest(path, res => {
        setList(res.data)
      }, e => {
        M.toast({ html: getErrorMessage(e), classes: 'rounded red large', displayLength: 15000 })
      }, {}, 'GET'))

    Promise.all(promises).finally(() => setLoading(false))
  }, [])

  const getToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return yyyy + '-' +  mm + '-' + dd;
  }

  const getFiltered = () => {
    let result = list
    return result.filter(it => {
      // if (isNumber(event) && it.event_id !== event) {
      //   return false
      // }
      return true
    })
  }
  const getPaginated = () => paginateArray(getFiltered(), 35, page)

  const pageMax = ceil(getFiltered().length / perPage)

  const previousPage = () => {
    const newPage = page - 1
    setPage(newPage < 1 ? 1 : newPage)
  }
  const nextPage = () => {
    const newPage = page + 1
    setPage(newPage > pageMax ? pageMax : newPage)
  }

  const language = useTranslation().i18n.language


  if (loading) {
    return <PageLoader/>
  }

  return <div style={{
    maxWidth: '100%',
    width: '80%',
    margin: 'auto'
  }}>
    <div className="row">
      <table className="striped">
        <thead>
        <tr>
          {['id', 'user_id', 'event_id', 'status', 'discount', 'company_name', 'registration_number', 'team_name', 'phone', 'created_at'].map(
              //item => <th key={item}>{t(`corporate_signup_request_list.${item}`)}</th>
              item => <th key={item}>{item}</th>
          )}
        </tr>
        </thead>
        <tbody>
        {getPaginated().map(item => <CorporateSignupRequest key={item.id} requestData={item}/>)}
        </tbody>
      </table>
      <ul className="pagination">
        <li className={page === 1 ? 'disabled' : 'waves-effect'}>
          <a href="#!" onClick={e => {
            e.preventDefault()
            previousPage()
          }}><i className="material-icons">chevron_left</i></a>
        </li>
        {range(1, pageMax + 1).map(it => {
          return <li key={it} className={it === page ? 'active' : ''}>
            <a onClick={e => {
              e.preventDefault()
              setPage(it)
            }} href="#!">{it}</a>
          </li>
        })}
        <li className={page === pageMax ? 'disabled' : 'waves-effect'}>
          <a href="#!" onClick={e => {
            e.preventDefault()
            nextPage()
          }}><i className="material-icons">chevron_right</i></a>
        </li>
      </ul>
    </div>
  </div>
}
export default List
