import React, {ChangeEvent, HTMLInputTypeAttribute} from 'react'

interface CommonInputProps {
    id: string;
    desc: string;
    races: { [k: number]: Race };
    type: HTMLInputTypeAttribute;
    change: (name: string, value: string | number | Race) => void;
    value: string | number;
    required: boolean;
    immutable: boolean;
}

const DefaultInput = ({id, desc, change, type, value, required}: CommonInputProps) => {
    return <>
        <i className='material-icons prefix'>edit</i>
        <input id={`participant-input-${id}`} role='presentation' autoComplete='none'
               onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                   change(id!!, e.target.value)
               }} min='0'
               required={required} name={id} className='validate'
               defaultValue={value as string | null || ''}
               type={type}/>
        <label htmlFor={`participant-input-${id}`} className='active'>{desc}</label>
    </>
}

export default DefaultInput
export type {CommonInputProps}
