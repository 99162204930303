import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PageLoader from '../../../../common/component/PageLoader'
import { apiRequest, PATH_EVENT_UPDATE, PATH_EVENT_VIEW } from '../../../../common/backend'
import M from 'materialize-css'
import FixedSaveButton from '../../../../common/component/FixedSaveButton'
import $ from 'jquery'
import { generateRandomString } from '../../../../common/commons'
import { useTranslation } from 'react-i18next'
import Promocode from './Promocode'
import { useCurrencyList } from '../../../../context/CurrencyListContext'
import { clone } from 'lodash'

const PromocodePage = (props) => {
  const eventId = props.eventId

  const [isLoading, setLoading] = useState(true)
  const [promocode, setPromocode] = useState(null)
  //Обновляется после сохранения
  //Для вывода добавленых кодов
  const beforeSaveCode = useRef(null)
  const [newPromocodes, setNewPromocodes] = useState([])

  const { t } = useTranslation()
  const currencies = useCurrencyList()

  const [generateData, setGenerateData] = useState({
    discount_type: 0,
    discount: {},
    discount_percent: 1,
    max: 1
  })

  useLayoutEffect(() => {
    if (currencies.every(it => it in generateData.discount)) {
      return
    }
    const data = clone(generateData)
    data.discount = Object.fromEntries(currencies.map(it => [it, 0]))
    setGenerateData(data)
  }, [currencies])

  const updateGenerateData = (key, val) => {
    const data = clone(generateData)
    data[key] = val
    setGenerateData(data)
  }
  const updateGenerateDataFixed = (val, code) => {
    const data = clone(generateData)
    data.discount[code] = val
    setGenerateData(data)
  }

  useEffect(() => {
    if (promocode === null) {
      apiRequest(PATH_EVENT_VIEW + eventId, res => {
        setPromocode(new Map(Object.entries(JSON.parse(res.data['promocode']))))
        beforeSaveCode.current = new Map(Object.entries(JSON.parse(res.data['promocode'])))
        setLoading(false)
      }, () => {}, undefined, 'get')
    }
    M.Modal.init(document.querySelectorAll('.modal'), {})
    M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {})
  })

  const loading = () => {
    if (isLoading) {
      return <PageLoader/>
    }
    return ''
  }

  const savePromocode = () => {
    apiRequest(PATH_EVENT_UPDATE + eventId, () => {
      const newCodes = Array.from(promocode.keys())
      Array.from(beforeSaveCode.current.keys()).forEach(it => {
        const index = newCodes.indexOf(it)
        if (index > -1) {
          newCodes.splice(index, 1)
        }
      })
      beforeSaveCode.current = new Map(JSON.parse(JSON.stringify(Array.from(promocode))))
      if (newCodes.length > 0) {
        setNewPromocodes(newCodes)
        setLoading(false)
        document.getElementById('added-code-trigger').click()
      } else {
        setLoading(false)
      }
      M.toast({ html: t('event.promocodes.saving_success'), classes: 'rounded green large' })
    }, err => {
      setLoading(false)
      M.toast({
        html: t('event.promocodes.saving_error') + ' ' + err.response.data[0].message,
        classes: 'rounded red large'
      })
    }, { 'promocode': JSON.stringify(Object.fromEntries(promocode)) }, 'put')
  }

  const generatePromocodes = (count, length) => {
    const list = []
    while (list.length < count) {
      const code = generateRandomString(length)
      if (promocode.has(code)) {
        continue
      }
      list.push(code)
    }
    return list
  }

  if (promocode === null) {
    return <PageLoader/>
  }

  return <>
    <div id="added-codes" className="modal open">
      <div className="modal-content">
        <h4>{t('event.promocodes.header')}</h4>
        <p><a className="btn waves-effect waves-green blue" onClick={() => {
          navigator.clipboard.writeText(newPromocodes.join('\n'))
        }}>{t('event.promocodes.copy_btn')}<i className="material-icons right">content_copy</i></a></p>
        <ul className="collection">
          {Array.from(newPromocodes.map(it => <li className="collection-item">{it}</li>))}
        </ul>
      </div>
      <div className="modal-footer">
        <a href="#" onClick={e => e.preventDefault()} className="modal-close waves-effect waves-green btn-flat">
          {t('event.promocodes.close_btn')}
        </a>
      </div>
    </div>
    <a id="added-code-trigger" className="modal-trigger" href="#added-codes" hidden="hidden"/>
    <div style={{
      maxWidth: '100%',
      width: '70%',
      margin: 'auto'
    }}>
      {loading()}
      <FixedSaveButton action={savePromocode}/>
      <ul className="collection with-header">
        <li className="collection-header">
          <h4>{t('event.promocodes.promocode_generation')}</h4>
          <div className="row">
            <div className="input-field col s2">
              <input type="number"
                     id="new-code-count"
                     defaultValue="1"/>
              <label htmlFor="new-code-count" className="active">{t('event.promocodes.count')}</label>
            </div>
            {generateData.discount_type === 0 ?
              <div className="input-field col s2">
                <input required="required" type="number" min="0" max="100"
                       onChange={e => {
                         let val = parseInt(e.target.value)
                         if (val > 100) {
                           val = 100
                           e.target.value = '100'
                         }
                         updateGenerateData('discount_percent', val)
                       }}
                       id="new-code-discount"
                       defaultValue={generateData['discount_percent']}/>
                <label htmlFor="new-code-discount"
                       className="active">{t('event.promocodes.percent', { percent: ' %' })}</label>
              </div>
              : currencies.map(currency => {
                return <div key={currency}>
                  <div className="input-field col s1">
                    <input required="required" type="number" min="0" max="100"
                           onChange={e => {
                             let val = parseInt(e.target.value)
                             updateGenerateDataFixed(val, currency)
                           }}
                           id="new-code-discount"
                           defaultValue={generateData['discount'][currency]}/>
                    <label htmlFor="new-code-discount"
                           className="active">{currency}</label>
                  </div>
                </div>
              })
            }
            <div className="input-field col s3">
              <select className="browser-default" id="new-code-type" defaultValue="0"
                      onChange={e => updateGenerateData('discount_type', parseInt(e.target.value))}>
                <option value="0">{t('event.promocodes.discount_type.percent')}</option>
                <option value="1">{t('event.promocodes.discount_type.fixed')}</option>
              </select>
              <label htmlFor="new-code-type" className="active">{t('event.promocodes.discount_type')}</label>
            </div>
            <div className="input-field col s2">
              <input required="required" type="number" min="1" max="9999999"
                     onChange={e => updateGenerateData('max', e.target.value)}
                     id="new-code-max"
                     defaultValue="1"/>
              <label htmlFor="new-code-max" className="active">{t('event.promocodes.max_usages')}</label>
            </div>
            <div className="input-field col s3">
              <button className="btn waves-effect waves-green green" onClick={() => {
                const list = new Map(JSON.parse(JSON.stringify(Array.from(promocode))))
                generatePromocodes(parseInt($('#new-code-count').val()), 7).forEach(code => {
                  list.set(code, {
                    discount: generateData.discount,
                    discount_type: generateData.discount_type,
                    discount_percent: generateData.discount_percent,
                    used: 0,
                    max: generateData.max,
                  })
                })
                setPromocode(list)
                M.toast({ html: t('event.promocodes.dont_forget_to_save'), classes: 'rounded orange large' })
              }}>
                {t('event.promocodes.generate_btn')}
                <i className="material-icons right">add</i>
              </button>
            </div>
          </div>
        </li>
        <li className="collection-header">
          <h4>{t('event.promocodes.header')}</h4>
        </li>
        {Array.from(promocode).map(([value, data], i) => {
          return <Promocode key={i} id={i} propsData={data} update={it => Object.assign(data, it)} value={value}
                            remove={() => {
                              const list = new Map(JSON.parse(JSON.stringify(Array.from(promocode))))
                              list.delete(value)
                              setPromocode(list)
                            }}/>
        })}
      </ul>
      <br/>
      <div className="row">
        <div className="input-field col s5"/>
        <div className="input-field col s2">
          <input type="text" id="new-code-name"/>
          <label htmlFor="new-code-name">{t('event.promocodes.promocode')}</label>
        </div>
      </div>
      <a onClick={() => {
        const list = new Map(JSON.parse(JSON.stringify(Array.from(promocode))))
        let code = $('#new-code-name').val()
        if (!code) {
          code = generatePromocodes(1, 7)[0]
        }
        if (list.has(code)) {
          M.toast({ html: t('event.promocodes.promocode_exists_error'), classes: 'rounded red large' })
          return
        }
        list.set(code, {
          discount: Object.fromEntries(currencies.map(it => [it, 1])),
          discount_percent: 0,
          discount_type: 0,
          used: 0,
          max: 1
        })
        setPromocode(list)
      }} className="btn-floating btn-large green"><i
        className="material-icons">add</i></a>
      <br/>
      <br/>
      <br/>
    </div>
  </>
}

export default PromocodePage
