import React, { useEffect, useRef, useState } from 'react'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import TextEditor from '../../common/component/TextEditor'
import M from 'materialize-css'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import TranslatableTextInput from '../../common/component/TranslatableTextInput'
import LocationSelector from './LocationSelector'
import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { AddLocationAlt, Close } from '@mui/icons-material'
/*
Инпуты для ивента. Используются на странице создания ивента и его редактирования

props:
state - Ref с объектом где записаны текущие значения инпутов

errRefs - объект со списком ref`ов, в них указаны ссылки на текст, возникающий при ошибке.
Каждый инпут имеет свой span для отображения этого текста. Чтобы этот текст стал видимым нужно добавить инпуту класс invalid

props.handleChange - функция вызывающаяся при изменении инпута
*/
const Body = (props) => {

  /*
   * В запросе к при указании даты передается строка вида 2000-12-31 24:00:00
   * @return часы и минуты от строки с датой
   */
  // noinspection DuplicatedCode
  const getTime = (str) => {
    const timePart = str.split(' ')[1]
    return timePart.substr(0, timePart.lastIndexOf(':'))
  }

  const { t } = useTranslation()

  useEffect(() => {
    /** https://materializecss.com/pickers.html */
    $('.datepicker').each((_, it) => {
      M.Datepicker.init($(it), {
        format: 'yyyy-mm-dd',
        defaultDate: new Date(props.state.current[$(it).attr('name')].split(' ')[0]),
        setDefaultDate: true,
        minDate: new Date(),
        i18n: {
          months: [t('month.january'), t('month.february'), t('month.march'), t('month.april'), t('month.may'), t('month.june'), t('month.july'), t('month.august'), t('month.september'), t('month.october'), t('month.november'), t('month.december')],
          monthsShort: [t('month.january.short'), t('month.february.short'), t('month.march.short'), t('month.april.short'), t('month.may.short'), t('month.june.short'), t('month.july.short'), t('month.august.short'), t('month.september.short'), t('month.october.short'), t('month.november.short'), t('month.december.short')],
          weekdays: [t('weekday.sunday'), t('weekday.monday'), t('weekday.tuesday'), t('weekday.wednesday'), t('weekday.thursday'), t('weekday.friday'), t('weekday.saturday')],
          weekdaysShort: [t('weekday.sunday.short'), t('weekday.monday.short'), t('weekday.tuesday.short'), t('weekday.wednesday.short'), t('weekday.thursday.short'), t('weekday.friday.short'), t('weekday.saturday.short')],
          weekdaysAbbrev: [t('weekday.sunday.abbrev'), t('weekday.monday.abbrev'), t('weekday.tuesday.abbrev'), t('weekday.wednesday.abbrev'), t('weekday.thursday.abbrev'), t('weekday.friday.abbrev'), t('weekday.saturday.abbrev')],
          cancel: t('cancel')
        }
      })
      //onChange that was set in the html element below don`t work
      $(it).change((event) => props.handleChange($(it).attr('name'), event))
    })
    $('.timepicker').each((_, it) => {
      M.Timepicker.init($(it), {
        twelveHour: false,
        defaultTime: getTime(props.state.current[$(it).attr('name').replace('time', 'date')]),
        i18n: {
          cancel: t('cancel'),
          done: t('ok')
        }
      })
      //onChange that was set in the html element below don`t work
      $(it).change((event) => props.handleChange($(it).attr('name'), event))
    })
  })

  const language = props.language
  const [dialogRef,setDialogRef] = useState()
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false)

  return <div>
    <Dialog open={locationSelectorOpen} fullScreen={true} keepMounted={false}
            onClose={() => setLocationSelectorOpen(false)}>
      <DialogContent ref={it=>setDialogRef(it)}>
        <LocationSelector
          width={dialogRef?.getBoundingClientRect()?.width || 0}
          height={dialogRef?.getBoundingClientRect()?.width || 0}
          center={props.state.current['location_lat'] && props.state.current['location_lng'] ? {
            lat: parseFloat(props.state.current['location_lat']),
            lng: parseFloat(props.state.current['location_lng']),
          } : null}
          onMarkerDrag={(pos) => {
            props.state.current['location_lng'] = pos.lng().toString()
            props.state.current['location_lat'] = pos.lat().toString()
          }}
        />
      </DialogContent>
      <DialogActions>
        <IconButton onClick={() => setLocationSelectorOpen(false)}>
          <Close/>
        </IconButton>
      </DialogActions>
    </Dialog>
    <div className="row">
      <TranslatableTextInput initialValue={props.state.current['name'] ?? ''}
                             update={(it) => props.state.current['name'] = it}
                             language={language} name={t('event.body.name')}
      />
      {/*<div className="input-field col s6">
        <input onChange={props.handleChange.bind(this, 'name')} minLength="3" maxLength="255"
               className="validate" required="required" type="text"
               id="name" name="name" defaultValue={props.state.current['name']}/>
        <label htmlFor="name" className="active">{t('event.body.name')}</label>
        <span ref={props.errRefs['name']} className="helper-text"
              data-error={t('event.body.name_error')}/>
      </div>*/}
      <TranslatableTextInput initialValue={props.state.current['sponsor'] ?? ''}
                             update={(it) => props.state.current['sponsor'] = it}
                             language={language} name={t('event.body.organizer')}
      />
    </div>
    <br/>
    <div className="row">
      <span className="h4">{t('event.body.description')}</span>
      <div className="input-field col s12">
        <TextEditor ref={props.descriptionRef} triggerName="description" handleChange={props.handleChange}
                    language={language} id='description'
                    defaultText={props.state.current.description}/>
      </div>
    </div>
    <div className="row">
      <span className="h4">{t('event.body.schedule')}</span>
      <div className="input-field col s12">
        <TextEditor ref={props.scheduleRef} triggerName="schedule" handleChange={props.handleChange}
                    language={language} id='schedule'
                    defaultText={props.state.current.schedule}/>
      </div>
    </div>
    <br/>
    <div className="row center">
      <TranslatableTextInput initialValue={props.state.current['city'] ?? ''} classes="input-field col s4"
                             update={(it) => props.state.current['city'] = it}
                             language={language} name={t('event.body.city')}
      />
      <TranslatableTextInput initialValue={props.state.current['address'] ?? ''} classes="input-field col s4"
                             update={(it) => props.state.current['address'] = it}
                             language={language} name={t('event.body.address')}
      />
      <Button onClick={() => setLocationSelectorOpen(true)}
              endIcon={<AddLocationAlt/>}>{t('event.body.address')}</Button>
      {/*<LocationSelector/>*/}
    </div>
    <div className="row">
      <div className="input-field col s2">
        <input onChange={props.handleChange.bind(this, 'date_register_start')} minLength="3" maxLength="255"
               className="validate" required="required" type="date"
               id="date_register_start" name="date_register_start"
               defaultValue={props.state.current['date_register_start'].split(' ')[0]}/>
        <label htmlFor="date_register_start" className="active">{t('event.body.registration_start_at')}</label>
        {/*<i className="material-icons">date_range</i>*/}
        <span ref={props.errRefs['date_register_start']} className="helper-text"
              data-error={t('event.body.register_start_error')}/>
      </div>
      <div className="input-field col s1">
        <input onChange={props.handleChange.bind(this, 'time_register_start')} minLength="3" maxLength="255"
               className="validate" /*required="required"*/ type="time"
               id="time_register_start" name="time_register_start"
               defaultValue={getTime(props.state.current['date_register_start'])}/>
      </div>
      <div className="input-field col s1"/>
      <div className="input-field col s2">
        <input onChange={props.handleChange.bind(this, 'date_register_end')} minLength="3" maxLength="255"
               className="validate" required="required" type="date"
               id="date_register_end" name="date_register_end"
               defaultValue={props.state.current['date_register_end'].split(' ')[0]}/>
        <label htmlFor="date_register_end" className="active">{t('event.body.registration_end_at')}</label>
        <span ref={props.errRefs['date_register_end']} className="helper-text"
              data-error={t('event.body.registration_end_error')}/>
      </div>
      <div className="input-field col s1">
        <input onChange={props.handleChange.bind(this, 'time_register_end')} minLength="3" maxLength="255"
               className="validate" required="required" type="time"
               id="time_register_end" name="time_register_end"
               defaultValue={getTime(props.state.current['date_register_end'])}/>
      </div>
      <div className="input-field col s1"/>
      <div className="input-field col s2">
        <input onChange={props.handleChange.bind(this, 'date_start_at')} minLength="3" maxLength="255"
               className="validate" required="required" type="date"
               id="date_start_at" name="date_start_at"
               defaultValue={props.state.current['date_start_at'].split(' ')[0]}/>
        <label htmlFor="date_start_at" className="active">{t('event.body.date_start_at')}</label>
        <span ref={props.errRefs['date_start_at']} className="helper-text"
              data-error={t('event.body.date_start_error')}/>
      </div>
      <div className="input-field col s1">
        <input onChange={props.handleChange.bind(this, 'time_start_at')} minLength="3" maxLength="255"
               className="validate" required="required" type="time"
               id="time_start_at" name="time_start_at" defaultValue={getTime(props.state.current['date_start_at'])}/>
      </div>
    </div>
    <br/>
  </div>
}
export default Body
