import {ReactElement, useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper} from "@mui/material";
import {CheckCircle, DoNotDisturb} from "@mui/icons-material";
import PageLoader from "../../../common/component/PageLoader";
import {
    apiRequest,
    backendAddress,
    PATH_USER_ACCEPT_DOCUMENTS,
    PATH_USER_DECLINE_DOCUMENTS,
    PATH_USER_GET_DOCUMENTS
} from "../../../common/backend";
import JSZip from "jszip";
import {decryptFiles, decryptSymmetricKey, SymmetricKey} from "../../../common/crypt";
import {getPercentage} from "../../../common/commons";
import DeclineDialog from "./DeclineDialog";

enum DocumentsStatus {
    IN_PROGRESS = 0,
    ACCEPTED = 1,
    DECLINED = 2,
    UNVERIFIED = 3,
}

export interface User {
    id: number,
    first_name: string,
    last_name: string,
    documents_status: DocumentsStatus,
    documents_verified: boolean
}

interface DocumentsModalProps {
    close: () => void,
    open: boolean,
    user: User | null,
    rsaKey: CryptoKey | null
}

type FileList = Map<string, string>

const fetchData = async (rsaKey: CryptoKey, userID: string | number): Promise<FileList> => {
    // fetch zip archive containing key and encrypted files
    const file: Blob = await (await fetch(backendAddress + PATH_USER_GET_DOCUMENTS + `?user=${userID}`, {
        method: 'get',
        headers: {Authorization: 'Bearer ' + localStorage.getItem('api-key')},
    })).blob()
    let zip: JSZip = new JSZip();
    zip = await zip.loadAsync(file)

    // extract files from zip archive
    const files: Map<string, Blob> = new Map<string, Blob>()
    for (const [name, zipObject] of Object.entries(zip.files)) {
        files.set(name, await zipObject.async('blob'))
    }

    // symmetric key also present in zip, but encrypted with RSA
    const symmetricKey: SymmetricKey = await decryptSymmetricKey(files.get('key')!, rsaKey!)
    files.delete('key')
    return await decryptFiles(files, symmetricKey, true)
}

const DocumentsModal = ({close, user, open, rsaKey}: DocumentsModalProps): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false)
    const [files, setFiles] = useState<FileList | null>(null)

    const [declineUser, setDeclineUser] = useState<User | null>(null)

    const {t} = useTranslation()

    const documentsCache = useRef<Map<number, FileList>>(new Map<number, FileList>)

    useEffect((): void => {
        if (user === null) {
            setFiles(null)
            return
        }
        const cached: FileList | null = documentsCache.current.get(user.id) ?? null
        if (cached !== null) {

            console.log("cached")
            setFiles(cached)
            return;
        }
        setLoading(true)

        fetchData(rsaKey!, user!.id).then((it: FileList): void => {
            documentsCache.current.set(user.id, it)
            setFiles(it)
        }).finally((): void => {
            setLoading(false)
        })
    }, [user?.id])

    const decline = (comment: string): void => {
        setLoading(true)
        apiRequest(PATH_USER_DECLINE_DOCUMENTS + `?user=${user!.id}&comment=${encodeURIComponent(comment)}`, (): void => {
            documentsCache.current.delete(declineUser!.id)
            M.toast({
                html: t('document.declined', {
                    firstName: user!.first_name,
                    lastName: user!.last_name
                }), classes: 'rounded yellow large'
            })
            user!.documents_status = DocumentsStatus.DECLINED
            close()
            // @ts-ignore
        }, (err: any): void => {
            M.toast({html: t('document.unknown_error'), classes: 'rounded red large'})
            console.log(err)
        })!.finally((): void => {
            setDeclineUser(null)
            setLoading(false)
        })
    }

    const accept = (): void => {
        setLoading(true)
        apiRequest(PATH_USER_ACCEPT_DOCUMENTS + `?user=${user!.id}`, (): void => {
                M.toast({
                    html: t('document.accepted', {
                        firstName: user!.first_name,
                        lastName: user!.last_name
                    }), classes: 'rounded green large'
                })
                user!.documents_status = DocumentsStatus.ACCEPTED
                close()
                // @ts-ignore
            }, (): void => {
                M.toast({html: t('document.unknown_error'), classes: 'rounded red large'})
            },
        )!.finally((): void => {
            setLoading(false)
        })
    }

    return <>
        {loading && <PageLoader zIndex={9000}/>}
        <DeclineDialog isOpen={declineUser !== null} close={(): void => {
            setDeclineUser(null)
        }} decline={decline} user={declineUser}/>
        <Dialog component={Paper} fullScreen={true} open={open} onClose={close}>
            <DialogTitle>
                {t('document.title', {first_name: user?.first_name, last_name: user?.last_name, id: user?.id})}
            </DialogTitle>
            <DialogContent>
                <Grid spacing={2} justifyContent='center' direction='column' container={true}>
                    {files !== null && Array.from(files.entries()).map(([filename, data]: [string, string]) => {
                        const extension: string = filename.split('.').pop()!
                        const src: string = `data:image/${extension};base64, ${data}`

                        let element: ReactElement
                        if (extension === 'pdf') {
                            element = <iframe src={`data:application/pdf;base64, ${data}`}
                                              width={getPercentage(window.innerWidth, 80)}
                                              height={getPercentage(window.innerHeight, 80)}/>
                        } else {
                            element = <img alt={filename} src={src} height={getPercentage(window.innerHeight, 80)}/>
                        }
                        return <Grid key={filename} container={true}>
                            <a href={src} download={filename} style={{textAlign: 'center', margin: 'auto'}}>
                                {element}
                            </a>
                        </Grid>
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t('document.close_btn')}</Button>
                {user?.documents_status === DocumentsStatus.IN_PROGRESS && <>
                  <Button endIcon={<DoNotDisturb/>} onClick={(): void => setDeclineUser(user)}>
                      {t('document.decline_btn')}
                  </Button>
                  <Button endIcon={<CheckCircle/>} onClick={accept}>
                      {t('document.accept_btn')}
                  </Button>
                </>}
            </DialogActions>
        </Dialog>
    </>
}

export default DocumentsModal
