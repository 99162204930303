import React, { useEffect, useRef, useState } from 'react'
import { apiRequest, PATH_MERCHANDISE_DELETE, PATH_MERCHANDISE_INDEX } from '../../common/backend'
import { Link, NavLink } from 'react-router-dom'
import M from 'materialize-css'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import ML from '../../common/multilang'

//Страница со списком ивентов для редактирования
const IndexPage = () => {
  const [merch, setMerch] = useState(null)
  const removeMerchId = useRef(-1)
  const { t, i18n } = useTranslation()

  const loadMerch = () => {
    apiRequest(PATH_MERCHANDISE_INDEX, res => {
      const list = []
      for (const it of res.data) {
        const link = '/admin/merchandise/edit/' + it['id']
        list.push(
          <tr key={'merch-' + it['id']}>
            <td className="title">{ML.getStringForLanguage({
              multilangString: it['name'],
              language: i18n.language
            })}</td>
            {/*<td>{it['price']} грн</td>*/}
            <td>{it['max_per_race']}</td>
            <td className="center"><NavLink to={link}><i className="material-icons">edit</i></NavLink></td>
            <td className="center">
              <a href="#remove-merch" onClick={() => {
                $('#remove-merch-name').text(it['name'])
                removeMerchId.current = it['id']
              }} className="modal-trigger"><i className="material-icons">delete</i></a>
            </td>
          </tr>
        )
      }
      setMerch(list)
    }, () => {
    }, {}, 'get')
  }

  useEffect(() => {
    if (merch === null) {
      loadMerch()
    }
    M.Modal.init($('.modal'), {})
  })

  const removeMerch = () => {
    // setLoading(true)
    apiRequest(PATH_MERCHANDISE_DELETE + removeMerchId.current, () => {
      loadMerch()
      M.toast({
        html: t('merch.list.removing_success', { name: $('#remove-merch-name').text() }),
        classes: 'rounded green large'
      })
    }, () => {
      // setLoading(false)
      M.toast({
        html: t('merch.list.removing_fail') + ' ' + $('#remove-merch-name').text(),
        classes: 'rounded red large'
      })
    }, undefined, 'delete')
  }

  return <>
    <div id="remove-merch" className="modal">
      <div className="modal-content">
        <h4>{t('merch.remove_item_dialog.header')}</h4>
        <p>{t('merch.remove_item_dialog.text')} <strong id="remove-merch-name"/>?</p>
      </div>
      <div className="modal-footer">
        <a href="#!"
           className="modal-close waves-effect waves-green btn-flat">{t('merch.remove_item_dialog.cancel_btn')}</a>
        <a href="#!" onClick={removeMerch}
           className="modal-close waves-effect waves-red btn-flat">{t('merch.remove_item_dialog.remove_btn')}</a>
      </div>
    </div>
    <br/>
    <ul className="collection with-header" style={{
      maxWidth: '100%',
      width: '60%',
      margin: 'auto'
    }}>
      <h4 className="center">{t('merch.list.header')}</h4>
      <h4 className="center">
        <Link to="/admin/merchandise/create" className="btn btn-large btn-register waves-effect waves-light">
          {t('merch.list.create_btn')}
        </Link></h4>
      <table className="table-bordered" style={{ tableLayout: 'fixed' }}>
        <thead>
        <tr>
          <th>{t('merch.list.table.name')}</th>
          {/*<th>{t('merch.list.table.price')}</th>*/}
          <th>{t('merch.list.table.max_per_race')}</th>
          <th className="center">{t('merch.list.table.edit')}</th>
          <th className="center">{t('merch.list.table.remove')}</th>
        </tr>
        </thead>
        <tbody>
        {merch}
        </tbody>
      </table>
    </ul>
  </>
}

export default IndexPage
