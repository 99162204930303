import {useTranslation} from "react-i18next";
import {ReactElement} from "react";
import ML from "../multilang";

interface LocalizedStringProps {
    str: string
}

const LocalizedString = ({str}: LocalizedStringProps): ReactElement => {
    const language: string = useTranslation().i18n.language
    return <>
        {ML.getStringForLanguage({multilangString: str, language})}
    </>
}

export default LocalizedString;
