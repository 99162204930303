import './App.css'
import React from 'react'
import Navbar from './common/component/Navbar'
import {Spinner} from 'react-bootstrap'
import {Route, Routes} from 'react-router-dom'
import Event from './page/events/Event'
import {default as EventCreate} from './page/events/Create'
import {default as RaceCreate} from './page/race/Create'
import Edit from './page/race/Edit'
import {default as EventsIndex} from './page/events/inner_page/IndexPage'
import Users from './page/user/Users'
import {default as MerchandiseCreate} from './page/merchandise/Create'
import {default as MerchandiseEdit} from './page/merchandise/Edit'
import {default as MerchandiseIndex} from './page/merchandise/IndexPage'
import AuthContextProvider from './context/AuthContext'
import ParticipantListPage from './page/participant/page/ParticipantListPage'
//import Info from './page/info/Info'
import CorporateSignupRequest from "./page/corporate/CorporateSignupRequestListPage";
import CurrencyListContext from './context/CurrencyListContext'

const App = () => {
    return (
        <React.Suspense fallback={<Spinner animation={'border'}/>}>
            <AuthContextProvider>
                <CurrencyListContext>
                    <Navbar/>
                    {/*<Breadcrumbs/>*/}
                    {/*{routes}*/}
                    <Routes>
                        <Route path="admin/merchandise/create" element={<MerchandiseCreate/>}/>
                        <Route path="admin/merchandise/edit/:id" element={<MerchandiseEdit/>}/>
                        <Route path="admin/merchandise" element={<MerchandiseIndex/>}/>
                        <Route path="admin/events/race/create/:eventId" element={<RaceCreate/>}/>
                        <Route path="admin/events/race/edit/:id" element={<Edit/>}/>
                        <Route path="admin/events/edit/:id" element={<Event/>}/>
                        <Route path="admin/events/create" element={<EventCreate/>}/>
                        <Route path="admin/events" element={<EventsIndex/>}/>
                        <Route path="admin/users" element={<Users/>}/>
                        <Route path="admin/participants" element={<ParticipantListPage/>}/>
                        <Route path="admin/corporate" element={<CorporateSignupRequest/>}/>
                        {/*<Route path="admin/info" element={<Info/>}/>*/}
                    </Routes>
                </CurrencyListContext>
            </AuthContextProvider>
        </React.Suspense>
    )
}

export default App
