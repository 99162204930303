import {chunk} from "lodash";

interface MLObject {
    [k: string]: string;
}

interface GetStringForLanguageParams {
    multilangString: string,
    language: string,
    returnAllIfNotMultilang?: boolean,
    returnFirst?: boolean
}

/**
 * Used for handling multi-language strings
 * Each of those strings can contain translations for multiple languages
 * Format is [:language]text[:language_2]Another text[:]
 * For example `[:en]Hello World![:uk]Привіт Світ![:]`
 */
class ML {
    public static stringToMLString(string: string, language: string): string {
        return `[:${language}]${string}[:]`
    }

    public static isMLString(string: string): boolean {
        // noinspection RegExpRedundantEscape
        return /\[:[a-z-]+\]/im.test(string)
    }

    public static mlStringToMLObject(str: string): MLObject {
        const result: MLObject = {}

        // noinspection RegExpRedundantEscape
        for (let it of chunk(str.split(/\[:([a-z-]*)\]/im).filter(it => it !== ''), 2)) {
            result[it[0]] = it[1]
        }
        return result
    }

    public static mlObjectToMlString(obj: MLObject): string {
        return Object.entries(obj).map(([lng, text]): string => {
            return `[:${lng}]${text}`.trim()
        }).join('') + '[:]'
    }

    public static updateLanguage(multilangString: string, targetString: string, language: string | null): string {
        if (typeof language !== 'string') {
            return multilangString;
        }
        if (!this.isMLString(multilangString)) {
            multilangString = this.stringToMLString(multilangString, language)
        }
        const obj: MLObject = this.mlStringToMLObject(multilangString)
        obj[language] = targetString

        return this.mlObjectToMlString(obj)
    }

    /**
     * @param multilangString multilang string or simple string, [:en]Eng[:uk]Укр[:]
     * @param language language, case-sensitive
     * @param returnAllIfNotMultilang if string isn`t multilang then return {multilangString}
     * @param returnFirst if required language doesn't exist then return first language string getStringForLanguage('[:en]Hello[:]', 'uk', returnFirst: true) will return 'Hello'
     */
    public static getStringForLanguage({
                                           multilangString,
                                           language,
                                           returnAllIfNotMultilang = true,
                                           returnFirst = false,
                                       }: GetStringForLanguageParams): string | null {

        if (!this.isMLString(multilangString)) {
            if (returnAllIfNotMultilang) {
                return multilangString
            }
            return null
        }
        const obj: MLObject = this.mlStringToMLObject(multilangString)
        //console.log(obj,language)
        let result: string | null = obj[language] ?? null
        if (result === null && returnFirst) {
            result = Object.values(obj)[0] ?? null
        }
        return result
    }
}


export default ML
