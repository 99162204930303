import {useTranslation} from "react-i18next";
import {ChangeEvent, useLayoutEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize} from "@mui/material";
import {DoNotDisturb} from "@mui/icons-material";
import {User} from "./DocumentsModal";

interface DeclineDialogProps {
    isOpen: boolean,
    close: () => void,
    decline: (comment: string) => void,
    user: User | null
}

const DeclineDialog = ({isOpen, close, user, decline}: DeclineDialogProps) => {
    const {t} = useTranslation()
    const [comment, setComment] = useState<string>('')

    useLayoutEffect((): void => {
        setComment('')
    }, [user])

    return <Dialog sx={{zIndex: '1301'}} open={isOpen} onClose={close}>
        <DialogTitle>
            {t('document.decline_modal.title')}
        </DialogTitle>
        <DialogContent>
            <Box textAlign='center'>
                {t('document.decline_modal.text')}
                <TextareaAutosize value={comment} minRows={3}
                                  onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
                                      setComment(e.target.value)
                                  }}/>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>
                {t('document.decline_modal.cancel_btn')}
            </Button>
            <Button endIcon={<DoNotDisturb/>} onClick={(): void => decline(comment)}>
                {t('document.decline_modal.decline_btn')}
            </Button>
        </DialogActions>
    </Dialog>
}
export default DeclineDialog
