import { useEffect, useState } from 'react'
import {
  apiRequest,
  backendAddress,
  getErrorMessage,
  PATH_USER_INDEX,
  PATH_USER_EXPORT
} from '../../common/backend'
import PageLoader from '../../common/component/PageLoader'
import { Table } from 'react-bootstrap'
import { paginateArray } from '../../common/commons'
import UserModal from './UserModal'
import User from './User'
import DeleteUserModal from './DeleteUserModal'
import CreateUserModal from './CreateUserModal'
import { useTranslation } from 'react-i18next'
import DocumentsModal from './documents/DocumentsModal'
import UploadKeyModal from './documents/UploadKeyModal'
import { useAuth } from '../../context/AuthContext'
import axios from 'axios'
import M from 'materialize-css'
import i18n from 'i18next'
import $ from 'jquery'
import { Button } from '@mui/material'
import { Add, Download } from '@mui/icons-material'

const Users = () => {
  const [users, setUsers] = useState(null)
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [search, setSearchState] = useState('')

  const [role, setRole] = useState(-1)
  const [docVerificationStatus, setDocVerificationStatus] = useState(-1)

  const { t } = useTranslation()

  const [user, setUser] = useState(null)
  const [userRemove, setUserRemove] = useState(null)
  const [creationOpen, setCreationOpen] = useState(false)

  const [decryptKey, setDecryptKey] = useState(null)
  const [importKeyOpen, setImportKeyOpen] = useState(false)
  const [documentsVerifyingUser, setDocumentsVerifyingUser] = useState(null)

  const isAdmin = useAuth().user?.role === 1

  useEffect(() => {
    apiRequest(PATH_USER_INDEX, res => {
      setUsers(res.data)
    }, () => {
    }, {}, 'get')
  }, [])

  if (users === null) {
    return <PageLoader/>
  }

  const getFiltered = () => {
    return users.filter(it => {
      if (search.length !== 0) {
        if (!['email', 'second_name', 'first_name', 'last_name', 'club', 'team'].some(prop => (it[prop] ?? '').toLowerCase().includes(search))) {
          return false
        }
      }
      if (role !== -1 && it.role !== role) {
        return false
      }
      return !(docVerificationStatus !== -1 && it.documents_status !== docVerificationStatus);
    })
  }

  const getPage = () => paginateArray(getFiltered(), perPage, page)

  const maxPage = Math.ceil(getFiltered().length / perPage)

  const previousPage = e => {
    e.preventDefault()
    const newPage = page - 1
    if (newPage < 1) {
      return
    }
    setPage(newPage)
  }

  const nextPage = e => {
    e.preventDefault()
    const newPage = page + 1
    if (newPage > maxPage) {
      return
    }
    setPage(newPage)
  }

  const setSearch = (val) => {
    setPage(1)
    setSearchState(val || '')
  }
  const setVerificationStatusFilter = (val) => {
    setPage(1)
    setDocVerificationStatus(val)
  }
  const setRoleFilter = (val) => {
    setPage(1)
    setRole(val)
  }

  const addUser = (user) => {
    const newUsers = [...users]
    newUsers.unshift(user)
    setUsers(newUsers)
  }

  const exportUsers = async () => {
    try {
      const data = {
        role: role,
        docs: docVerificationStatus,
      }
      const query = '?'+$.param(data)
      const response = await axios.get(backendAddress + PATH_USER_EXPORT + query, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('api-key'),
          'Accept-Language': i18n.language
        }
      })
      const contentDisposition = response.headers['content-disposition']
      let filename = 'users_export.xlsx'

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/)
        if (match && match[1]) {
          filename = match[1]
          filename = decodeURIComponent(filename)
          filename = filename.replace('+', ' ')
        }
      }

      const fileContent = response.data
      const url = URL.createObjectURL(fileContent)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      M.toast({ html: getErrorMessage(error), classes: 'rounded red large' })
    }
  }

  return <div style={{
    maxWidth: '80%',
    margin: 'auto'
  }}>
    <UploadKeyModal isOpen={importKeyOpen} close={() => {
      setImportKeyOpen(false)
      setDocumentsVerifyingUser(null)
    }} setKey={(key) => {
      setImportKeyOpen(false)
      setDecryptKey(key)
    }}/>
    <DocumentsModal close={() => setDocumentsVerifyingUser(null)}
                    rsaKey={decryptKey}
                    open={documentsVerifyingUser !== null && decryptKey !== null}
                    user={decryptKey === null ? null : documentsVerifyingUser}/>
    <UserModal user={user} setUser={setUser}/>
    <DeleteUserModal user={userRemove} remove={() => setUsers([...users])}
                     close={() => setUserRemove(null)}/>
    <CreateUserModal add={addUser} open={creationOpen} close={() => setCreationOpen(false)}/>
    <div className="row">
      <div className="col input-field s5">
        <input style={{ marginTop: '20px' }} type="text" placeholder={t('users_list.search')} value={search}
               onChange={e => {
                 let val = e.target.value
                 if (val.length === 0) {
                   val = null
                 }
                 setSearch(val)
               }}/>
      </div>
      <div className="col input-field s1">
        <label className="active">{t('users_list.filter.role')}</label>
        <select onChange={e => setRoleFilter(parseInt(e.target.value))} className="browser-default">
          <option value={-1}>{t('users_list.filter.role.all')}</option>
          <option value={0}>{t('users_list.filter.role.user')}</option>
          <option value={1}>{t('users_list.filter.role.admin')}</option>
          <option value={2}>{t('users_list.filter.role.organizer')}</option>
        </select>
      </div>
      <div className="col input-field s1">
        <label className="active">{t('users_list.filter.docs_status')}</label>
        <select onChange={e => setVerificationStatusFilter(parseInt(e.target.value))} className="browser-default">
          <option value={-1}>{t('users_list.filter.docs_status.all')}</option>
          <option value={0}>{t('users_list.filter.docs_status.waiting')}</option>
          <option value={1}>{t('users_list.filter.docs_status.verified')}</option>
          <option value={2}>{t('users_list.filter.docs_status.declined')}</option>
          <option value={3}>{t('users_list.filter.docs_status.not_sent')}</option>
        </select>
        {/*<input style={{ marginTop: '20px' }} type="text" placeholder={t('users_list.search')} value={search}
               onChange={e => {
                 let val = e.target.value
                 if (val.length === 0) {
                   val = null
                 }
                 setSearch(val)
               }}/>*/}
      </div>
      <div className="col s1 input-field center">
        <Button endIcon={<Add/>} onClick={() => setCreationOpen(true)}
                sx={{ margin: 'auto', padding: '20px' }}
                color="primary" size="large">
          {t('race_merch.add_btn')}
        </Button>
      </div>
      <div className="col input-field right">
        <Button startIcon={<Download/>} color="primary" size="large" onClick={exportUsers}>
          {t('users_list.export_button')}
        </Button>
      </div>
    </div>
    <Table>
      <thead>
      <tr>
        {['email', 'role', 'verified', 'verification', 'register_date', 'login_date']
          .map(it => <th className="center" key={it}>{t(`users_list.table.${it}`)}</th>)}
        <th className="center"><i className="material-icons">visibility</i></th>
        <th className="center"><i className="material-icons">delete_forever</i></th>
      </tr>
      </thead>
      <tbody>
      {getPage().map(it => <User key={it.id} openVerification={user => {
        if (decryptKey === null) {
          setImportKeyOpen(true)
        }
        setDocumentsVerifyingUser(user)
      }} user={it} open={setUser} remove={setUserRemove}/>)}
      </tbody>
    </Table>
    <ul className="pagination">
      <li className={page === 1 ? 'disabled' : 'waves-effect'}>
        <a href="#!" onClick={previousPage}>
          <i className="material-icons">chevron_left</i>
        </a>
      </li>
      <li className="active">
        <a href="#!" onClick={e => e.preventDefault()}>{page}</a>
      </li>
      <li className={page === maxPage ? 'disabled' : 'waves-effect'} onClick={nextPage}>
        <a href="#!"><i className="material-icons">chevron_right</i></a>
      </li>
    </ul>
  </div>
}

export default Users
