import {ReactElement, useEffect, useState} from 'react'
import {apiRequest, PATH_GET_LANGUAGE_LIST, PATH_USER_UPDATE_LANGUAGE} from '../backend'
import {useTranslation} from 'react-i18next'

interface LanguageList {
    [k: string]: string
}

const LanguageSelect = () => {
    const {i18n} = useTranslation()
    const [list, setList] = useState<LanguageList | null>(null)
    useEffect(() => {
        apiRequest(PATH_GET_LANGUAGE_LIST, (res: { data: LanguageList }): void => {
            setList(res.data)
        })
    }, [])

    const updateLanguage = (code: string): void => {
        i18n.changeLanguage(code)
        apiRequest(PATH_USER_UPDATE_LANGUAGE + `?lang=${code}`)
    }

    return <select style={{marginTop: '5px'}} className="browser-default" value={i18n.language}
                   onChange={e => updateLanguage(e.target.value)}>
        {Object.entries(list || {}).map(([code, name]): ReactElement => {
            return <option key={code} value={code}>{name}</option>
        })}
    </select>
}

export default LanguageSelect
