import React, {useState} from 'react'
import {apiRequest, getErrorMessage, PATH_PARTICIPANT_ID} from '../../../common/backend'
import M from 'materialize-css'
import PageLoader from '../../../common/component/PageLoader'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";

interface ParticipantDeleteConfirmationProps {
    participant: Participant | null,
    close: () => void,
    success: (participantID: number) => void
}

const ParticipantDeleteConfirmation = ({participant, close, success}: ParticipantDeleteConfirmationProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const {t} = useTranslation()

    const deleteParticipant = (): void => {
        setLoading(true)
        apiRequest(PATH_PARTICIPANT_ID + participant!.id, (): void => {
            M.toast({html: t('delete_participant_dialog.deleted_success'), classes: 'rounded green large'})
            success(participant!.id!)
            close()
            // @ts-ignore
        }, (e): void => {
            M.toast({
                html: getErrorMessage(e),
                classes: 'rounded red large'
            })
        }, undefined, 'DELETE')!!
            .finally((): void => {
                setLoading(false)
            })
    }

    return <>
        {loading ? <PageLoader/> : null}
        <Dialog open={participant !== null} onClose={close}>
            <DialogContent>
                {t('delete_participant_dialog.header', {id: participant?.id, email: participant?.email})}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    {t('delete_participant_dialog.cancel_btn')}
                </Button>
                <Button onClick={deleteParticipant}>
                    {t('delete_participant_dialog.delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default ParticipantDeleteConfirmation;
