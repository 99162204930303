import React, {ReactElement} from 'react'
import ML from '../../../common/multilang'
import {useTranslation} from 'react-i18next'
import {IconButton} from "@mui/material";
import {DeleteForever, Edit} from "@mui/icons-material";

interface ParticipantData {
    first_name: string;
    last_name: string;
    start_number: string;
    event?: { name: string };
    race?: { name: string };
    decoded_merchandise: { [k: string]: number };
    created_at: string;

    [k: string]: any;
}

interface ParticipantProps {
    data: ParticipantData,
    openEdit: (participant: ParticipantData) => void,
    openDelete: (participant: ParticipantData) => void,
}

const Participant = ({data, openEdit, openDelete}: ParticipantProps) => {
    const language: string = useTranslation().i18n.language

    return <tr>
        <td>
            {ML.getStringForLanguage({
                multilangString: (data.event || {name: 'Deleted'}).name,
                language,
                returnFirst: true
            })}
        </td>
        <td>
            {ML.getStringForLanguage({
                multilangString: (data.race || {name: 'Deleted'}).name,
                language,
                returnFirst: true
            })}
        </td>
        <td>
            {data.start_number || '???'}
        </td>
        <td>
            {data.last_name + ' ' + data.first_name}
        </td>
        {['promocode', 'price_final'].map((it: string): ReactElement => {
            return <td key={it}>{data[it]}</td>
        })}
        <td>
            <IconButton onClick={(): void => openEdit(data)} size='large' color='primary'>
                <Edit/>
            </IconButton>
        </td>
        <td>
            <ul>
                {Object.entries(data.decoded_merchandise).map(([name, count]): ReactElement => {
                    return <li>{ML.getStringForLanguage({multilangString: name, language})}-{count}</li>
                })}
            </ul>
        </td>
        <td>
            <IconButton size='large' onClick={(): void => openDelete(data)}>
                <DeleteForever color='error'/>
            </IconButton>
        </td>
        <td>
            {data.created_at}
        </td>
    </tr>
}
export default Participant
