import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CommonInputProps} from "./DefaultInput";

const sizeList: string[] = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']

const ShirtSizeInput = ({change, value}: CommonInputProps) => {
    const [size, setSize] = useState<string>(value as string)
    const {t} = useTranslation()

    useEffect((): void => {
        if (!value) {
            return
        }
        setSize(value as string)
    }, [value])

    return <>
        <label className='active'>{t('shirt_size')}</label>
        <select value={size} onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
            setSize(e.target.value)
            change('shirt_size', e.target.value)
        }} required={true} className='browser-default'>
            <option value="" disabled>{t('shirt_size')}</option>
            {sizeList.map((it: string): ReactElement => {
                return <option key={it} value={it}>{it}</option>
            })}
        </select>
    </>
}
export default ShirtSizeInput