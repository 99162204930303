import { useEffect, useRef, useState } from 'react'
import PageLoader from '../../../../common/component/PageLoader'
import { apiRequest, PATH_RACE_UPDATE, PATH_RACE_VIEW } from '../../../../common/backend'
import M from 'materialize-css'
import FixedSaveButton from '../../../../common/component/FixedSaveButton'
import GenderList from './GenderList'
import { useTranslation } from 'react-i18next'

const rangePresets = [
  { //+5 years
    '1': [
      { 'min': 1, 'max': 19, 'name': 'F up to 19' },
      { 'min': 20, 'max': 24, 'name': 'F 20-24' },
      { 'min': 25, 'max': 29, 'name': 'F 25-29' }, { 'min': 30, 'max': 34, 'name': 'F 30-34' }, {
        'min': 35,
        'max': 39,
        'name': 'F 35-39'
      }, { 'min': 40, 'max': 44, 'name': 'F 40-44' }, {
        'min': 45,
        'max': 49,
        'name': 'F 45-49'
      }, { 'min': 50, 'max': 54, 'name': 'F 50-54' }, {
        'min': 55,
        'max': 59,
        'name': 'F 55-59'
      }, { 'min': 60, 'max': 200, 'name': 'F 60+' }],
    '0': [{ 'min': 1, 'max': 19, 'name': 'M up to 19' }, {
      'min': 20,
      'max': 24,
      'name': 'M 20-24'
    }, { 'min': 25, 'max': 29, 'name': 'M 25-29' }, { 'min': 30, 'max': 34, 'name': 'M 30-34' }, {
      'min': 35,
      'max': 39,
      'name': 'M 35-39'
    }, { 'min': 40, 'max': 44, 'name': 'M 40-44' }, { 'min': 45, 'max': 49, 'name': 'M 45-49' }, {
      'min': 50,
      'max': 54,
      'name': 'M 50-54'
    }, { 'min': 55, 'max': 59, 'name': 'M 55-59' }, { 'min': 60, 'max': 200, 'name': 'M 60+' }]
  },
  { //10 years
    '1': [{ 'min': 1, 'max': 9, 'name': 'F up to 9' }, {
      'min': 10,
      'max': 13,
      'name': 'F 10-13'
    }, { 'min': 14, 'max': 17, 'name': 'F 14-17' }, {
      'min': 18,
      'max': 29,
      'name': 'F 18-29'
    }, { 'min': 60, 'max': 200, 'name': 'F 60+' }, { 'min': 30, 'max': 39, 'name': 'F 30-39' }, {
      'min': 40,
      'max': 49,
      'name': 'F 40-49'
    }, { 'min': 50, 'max': 59, 'name': 'F 50-59' }],
    '0': [
      { 'min': 1, 'max': 9, 'name': 'M up to 9' },
      {
        'min': 10,
        'max': 13,
        'name': 'M 10-13'
      },
      { 'min': 14, 'max': 17, 'name': 'M 14-17' },
      {
        'min': 18,
        'max': 29,
        'name': 'M 18-29'
      },
      { 'min': 60, 'max': 200, 'name': 'M 60+' }, { 'min': 30, 'max': 39, 'name': 'M 30-39' }, {
        'min': 40,
        'max': 49,
        'name': 'M 40-49'
      }, { 'min': 50, 'max': 59, 'name': 'M 50-59' }]
  },
  {   //absolute + 10 years
    '1': [{ 'min': 1, 'max': 39, 'name': 'ABSOLUTE' }, {
      'min': 40,
      'max': 49,
      'name': 'F 40-49'
    }, { 'min': 50, 'max': 59, 'name': 'F 50-59' }, {
      'min': 60,
      'max': 69,
      'name': 'F 60-69'
    }, { 'min': 70, 'max': 79, 'name': 'F 70-79' }, { 'min': 80, 'max': 200, 'name': '80+' }],
    '0': [{ 'min': 1, 'max': 39, 'name': 'ABSOLUTE' }, {
      'min': 40,
      'max': 49,
      'name': 'M 40-49'
    }, { 'min': 50, 'max': 59, 'name': 'M 50-59' }, { 'min': 80, 'max': 200, 'name': '80+' }]
  }
]
const rangePresetsChild = [
  { // 0-7, 8-12
    '0': [{ 'min': 0, 'max': 7, 'name': 'M 0-7' }, { 'min': 8, 'max': 12, 'name': 'M 8-12' }],
    '1': [{ 'min': 0, 'max': 7, 'name': 'F 0-7' }, { 'min': 8, 'max': 12, 'name': 'F 8-12' }],
  },
]

const AgeRangePage = (props) => {

  const isChild = props.state.current?.is_child ?? false

  const raceId = props.raceId
  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()

  const [male, setMale] = useState(null)
  const [female, setFemale] = useState(null)

  const maleRef = useRef()
  const femaleRef = useRef()

  const GENDER_MALE = 0
  const GENDER_FEMALE = 1

  useEffect(() => {
    if (male === null) {
      apiRequest(PATH_RACE_VIEW + raceId, res => {
        const ranges = JSON.parse(res.data['years_range'])
        setMale(ranges[0])
        setFemale(ranges[1])
        setLoading(false)
      }, () => {}, undefined, 'get')
    }
    M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {})
  }, [])

  const loading = () => {
    if (isLoading) {
      return <PageLoader/>
    }
    return ''
  }

  const saveRanges = () => {
    setLoading(true)
    apiRequest(PATH_RACE_UPDATE + raceId, () => {
      setLoading(false)
      M.toast({ html: t('race_age_group.saving_success'), classes: 'rounded green large' })
    }, err => {
      setLoading(false)
      M.toast({ html: t('race_age_group.saving_error') + err.response.data[0].message, classes: 'rounded red large' })
    }, { 'years_range': JSON.stringify({ 0: maleRef.current.getData, 1: femaleRef.current.getData }) }, 'put')
  }

  // Requires for re-rendering while selecting preset
  const currentPreset = useRef('none')

  const selectPreset = (e) => {
    e.preventDefault()
    currentPreset.current = e.target.value
    maleRef.current.setData((isChild ? rangePresetsChild : rangePresets)[e.target.value]['0'])
    femaleRef.current.setData((isChild ? rangePresetsChild : rangePresets)[e.target.value]['1'])
  }

  if (male === null || female === null) {
    return <PageLoader/>
  }

  return <div style={{
    maxWidth: '100%',
    width: '80%',
    margin: 'auto'
  }}>
    {loading()}
    <FixedSaveButton action={saveRanges}/>
    <div className="row">
      <div className="input-field col s4 right">
        <label className="active">{t('race_age_group.presets')}</label>
        <select defaultValue="-1" onChange={e => selectPreset(e)} className="browser-default">
          <option disabled value="-1">{t('race_age_group.presets')}</option>
          {isChild ? <option value="0">0-7, 8-12</option> : <>
            <option value="0">{t('race_age_group.presets.5_years')}</option>
            <option value="1">{t('race_age_group.presets.10_years')}</option>
            <option value="2">{t('race_age_group.presets.absolute_10_years')}</option>
          </>}
        </select>
      </div>
    </div>
    <GenderList name={t('race_age_group.males_list_header')} initial={male} ref={maleRef}/>
    <GenderList name={t('race_age_group.females_list_header')} initial={female} ref={femaleRef}/>
    {/*{Object.entries(ranges).map(([genderId, rangeList]) => {
      return [
        <ul className="collection with-header">
          <li className="collection-header">
            <h4>{getGenderName(genderId)}</h4>
          </li>
          {sortBy(rangeList, it => it.min).map((it, i) => {
            return <li className="collection-item">
              <div className="row">
                <div className="input-field col s3">
                  <input min="1" max="200" type="number"
                         onChange={(e) => ranges[genderId][i]['min'] = parseInt($(e.target).val())} id={'min-age-' + i}
                         defaultValue={it['min']}/>
                  <label htmlFor={'min-age-' + i} className="active">Минимальный возраст</label>
                </div>
                <div className="input-field col s3">
                  <input min="1" max="200" type="number"
                         onChange={(e) => ranges[genderId][i]['max'] = parseInt($(e.target).val())}
                         id={'max-age-' + i + '-' + genderId} defaultValue={it['max']}/>
                  <label htmlFor={'max-age-' + i} className="active">Максимальный возраст</label>
                </div>
                <div className="input-field col s3">
                  <input type="text" minLength="2" maxLength="255"
                         onChange={(e) => ranges[genderId][i]['name'] = $(e.target).val()} id={'name-' + i}
                         defaultValue={it['name']}/>
                  <label htmlFor={'name-' + i} className="active">Название</label>
                </div>
                <div className="input-field col s3">
                  <button onClick={() => {
                    const list = JSON.parse(JSON.stringify(ranges))
                    list[genderId].splice(i, 1)
                    setRanges(list)
                  }} className="btn waves-effect waves-red red">Удалить
                    <i className="material-icons right">delete</i>
                  </button>
                </div>
              </div>
            </li>
          })}
        </ul>,
        <br/>,
        <a onClick={() => {
          const list = JSON.parse(JSON.stringify(ranges))
          list[genderId].push({
            min: 1,
            max: 2,
            name: ''
          })
          setRanges(list)
        }} className="btn-floating btn-large green"><i
          className="material-icons">add</i></a>,
        <br/>,
        <br/>
      ]
    })}*/}
  </div>
}

export default AgeRangePage
