import {ChangeEvent, ReactElement, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Input} from "@mui/material";
import {useTranslation} from "react-i18next";
import PageLoader from "../../../common/component/PageLoader";
import {importPrivateKey} from "../../../common/crypt";

interface UploadKeyModalProps {
    isOpen: boolean,
    close: () => void,
    setKey: (key: CryptoKey) => void
}

const UploadKeyModal = ({isOpen, close, setKey}: UploadKeyModalProps): ReactElement => {
    const {t} = useTranslation()
    const [isLoading, setLoading] = useState<boolean>(false)

    const tryKey = (key: File): void => {
        key.text().then(async (text: string): Promise<CryptoKey | null> => {
            try {
                const result: void | CryptoKey = await importPrivateKey(text)
                if (result instanceof CryptoKey) {
                    return result
                } else {
                    return null
                }
            } catch (e) {
                console.log(e)
            }
            return null;
        }).then((it: CryptoKey | null): void => {
            if (it === null) {
                throw new Error()
            }
            M.toast({
                html: t('document.import_key.success'), classes: 'rounded green large'
            })
            setKey(it)
        }).catch((e): void => {
            M.toast({
                html: t('document.import_key.error'), classes: 'rounded red large'
            })
            console.log(e)
        }).finally((): void => setLoading(false))
    }

    return <>
        {isLoading && <PageLoader/>}
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>
                {t('document.import_key.title')}
            </DialogTitle>
            <DialogContent>
                <Input type='file' inputProps={{
                    accept: '.pem',
                }} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const file: File | null = e.target.files?.item(0) ?? null
                    if (file !== null) {
                        tryKey(file)
                    }
                }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t('document.import_key.close_btn')}</Button>
            </DialogActions>
        </Dialog>
    </>
}
export default UploadKeyModal
