import ML from '../../common/multilang'
import M from 'materialize-css'
import { useState } from 'react'
import {useTranslation} from 'react-i18next'
import {IconButton} from "@mui/material";
import {apiRequest, getErrorMessage, PATH_CORPORATE_SET_STATUS} from "../../common/backend";
import { Tooltip, Typography, Box } from '@mui/material'
import { ThumbUp, ThumbDown, Block, PendingActions, MonetizationOn, MoneyOff, CheckCircleOutline, Warning } from '@mui/icons-material'
import StatusLoader from "../../common/component/StatusLoader";

const CorporateSignupRequest = ({requestData}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const success = () => M.toast({ html: t('user_modal.saved'), classes: 'rounded green large' })
    const error = (err) => M.toast({
        html: t('user_modal.save_error') + +getErrorMessage(err),
        classes: 'rounded red large'
    })

    const approve = () => {
        setLoading(true)
        apiRequest(
            PATH_CORPORATE_SET_STATUS,
            () => {success()},
            err => error(err),
            {id:requestData.id, status: 1},
            'put'
        ).finally(() => setLoading(false))
    }

    const decline = () => {
        setLoading(true)
        apiRequest(
            PATH_CORPORATE_SET_STATUS,
            () => {success()},
            err => error(err),
            {id:requestData.id, status: 2},
            'put'
        ).finally(() => setLoading(false))
    }

    const requestStatus = () => {
        let color
        let Icon
        let statusText
        let controlsVisible = false
        let approveBtnText = t('users_list.table.approve_email')
        let declineBtnText = t('users_list.table.approve_email')

        switch (requestData.status) {
            case 0:
                // statusText = 'corporate.signup.status.pending'
                // Icon = PendingActions
                // color = 'warning'
                controlsVisible = true
                break
            case 1:
                statusText = 'corporate.signup.status.accepted'
                Icon = CheckCircleOutline
                color = 'primary'
                break
            case 2:
                statusText = 'corporate.signup.status.declined'
                Icon = Block
                color = 'error'
                break
            case 3:
                statusText = 'corporate.signup.status.awaiting_payment'
                Icon = MoneyOff
                color = 'warning'
                break
            case 4:
                statusText = 'corporate.signup.status.paid'
                Icon = MonetizationOn
                color = 'success'
                break
            default:
                statusText = 'document.unknown_error'
                Icon = Warning
                color = 'warning'
        }

        return <Box>
            {loading && <StatusLoader/>}
            {!controlsVisible &&
            <Tooltip title={<Typography variant="h6">{t(statusText)}</Typography>}>
                <IconButton style={{ cursor: 'inherit' }}>
                    <Icon size="large" color={color}/>
                </IconButton>
            </Tooltip>}
            {controlsVisible &&
                <Box>
                    <Tooltip title={<Typography variant="h6">{approveBtnText}</Typography>}>
                        <IconButton size="large" color="success" sx={{ marginLeft: '5px' }} onClick={approve}><ThumbUp/></IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">{declineBtnText}</Typography>}>
                        <IconButton size="large" color="error" sx={{ marginLeft: '5px' }} onClick={decline}><ThumbDown/></IconButton>
                    </Tooltip>
                </Box>
            }
        </Box>
    }

    return <tr>
        <td>{requestData.id}</td>
        <td>{requestData.user_id}</td>
        <td>{requestData.event_id}</td>
        <td className="center">{requestStatus()}</td>
        <td>{requestData.discount}</td>
        <td>{requestData.company_name}</td>
        <td>{requestData.registration_number}</td>
        <td>{requestData.team_name}</td>
        <td>{requestData.phone}</td>
        <td>{requestData.created_at}</td>
    </tr>
}

export default CorporateSignupRequest
