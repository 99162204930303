import axios from 'axios'
import i18n from 'i18next'
import { isObject } from 'lodash'

// noinspection HttpUrlsUsage
export const backendAddress = process.env.REACT_APP_BACKEND_ADDRESS
// export const backendAddress = 'https://admin-dev.newrun.com.ua/'

const DEBUG = false
export { DEBUG }

export const PATH_MY_NAME = 'user/myname'
export const PATH_USER_INDEX = 'user'
export const PATH_USER_VIEW = 'user/view/'
export const PATH_USER_EXPORT = 'user/export'
export const PATH_USER_UPDATE = 'user/'
export const PATH_USER_REQUEST_REMOVAL = 'user/request-removal'
export const PATH_USER_CREATE = 'user'
export const PATH_USER_CHANGE_PASSWORD = 'user/change-password'
export const PATH_USER_GET_DOCUMENTS = 'user/get-documents'
export const PATH_USER_ACCEPT_DOCUMENTS = 'user/accept-documents'
export const PATH_USER_DECLINE_DOCUMENTS = 'user/decline-documents'
export const PATH_USER_UPDATE_LANGUAGE = 'user/set-language'

export const PATH_EVENT_INDEX = 'event/index'
export const PATH_EVENT_VIEW = 'event/'
export const PATH_EVENT_UPDATE = 'event/'
export const PATH_EVENT_CREATE = 'event'
export const PATH_EVENT_DELETE = 'event/'
export const PATH_EVENT_UPDATE_IMAGE = 'event/image?target='
export const PATH_EVENT_UPDATE_REGULATION = 'event/regulation?target='

export const PATH_RACE_VIEW = 'race/'
export const PATH_RACE_DELETE = 'race/'
export const PATH_RACE_UPDATE = 'race/'
export const PATH_RACE_CREATE = 'race'
export const PATH_RACE_GET_BY_EVENT = 'race/by-event'
export const PATH_RACE_GET_MERCHANDISE = 'race/get-merchandise?race='

export const PATH_MERCHANDISE_INDEX = 'merchandise'
export const PATH_MERCHANDISE_CREATE = 'merchandise'
export const PATH_MERCHANDISE_VIEW = 'merchandise/'
export const PATH_MERCHANDISE_UPDATE = 'merchandise/'
export const PATH_MERCHANDISE_DELETE = 'merchandise/'
export const PATH_MERCHANDISE_UPDATE_IMAGE = 'merchandise/image?target='

export const PATH_INFO_INDEX = 'info'
export const PATH_INFO_VIEW = 'info/'

export const PATH_TEXT_BLOCK_VIEW = 'text-block/'
export const PATH_TEXT_BLOCK_UPDATE = 'text-block/'
export const PATH_TEXT_BLOCK_CREATE = 'text-block'
export const PATH_TEXT_BLOCK_DELETE = 'text-block/'
export const PATH_TEXT_BLOCK_GET_BY_RACE = 'text-block/by-race'

export const PATH_MAILER_GET_ALL_EMAILS = 'mail/get-all-emails'
export const PATH_MAILER_SEND = 'mail/send'

export const PATH_NOVAPOSHTA_GET_AREAS = 'site/nova-poshta-get-areas'
export const PATH_NOVAPOSHTA_GET_CITIES = 'site/nova-poshta-get-cities'
export const PATH_NOVAPOSHTA_GET_WAREHOUSES = 'site/nova-poshta-get-warehouses'

export const PATH_PARTICIPANT_INDEX = 'participant'
export const PATH_PARTICIPANT_EXPORT = 'participant/export?'
export const PATH_PARTICIPANT_ID = 'participant/'

export const PATH_CUSTOM_FIELD = 'custom-field'
export const PATH_CUSTOM_FIELD_VIEW = 'custom-field/'
export const PATH_CUSTOM_FIELD_BY_RACE = 'custom-field/by-race'

export const PATH_GET_LANGUAGE_LIST = 'site/get-languages'

export const PATH_GET_CURRENCIES = 'site/currencies'

export const PATH_CORPORATE_SIGNUP_REQUEST_LIST = 'admin-corporate/list'
export const PATH_CORPORATE_SET_STATUS = 'admin-corporate/set-status'

export const canAuth = () => {
  return localStorage.getItem('api-key') !== null
}

/**
 * @param {{path:string,method:string,headers:{[k:string]:string}},onerror} requestData
 */
export const apiRequestRaw = (requestData) => {
  const { path, method = 'POST', headers = {}, onerror = undefined, data = undefined } = requestData
  if (!canAuth()) {
    // Эти запросы можно произвести не имея ключа доступа
    if (!['nova-poshta-get-areas', 'nova-poshta-get-cities', 'nova-poshta-get-warehouses', PATH_GET_CURRENCIES].some(it => path.includes(it))) {
      if (DEBUG) console.log('Cannot execute api request, no auth. Path="' + path + '"')
      if (onerror) {
        onerror()
      }
      throw new Error('Attempted')
    }
  }
  if (!['nova-poshta-get-areas', 'nova-poshta-get-cities', 'nova-poshta-get-warehouses'].some((it) => {
    return path.indexOf(it) !== -1
  })) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('api-key')
  }
  headers['Accept-Language'] = i18n.language
  if (DEBUG) {
    console.log('HEADERS & DATA for ' + path)
    console.log(headers)
    console.log(data)
  }
  return axios({
    method: method,
    data: data, //IMPORTANT: passing empty object breaks yii page cache
    url: backendAddress + path,
    headers: headers
  })
}

export const apiRequest = (path, onsuccess, onerror = undefined, data = undefined, method = 'post', headers = {}) => {
  return apiRequestRaw({ path, onerror, method, headers, data }).then((response) => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      if (DEBUG) console.log(backendAddress + path + ' ' + method + ' success')
      onsuccess(response)
    } else {
      if (DEBUG) console.log(backendAddress + path + ' failed')
      if (onerror) onerror(response)
    }
    return response
  }).catch((e) => {
    if (DEBUG) console.log(e)
    if (DEBUG) console.log(backendAddress + path + ' request failed')
    if (onerror) onerror(e)
  })
}

export const getAccountData = (userId, onsuccess, onerror) => {
  apiRequest(PATH_USER_VIEW + '?id=' + userId, onsuccess, onerror, undefined, 'get')
}

export const updateAccountData = (userId, data, onsuccess, onerror) => {
  data['birthday'] = data['year'] + '-' + data['month'] + '-' + data['day']
  apiRequest(PATH_USER_UPDATE + '?id=' + userId, onsuccess, onerror, data, 'put')
}

export const changePassword = (userId, password, onsuccess, onerror) => {
  apiRequest(PATH_USER_CHANGE_PASSWORD + '?id=' + userId, onsuccess, onerror, { 'password': password })
}

/**
 * Получает список областей новой почты и передает их в callback
 */
export const npGetAreas = (callback) => {
  apiRequest(PATH_NOVAPOSHTA_GET_AREAS, (res) => {
    callback(res.data)
  })
}

/**
 * Получает список городов новой почты используя Ref области и передает их в callback
 */
export const npGetCities = (callback, areaRef) => {
  apiRequest(PATH_NOVAPOSHTA_GET_CITIES + '?area=' + areaRef, (res) => {
    callback(res.data)
  })
}

/**
 * Получает список отделений и почтоматов новой почты используя Ref города и передает их в callback
 */
export const npGetWarehouses = (callback, cityRef) => {
  apiRequest(PATH_NOVAPOSHTA_GET_WAREHOUSES + '?city=' + cityRef, (res) => {
    callback(res.data)
  })
}

export const getErrorMessage = obj => {
  if (obj instanceof String) {
    return obj.substring(0, 10)
  }
  if (Array.isArray(obj)){
    if (isObject(obj[0])){
      if (obj[0].message!==null){
        return obj[0].message;
      }
    }
  }
  if ('request' in obj) {
    if (!obj.request || !obj.request.response) {
      return 'Request error'
    }
  }
  if ('response' in obj) {
    obj = obj.response
    if ('data' in obj) {
      obj = obj.data
    }
  }

  if ('message' in obj && obj.message.length > 0) {
    return obj.message
  }
  if (Array.isArray(obj) && 'field' in obj[0] && 'message' in obj[0]) {
    return obj.map(it => it.field + ': ' + it.message).join(', ')
  }
  if (obj.request && obj.request.response && 'message' in obj.request.response && obj.request.response.message.length > 0) {
    return obj.request.response.message
  }
  if ('error' in obj) {
    const err = obj.error
    if (typeof err === 'object' && 'message' in err) {
      return err.message
    }
    return err
  }
  if ('exception' in obj) {
    return obj.exception
  }
  return obj.toString()
}
