import React, {ChangeEvent, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {CommonInputProps} from "./DefaultInput";
import ML from "../../../../common/multilang";

const RaceInput = (props: CommonInputProps): ReactElement => {
    const {races, change, value, immutable = false} = props
    const {t, i18n} = useTranslation()
    if (races === null) {
        return <></>
    }
    return <select key={Object.values(races)[0]?.event} onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
        if (immutable) {
            return
        }
        change('race_id', races[parseInt(e.target.value)])
    }} required={true} value={value === 0 ? '' : value} disabled={immutable} className="browser-default">
        <option value='' disabled={true}>{t('race')}</option>
        {Object.values(races).map((race: Race): ReactElement => {
            return <option key={race.id} value={race.id}>
                {ML.getStringForLanguage({multilangString: race.event_name!, language: i18n.language})}:
                {ML.getStringForLanguage({multilangString: race.name, language: i18n.language})}
            </option>
        })}
    </select>
}
export default RaceInput
