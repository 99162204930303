import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { clone } from 'lodash'
import { useCurrencyList } from '../../../../context/CurrencyListContext'

const Promocode = ({ propsData, id, remove, value, update }) => {

  const { t } = useTranslation()
  const currencies = useCurrencyList()

  const [data, setDataState] = useState(propsData)

  useEffect(() => {
    update(data)
  }, [data])

  const setData = (data) => {
    setDataState(data)
  }

  const setDiscountType = (val) => {
    const dataCopy = clone(data)
    dataCopy['discount_type'] = parseInt(val)
    setData(dataCopy)
  }

  const setMax = (val) => {
    const dataCopy = clone(data)
    dataCopy['max'] = parseInt(val)
    setData(dataCopy)
  }

  const setPercent = (val) => {
    const dataCopy = clone(data)
    dataCopy['discount_percent'] = parseInt(val)
    setData(dataCopy)
  }

  const setFixed = (val, code) => {
    const dataCopy = clone(data)
    dataCopy['discount'][code] = parseInt(val)
    setData(dataCopy)
  }

  return <li key={id} className="collection-item">
    <div className="row">
      <div className="input-field col s2">
        <input type="text"
               id={'value-' + id}
               readOnly={true}
               value={value}/>
        <label htmlFor={'value-' + id} className="active">{t('event.promocodes.promocode')}</label>
      </div>
      {data['discount_type'] === 1 ?
        currencies.map((currency) => {
          return <div key={currency} className="input-field col s1">
            <input required="required" type="number" min="1" max="999999"
                   onChange={(e) => setFixed(e.target.value, currency)}
                   id={'discount-' + id}
                   defaultValue={data['discount'][currency] ?? 0}/>
            <label htmlFor={'discount-' + id}
                   className="active">
              {currency}
            </label>
          </div>
        }) : <div className="input-field col s2">
          <input required="required" type="number" min="1" max="100"
                 onChange={(e) => {
                   let val = parseInt(e.target.value)
                   if (val > 100) {
                     val = 100
                   }
                   setPercent(val)
                   e.target.value = String(val)
                 }}
                 id={'discount-' + id}
                 defaultValue={data['discount_percent']}/>
          <label htmlFor={'discount' + id}
                 className="active">
            {t('event.promocodes.percent', { percent: ' %' })}
          </label>
        </div>
      }
      <div className="input-field col s2">
        <select onChange={e => setDiscountType(e.target.value)}
                className="browser-default" id={'discount_type-' + id}
                defaultValue={data['discount_type']}>
          <option value="0">{t('event.promocodes.discount_type.percent')}</option>
          <option value="1">{t('event.promocodes.discount_type.fixed')}</option>
        </select>
        <label htmlFor={'discount_type-' + id} className="active">{t('event.promocodes.discount_type')}</label>
      </div>
      <div className="input-field col s1">
        <input required="required" type="text"
               id={'used-' + id} readOnly={true}
               value={data['used'] + '/' + data['max']}/>
        <label htmlFor={'used-' + id} className="active">{t('event.promocodes.used_count')}</label>
      </div>
      <div className="input-field col s2">
        <input required="required" type="number" min="1" max="9999999"
               id={'max-' + id} onChange={e => setMax(e.target.value)}
               defaultValue={data['max']}/>
        <label htmlFor={'max-' + id} className="active">{t('event.promocodes.max_usages')}</label>
      </div>
      <div className="input-field col s3">
        <button className="btn waves-effect waves-red red" onClick={remove}>
          {t('event.promocodes.delete_btn')}
          <i className="material-icons right">delete</i>
        </button>
      </div>
    </div>
  </li>
}

export default Promocode
