import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import ML from "../multilang";

interface TranslatableTextInputProps {
    initialValue: string;
    update: (val: string) => void;
    language: string;
    name?: string;
    immutable?: boolean;
    classes?: string;
    inputClasses?: string;
}

const TranslatableTextInput = (
    {
        initialValue,
        update,
        language,
        name = '',
        immutable = false,
        classes = 'input-field col s6',
        inputClasses = 'validate',
    }: TranslatableTextInputProps): ReactElement => {

    const [value, setValue] = useState<string>(ML.getStringForLanguage({multilangString: initialValue, language}) ?? '')
    useEffect((): void => {
        setValue(ML.getStringForLanguage({multilangString: initialValue, language}) ?? '')
    }, [language])

    useEffect((): void => {
        update(ML.updateLanguage(initialValue, value, language))
    }, [value])

    return <div className={classes}>
        <input onChange={(e: ChangeEvent<HTMLInputElement>): void => setValue(e.target.value)} minLength={3}
               readOnly={immutable}
               maxLength={255} style={{display: 'inline-block', verticalAlign: "middle"}}
               className={inputClasses} required={true} type="text"
               id={name} name={name} value={value}/>
        <label htmlFor={name} className="active">
            {name}
        </label>
    </div>
}

export default TranslatableTextInput
