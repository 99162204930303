interface PageLoaderProps {
    zIndex?: number
}

const defaultZIndex: number = 2

const PageLoader = ({zIndex = defaultZIndex}: PageLoaderProps) => {
    return <div className="main-loader" style={{zIndex}}>
        <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                    <div className="circle"/>
                </div>
                <div className="gap-patch">
                    <div className="circle"/>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"/>
                </div>
            </div>
        </div>
    </div>
}

export default PageLoader