import React, {ChangeEvent, memo, NamedExoticComponent, ReactElement, useEffect, useState} from "react";
import {apiRequest, PATH_GET_LANGUAGE_LIST} from "../common/backend";
import i18n from "i18next";

interface UseMLValue {
    availableLanguages: string[] | null;
    language: string | null;
    setLanguage: (language: string) => void;
    Selector: ReactElement;
}

interface LanguageSelectorProps {
    language: string | null;
    onChange: (lng: string) => void;
    list: string[] | null;
}

const LanguageSelector: NamedExoticComponent<LanguageSelectorProps> = memo((props: LanguageSelectorProps): ReactElement => {
    const {language, onChange, list} = props;
    if (list === null || language === null) {
        return <></>
    }
    return <select className='browser-default' value={language} onChange={(e: ChangeEvent<HTMLSelectElement>): void => onChange(e.target.value)}>
        {list.map((it: string): ReactElement => {
            return <option key={it} value={it}>{it}</option>
        })}
    </select>
})

/**
 * Helps manage available languages and selected language when editing multi-language pages
 */
const useML = (defaultLanguage: string = i18n.language): UseMLValue => {
    const [availableLanguages, setAvailableLanguages] = useState<string[] | null>(null)
    const [language, setLanguage] = useState<string | null>(null)

    useEffect((): void => {
        apiRequest(PATH_GET_LANGUAGE_LIST, (res: { data: { [k: string]: string } }): void => {
            const list: string[] = Object.keys(res.data)
            setAvailableLanguages(list)
            let newLanguage: string = defaultLanguage
            if (!list.includes(newLanguage)) {
                newLanguage = list[0]
            }
            setLanguage(newLanguage)
        })
    }, [])

    return {
        availableLanguages,
        language,
        setLanguage,
        Selector: <LanguageSelector language={language} list={availableLanguages}
                                    onChange={(lng: string): void => setLanguage(lng)}/>
    }
}

export default useML
