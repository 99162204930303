import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react'
import PageLoader from '../../../common/component/PageLoader'
import {useTranslation} from 'react-i18next'
import {
    apiRequest,
    apiRequestRaw,
    PATH_CUSTOM_FIELD,
    PATH_CUSTOM_FIELD_VIEW,
    PATH_RACE_VIEW
} from "../../../common/backend";
import {Box, Button, Grid, IconButton} from "@mui/material";
import {Add, DeleteForever} from "@mui/icons-material";
import useML from "../../../hook/UseML";
import TranslatableTextInput from "../../../common/component/TranslatableTextInput";
import FixedSaveButton from "../../../common/component/FixedSaveButton";
import M from "materialize-css";
import {isNumber, uniq} from "lodash";

interface RequiredFieldsPageProps {
    raceId: number | null;
}

const STATIC_FIELDS: string[] = [
    'first_name_latin',
    'last_name_latin',
    'np',
]

interface CustomFieldObj {
    required: boolean;
    name: string;
    race: number;
    id?: number | null;
    deleted: boolean;
}

const RequiredFieldsPage = ({raceId}: RequiredFieldsPageProps): ReactElement => {
    const {t} = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [race, setRace] = useState<Race | null>(null)
    const [customFields, setCustomFields] = useState<CustomFieldObj[]>([])
    const [requiredFields, setRequiredFields] = useState<(string | number)[] | null>(null)

    const load = (): void => {
        if (raceId === null) {
            setRace(null)
            return;
        }
        setLoading(true)
        apiRequest(PATH_RACE_VIEW + raceId + '?showCustomFields=1', (res: {
            data: Race
        }): void => {
            setRace(res.data)

            setCustomFields(res.data.custom_fields!.map((it: CustomField): CustomFieldObj => {
                return {...it, required: res.data.required_fields.includes(it.id), deleted: false}
            }))
            setRequiredFields(res.data.required_fields)
        }, undefined, undefined, 'GET')!.finally((): void => setLoading(false))
    }

    useEffect(load, [raceId])
    const {Selector, availableLanguages, language} = useML()

    if (race === null || availableLanguages === null || requiredFields === null) {
        return <PageLoader/>
    }

    const save = async (): Promise<void> => {
        const required = [...requiredFields]
        // save custom fields
        const toDelete: CustomFieldObj[] = customFields.filter((it: CustomFieldObj): boolean => it.deleted)
        await Promise.all(toDelete.map(async (field: CustomFieldObj): Promise<any> => {
            if (!isNumber(field.id)) {
                return null
            }
            const index: number = required.indexOf(field.id!)
            if (index !== -1) {
                required.splice(index, 1)
            }
            return apiRequestRaw({
                path: PATH_CUSTOM_FIELD_VIEW + field.id,
                method: 'DELETE'
            })
        }))
        const toSave: CustomFieldObj[] = customFields.filter((it: CustomFieldObj): boolean => !it.deleted)
        for (const field of toSave) {
            if (field.id === null) {
                delete field.id
                field.id = (await apiRequestRaw({path: PATH_CUSTOM_FIELD, data: field})).data.id
            } else {
                (await apiRequestRaw({path: PATH_CUSTOM_FIELD_VIEW + field.id, data: field, method: 'PUT'}))
            }
            if (field.required) {
                required.push(field.id!)
            } else {
                const index: number = required.indexOf(field.id!)
                if (index !== -1) {
                    required.splice(index, 1)
                }
            }
        }
        // update race required fields
        try {
            // @ts-ignore
            await apiRequest(PATH_RACE_VIEW + raceId, undefined, undefined, {required_fields: uniq(required)}, 'PUT')
            M.toast({
                html: t('race_fields.saving_success'),
                classes: 'rounded green large'
            })
            load()
        } catch (e) {
            console.log(e)
            M.toast({
                html: t('race_fields.saving_error'),
                classes: 'rounded red large'
            })
        }
        setLoading(false)
    }

    return <>
        {isLoading && <PageLoader/>}
        <div style={{
            maxWidth: '100%',
            width: '80%',
            margin: 'auto'
        }}>
            {Selector}
            <Grid direction='column' container={true} textAlign='left'>
                {STATIC_FIELDS.map((it: string): ReactElement => {
                    return <label key={it}>
                        <input type="checkbox" defaultChecked={requiredFields.includes(it)}
                               onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                   const newList = [...requiredFields]
                                   if (e.target.checked) {
                                       newList.push(it)
                                   } else {
                                       const index: number = newList.indexOf(it)
                                       if (index !== -1) {
                                           newList.splice(index, 1)
                                       }
                                   }
                                   setRequiredFields(newList)
                               }}/>
                        <span>{t(`race_field.${it === 'np' ? 'nova_poshta' : it}`)}</span>
                    </label>
                })}
                {customFields.filter((it: CustomFieldObj): boolean => !it.deleted).map((it: CustomFieldObj): ReactElement => {
                    return <Box display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                gap={2} key={it.id || Math.random()}>
                        <label style={{marginRight: '10px'}}>
                            <input type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                it.required = e.target.checked
                            }} defaultChecked={it.required}/>
                            <span>{t('race_field.required')}</span>
                        </label>
                        <TranslatableTextInput initialValue={it.name} language={language!}
                                               name={t('race_field.custom_field_name')}
                                               classes='input-field col s3' inputClasses='browser-default'
                                               update={(name: string): void => {
                                                   it.name = name
                                               }}/>
                        <IconButton onClick={(): void => {
                            it.deleted = true
                            setCustomFields([...customFields])
                        }}>
                            <DeleteForever/>
                        </IconButton>
                    </Box>
                })}
                <Grid item={true}>
                    <Button onClick={(): void => {
                        const newList: CustomFieldObj[] = [...customFields]
                        newList.push({required: false, name: '', id: null, deleted: false, race: raceId!})
                        setCustomFields(newList)
                    }} startIcon={<Add/>}>Add</Button>
                </Grid>
            </Grid>
        </div>
        <FixedSaveButton action={save}/>
    </>
}

export default RequiredFieldsPage;
