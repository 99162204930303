import React, {ReactElement, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import Login from '../../auth/Login'
import {useAuth} from '../../context/AuthContext'
import LanguageSelect from './LanguageSelect'

interface AuthContentProps {
    authorized: boolean | null,
    user: User | null
}

const AuthContent = ({authorized, user}: AuthContentProps): ReactElement => {
    const {t} = useTranslation()
    switch (authorized) {
        case false:
            return <li>
                <a className="modal-trigger" href="#login-modal">
                    {t('header.login')}
                </a>
            </li>
        case true:
            return <>
                <li>
                    <Link to="/profile">
                        {t('header.your_profile', {name: user?.first_name})}
                    </Link>
                </li>
                <li>
                    <LanguageSelect/>
                </li>
            </>
        default:
            return <></>
    }
}

const navigationOrganizer: string[] = [
    'participants',
    'events'
]
const navigationAdmin: string[] = [
    ...navigationOrganizer,
    'merchandise',
    'users',
    'corporate'
]

const Navbar = (): ReactElement => {
    const {t} = useTranslation()

    const {authorized, user} = useAuth()

    let nav: string[] = []
    if (user?.role === 1) {
        nav = navigationAdmin
    } else if (user?.role === 2) {
        nav = navigationOrganizer
    }

    useEffect(() => {
        const M = window.M
        const sidenav = document.querySelectorAll('.sidenav')
        M.Sidenav.init(sidenav)
    }, [])

    return <div className="container" style={{maxWidth: '100%', width: '100%', backgroundColor: '#ee6e73'}}>
        {authorized === false && <Login/>}
        <nav style={{width: '80%', backgroundColor: '', boxShadow: 'none', margin: 'auto'}} className="nav-extended">
            <div className="nav-wrapper">
                <Link className="brand-logo" to="/">Race Next</Link>
                <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                    <i className="material-icons">
                        {t('header.menu')}
                    </i>
                </a>
                <ul id="nav-mobile" className="right hide-on-med-and-down">
                    {nav.map((it: string): ReactElement => {
                        return <li key={it}>
                            <Link to={`/admin/${it}`}>
                                {t(`header.${it}`)}
                            </Link>
                        </li>
                    })}
                    <AuthContent user={user} authorized={authorized}/>
                </ul>
            </div>
            <ul className="sidenav" id="mobile-demo">
                {nav.map((it: string): ReactElement => {
                    return <li key={it}>
                        <Link to={`/admin/${it}`}>
                            {t(`header.${it}`)}
                        </Link>
                    </li>
                })}
                <AuthContent user={user} authorized={authorized}/>
            </ul>
        </nav>
    </div>
}

export default Navbar
