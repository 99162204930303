import { apiRequest, getErrorMessage, PATH_USER_UPDATE } from '../../common/backend'
import M from 'materialize-css'
import { useState } from 'react'
import PageLoader from '../../common/component/PageLoader'
import { isObject } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip, Typography, Box } from '@mui/material'
import { Autorenew, Block, DeleteForever, CheckCircleOutline, Pending, Verified, CommentsDisabled, Report, PendingActions, Warning, Visibility } from '@mui/icons-material'

const roles = {
  0: 'user',
  1: 'admin',
  2: 'organizer'
}

const User = ({ user, remove, open, openVerification }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const updateRole = (select) => {
    setLoading(true)
    const role = parseInt(select.value)
    apiRequest(PATH_USER_UPDATE + user.id, () => {
      user.role = role
      success()
    }, err => error(err), { role }, 'put').finally(() => setLoading(false))
  }

  const success = () => M.toast({ html: t('user_modal.saved'), classes: 'rounded green large' })
  const error = (err) => M.toast({
    html: t('user_modal.save_error') + getErrorMessage(err),
    classes: 'rounded red large'
  })

  const verifyEmail = () => {
    setLoading(true)
    apiRequest(PATH_USER_UPDATE + user.id, () => {
      success()
    }, err => error(err), { status: 10 }, 'put').finally(() => setLoading(false))
  }

  const emailStatus = () => {
    let color
    let Icon
    let text
    let activateVisible = false
    let activateText = t('users_list.table.approve_email')

    switch (user.status) {
      case 0:
        text = 'event.edit.status.blocked'
        Icon = Block
        color = 'error'
        break
      case 9:
        text = 'users_list.filter.docs_status.waiting'
        Icon = PendingActions
        color = 'warning'
        activateVisible = true
        break
      case 10:
        text = 'users_list.filter.docs_status.verified'
        Icon = CheckCircleOutline
        color = 'success'
        break
      default:
        text = 'document.unknown_error'
        Icon = Warning
        color = 'warning'
    }

    return <Box>
      <Tooltip title={<Typography variant="h6">{t(text)}</Typography>}>
        <IconButton style={{ cursor: 'inherit' }}>
          <Icon size="large" color={color}/>
        </IconButton>
      </Tooltip>
      {activateVisible && <Tooltip title={<Typography variant="h6">{activateText}</Typography>}><IconButton size="large" color="primary" sx={{ marginLeft: '5px' }} onClick={verifyEmail}><Autorenew/></IconButton></Tooltip>}
    </Box>
  }

  const verificationButton = () => {
    let color
    let Icon
    let text
    switch (user.documents_status) {
      case 0:
        text = 'users_list.documents.pending'
        Icon = Pending
        color = 'warning'
        break
      case 1:
        text = 'users_list.documents.verified'
        Icon = Verified
        color = 'success'
        break
      case 2:
        text = 'users_list.documents.declined'
        Icon = Report
        color = 'error'
        break
      case 3:
        text = 'users_list.documents.not_verified'
        Icon = CommentsDisabled
        color = 'gray'
    }
    return <Tooltip title={<Typography variant="h6">{t(text)}</Typography>}>
      <IconButton style={{ cursor: user.documents_status === 0 ? 'pointer' : 'inherit' }} onClick={() => {
        if (user.documents_status !== 3) {
          openVerification(user)
        }
      }} size="large" color={color}>
        <Icon size="large"/>
      </IconButton>
    </Tooltip>
  }

  return <tr>
    {loading ? <PageLoader/> : null}
    <td className="center">{user.email}</td>
    <td className="center">
      <select id={'role-' + user.id} style={{ maxWidth: '100px', margin: 'auto' }} defaultValue={user.role}
              className="browser-default"
              onChange={e => {
                updateRole(e.target)
              }}>
        {Object.entries(roles).map(([k, v]) => <option key={k} value={k}>{t(`user.role.${v}`)}</option>)}
      </select>
    </td>
    <td className="center">
      {emailStatus()}
    </td>
    <td className="center">
      {verificationButton()}
    </td>
    <td className="center">{isObject(user.created_at) ? 'Not updated' : user.created_at}</td>
    <td className="center">{isObject(user.last_login) ? 'Not updated' : user.last_login}</td>
    <td className="center">
      <IconButton size="large" color="secondary" onClick={() => {
        open(user)
      }}>
        <Visibility/>
      </IconButton>
    </td>
    <td className="center">
      {typeof user.removal_requested === 'string' ? <Tooltip title={<Typography
        variant="h6">{t('users_list.removal_request_sent', { date: user.removal_requested })}</Typography>}>
        <IconButton sx={{ cursor: 'inherit' }} color="warning">
          <DeleteForever/>
        </IconButton>
      </Tooltip> : <IconButton onClick={() => remove(user)}><DeleteForever/></IconButton>
      }
    </td>
  </tr>
}
export default User
