import {AES, enc} from "crypto-js";
import worker from "../web-worker/decryptFileWorker";

function fromBase64(base64String: string): Uint8Array {
    return Uint8Array.from(atob(base64String), (c: string): number => c.charCodeAt(0));
}

function getPkcs8Der(pkcs8Pem: string): Uint8Array {
    pkcs8Pem = pkcs8Pem.replace(/[\r\n]+/gm, "");
    const pkcs8PemHeader = "-----BEGIN PRIVATE KEY-----";
    const pkcs8PemFooter = "-----END PRIVATE KEY-----";
    pkcs8Pem = pkcs8Pem.substring(pkcs8PemHeader.length, pkcs8Pem.length - pkcs8PemFooter.length);
    return fromBase64(pkcs8Pem);
}

async function importPrivateKey(pkcs8Pem: string): Promise<void | CryptoKey> {
    try {
        return await window.crypto.subtle.importKey(
            'pkcs8',
            getPkcs8Der(pkcs8Pem),
            {
                name: 'RSA-OAEP',
                hash: 'SHA-1', // Replace SHA-256 with SHA-1
            },
            true,
            ['decrypt']
        );
    } catch (e) {
        return console.log(e);
    }
}

async function decryptRSA(pkcs8Pem: string | CryptoKey, ciphertext: BufferSource): Promise<string> {
    const key: CryptoKey = typeof pkcs8Pem === 'string' ? (await importPrivateKey(pkcs8Pem))! : pkcs8Pem;
    let decrypted: ArrayBuffer = await window.crypto.subtle.decrypt(
        {
            name: 'RSA-OAEP'
        },
        key,
        ciphertext
    );
    const dec: TextDecoder = new TextDecoder();
    return dec.decode(decrypted);
}

class Pair<F, S> {
    constructor(public first: F, public second: S) {
    }

    public toArray(): [F, S] {
        return [this.first, this.second];
    }
}

class SymmetricKey extends Pair<CryptoJS.lib.WordArray, CryptoJS.lib.WordArray> {
    public key(): CryptoJS.lib.WordArray {
        return this.first;
    }

    public iv(): CryptoJS.lib.WordArray {
        return this.second;
    }

    public toBase64(): Pair<string, string> {
        return new Pair(enc.Base64.stringify(this.first), enc.Base64.stringify(this.second))
    }
}

async function decryptSymmetricKey(file: Blob, privateRSAKey: string | CryptoKey): Promise<SymmetricKey> {
    const buffer: ArrayBuffer = await file.arrayBuffer()

    const [key, iv]: [CryptoJS.lib.WordArray, CryptoJS.lib.WordArray] = (await decryptRSA(privateRSAKey, buffer))
        .split('|')
        .map((it: string): CryptoJS.lib.WordArray => enc.Base64.parse(it)) as [CryptoJS.lib.WordArray, CryptoJS.lib.WordArray];

    return new SymmetricKey(key, iv);
}

/**
 * @param file
 * @param key
 * @return base64 encoded file data
 */
async function decryptFile(file: Blob, key: SymmetricKey): Promise<string> {
    const dataAsText: string = await file.text()
    const decryptedData: CryptoJS.lib.WordArray = AES.decrypt(
        dataAsText,
        key.key(),
        {iv: key.iv()}
    )
    return enc.Base64.stringify(decryptedData)
}

/**
 * @return Map<filename, base64>
 */
async function decryptFiles(files: Map<string, Blob>, key: SymmetricKey, useWebWorker: boolean = false): Promise<Map<string, string>> {
    const decryptedFiles: Map<string, string> = new Map<string, string>()
    for (const [filename, data] of files.entries()) {
        let decryptedBase64: string
        if (useWebWorker) {
            decryptedBase64 = await webWorkerDecryptPromise(key, await data.text())
        } else {
            decryptedBase64 = await decryptFile(data, key)
        }
        decryptedFiles.set(filename, decryptedBase64)
    }
    return decryptedFiles
}

/**
 * @param key
 * @param data encrypted data as string
 * @return promise with decrypted data as base64 string
 */
function webWorkerDecryptPromise(key: SymmetricKey, data: string): Promise<string> {
    const code: Blob = new Blob(['(' + worker.toString() + ')()'])
    const objectURL: string = URL.createObjectURL(code)
    const webWorker: Worker = new Worker(objectURL)
    return new Promise((resolve: (val: string) => void, reject: (val: string) => void): void => {
        webWorker.onmessage = ({data}: MessageEvent<{ decryptedData: string }>): void => {
            resolve(data.decryptedData)
            webWorker.terminate()
        }
        webWorker.onerror = (error: ErrorEvent): void => {
            webWorker.terminate()
            console.log(error)
            reject(error.message)
        }
        webWorker.postMessage({key: key.toBase64(), data: data})
    })
}

export {
    importPrivateKey,
    decryptRSA,
    decryptSymmetricKey,
    Pair,
    SymmetricKey,
    decryptFile,
    decryptFiles
}
