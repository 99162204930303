import React, {ChangeEvent, ReactElement, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useCurrencyList} from "../../context/CurrencyListContext";
import TranslatableTextInput from "../../common/component/TranslatableTextInput";
import useML from "../../hook/UseML";
import PageLoader from "../../common/component/PageLoader";
import {backendAddress, PATH_MERCHANDISE_UPDATE_IMAGE} from "../../common/backend";
import M from "materialize-css";
import axios from "axios";

interface MerchandiseBodyProps {
    state: { current: { [k: string]: any } };
    handleChange: (name: string, val: any) => void;
    errRefs: { [k: string]: string };
    edit: boolean;
}

const Body = ({state, handleChange, errRefs, edit}: MerchandiseBodyProps) => {
    const {t} = useTranslation()
    const currencies = useCurrencyList();
    const [loading, setLoading] = useState<boolean>(false)

    const ml = useML();
    const inputRef = useRef<HTMLInputElement | null>(null)
    const imageRef = useRef<HTMLImageElement | null>(null)

    const uploadImage = (file: File): void => {
        const formData: FormData = new FormData()
        formData.append('image', file)
        setLoading(true)
        axios.post(backendAddress + PATH_MERCHANDISE_UPDATE_IMAGE + state.current.id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + localStorage.getItem('api-key')
            }
        }).then(res => {
            if (res.status === 200) {
                M.toast({html: t('event.edit.image_uploading.success'), classes: 'rounded green large'})
                // @ts-ignore
                imageRef.current!.src = res.data.url + '?' + new Date().getTime();
            } else {
                M.toast({html: t('event.edit.image_uploading.error'), classes: 'rounded red large'})
            }
        }).catch((e) => {
            console.log(e)
            M.toast({html: t('event.edit.image_uploading.error'), classes: 'rounded red large'})
        }).finally(() => setLoading(false))
    }
    const setImage = (e: ChangeEvent<HTMLInputElement>): void => {
        setLoading(true)

        const file: File = e.target.files![0]
        const img: HTMLImageElement = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = (): void => {
            const aspectRatio = img.width / img.height
            const isAspectRatioValid = Math.abs(aspectRatio - 1) < 0.01
            if (isAspectRatioValid) {
                uploadImage(file)
            } else {
                setLoading(false)
                M.toast({
                    html: t('event.edit.image.aspect_ratio_error', {ratio: '1/1'}),
                    classes: 'rounded red large'
                })
            }
            img.remove()
        }
    }

    if (ml.language == null || ml.availableLanguages == null) {
        return <PageLoader/>
    }

    return <div className="row">
        {loading && <PageLoader/>}
        {ml.Selector}
        <div className="input-field col s3"/>
        <div className="input-field col s2">
            <TranslatableTextInput initialValue={state.current['name']} classes='' name={t('merch.body.name')}
                                   update={(it: string): void => handleChange('name', it)} language={ml.language!}/>
            {/*<input required={true} defaultValue={state.current['name']}
                   onChange={handleChange.bind(this, 'name')} id="name"
                   name="name" type="text"/>
            <label className="active" htmlFor="name">{t('merch.body.name')}</label>
            <span ref={errRefs['name']} className="helper-text"
                  data-error={t('merch.body.name_error')}/>*/}
        </div>
        <div className="col s1">
            {currencies.map((currency: string): ReactElement => {
                return <div key={currency}>
                    <input required={true} defaultValue={state.current['price'][currency] ?? '0'}
                           onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                               state.current.price[currency] = parseInt(e.target.value);
                           }}
                           id={`price-${currency}`}
                           name={`price-${currency}`} type="number" min="0"/>
                    <label className="active" htmlFor={`price-${currency}`}>{currency}</label>
                    <span ref={errRefs['price']} className="helper-text"
                          data-error={t('merch.body.price_error')}/>
                </div>
            })}
        </div>
        <div className="input-field col s1">
            <input defaultValue={state.current['max_per_race']} name="max_per_race"
                   onChange={handleChange.bind(this, 'max_per_race')} id="max_per_race" type="number" min="1"/>
            <label className="active" htmlFor="max_per_race">{t('merch.body.max_per_race')}</label>
            <span ref={errRefs['max_per_race']} className="helper-text"
                  data-error={t('merch.body.max_per_race_error')}/>
        </div>
        <div hidden={!edit} className='col s2'>
            <img onClick={() => inputRef.current!.click()} ref={imageRef} style={{cursor: 'pointer'}}
                 src={!state.current.image ? '/no_image.png' : backendAddress + state.current.image}/>
        </div>
        <input hidden={true} ref={inputRef} onChange={(e) => setImage(e)} name="image" type="file"
               accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"/>
    </div>
}

export default Body;
