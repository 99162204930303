import Body from './Body'
import React, { useRef, useState } from 'react'
import { apiRequest, getErrorMessage, PATH_MERCHANDISE_CREATE } from '../../common/backend'
import $ from 'jquery'
import { useNavigate } from 'react-router'
import PageLoader from '../../common/component/PageLoader'
import { useTranslation } from 'react-i18next'
import M from 'materialize-css'

const Create = () => {
  const [isLoading, setLoading] = useState(false)
  const nav = useNavigate()
  const { t } = useTranslation()

  const errRefs = {
    'name': useRef(),
    'price': useRef(),
    'max_per_race': useRef()
  }

  const state = useRef({
    name: '',
    price: {},
    max_per_race: 1
  })

  const handleCreate = (e) => {
    e.preventDefault()

    setLoading(true)
    apiRequest(PATH_MERCHANDISE_CREATE, res => {
      $('#create-merch input').removeClass('valid').removeClass('invalid')
      setLoading(false)
      nav('/admin/merchandise/edit/' + res.data['id'])
    }, r => {
      for (const err of r.response.data) {
        $(errRefs[err['field']].current).attr('data-error', err['message'])
        $('#create-merch input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
      }
      M.toast({ html: getErrorMessage(r), classes: 'rounded red large' })
      setLoading(false)
    }, state.current, 'post')
  }

  const handleChange = (name, e) => {
    state.current[name] = typeof e === 'string' ? e : $(e.target).val()
  }

  const loading = () => {
    if (isLoading) {
      return <PageLoader/>
    } else {
      return ''
    }
  }

  return <div className="center">
    <h4>{t('merch.create.header')}</h4>
    <br/>
    {loading()}
    <div style={{
      maxWidth: '100%',
      width: '70%',
      margin: 'auto'
    }}>
      <form id="create-merch" onSubmit={handleCreate.bind(this)} action="#">
        <Body state={state} errRefs={errRefs} handleChange={handleChange}/>
        <div className="input-field">
          <button type="submit" className="btn btn-large btn-register waves-effect waves-light"
                  name="action">
            {t('merch.create.create_btn')}
            <i className="material-icons right">done</i>
          </button>
        </div>
      </form>
    </div>
  </div>
}
export default Create
