import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { clone, sortBy } from 'lodash'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

const GenderList = forwardRef(({ name, initial }, ref) => {
  const id = useRef(0)
  const { t } = useTranslation()

  const addIds = (list) => {
    list.forEach(it => it.id = ++id.current)
    return list
  }

  const [list, setList] = useState(addIds(initial))

  const remove = (id) => {
    const newList = list.filter(it => it.id !== id)
    setList(newList)
  }

  const add = () => {
    const newList = clone(list)
    newList.push({
      min: 0,
      max: 2,
      name: '',
      id: ++id.current
    })
    setList(newList)
  }

  useImperativeHandle(ref, () => {
    return {
      getData: list,
      setData: (date) => setList(addIds(date))
    }
  })

  return <>
    <ul className="collection with-header">
      <li className="collection-header"><h4>{name}</h4></li>
      {sortBy(list, it => it.min === 0 ? 10000 + it.id : it.min).map(it => {
        return <li key={`${name}-${it.id}`} className="collection-item">
          <div className="row">
            <div className="input-field col s3">
              <input min="1" max="200" type="number"
                     id={`min-${it.id}`}
                     onChange={(e) => it.min = parseInt($(e.target).val())}
                     defaultValue={it['min']}/>
              <label htmlFor={`min-${it.id}`} className="active">{t('race_age_group.minimum_age')}</label>
            </div>
            <div className="input-field col s3">
              <input min="1" max="200" type="number" id={`max-${it.id}`}
                     onChange={(e) => it.max = parseInt($(e.target).val())}
                     defaultValue={it['max']}/>
              <label htmlFor={`max-${it.id}`} className="active">{t('race_age_group.maximum_age')}</label>
            </div>
            <div className="input-field col s3">
              <input type="text" minLength="2" maxLength="255" id={`name-${it.id}`}
                     onChange={(e) => it.name = $(e.target).val()}
                     defaultValue={it['name']}/>
              <label htmlFor={`name-${it.id}`} className="active">{t('race_age_group.name')}</label>
            </div>
            <div className="input-field col s3">
              <button onClick={() => remove(it.id)} className="btn waves-effect waves-red red">
                {t('race_age_group.delete_btn')}
                <i className="material-icons right">delete</i>
              </button>
            </div>
          </div>
        </li>
      })}
    </ul>
    <br/>
    <a onClick={add} className="btn-floating btn-large green">
      <i className="material-icons">add</i>
    </a>
    <br/>
    <br/>
  </>
})
export default GenderList