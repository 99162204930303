import { useEffect } from 'react'
import M from 'materialize-css'
import { useTranslation } from 'react-i18next'
import { isObject } from 'lodash'

const fields = [
  'username',
  'email',
  'first_name',
  'second_name',
  'last_name',
  'birthday',
  'gender',
  'shirt_size',
  'club',
  'team',
  'sos',
  'city',
  'country',
  'about',
  'avatar',
  'np_area',
  'np_area_ref',
  'np_city',
  'np_city_ref',
  'np_warehouse',
  'np_warehouse_ref',
  'status',
  'role',
  'created_at',
  'updated_at',
  'last_login'
]

const UserModal = ({ user, setUser }) => {

  const { t } = useTranslation()

  useEffect(() => {
    if (user === null) {
      return
    }
    M.Modal.init(document.querySelector('#user-modal'), {
      onCloseEnd: () => setUser(null)
    }).open()
  }, [user])
  if (user === null) {
    return null
  }

  return <div id="user-modal" className="modal">
    <div className="modal-content">
      <h4>{user.first_name} {user.second_name} {user.last_name} #{user.id}</h4>
      <ul className="collection">
        {fields.map(it => {
          let value = user[it]
          if (isObject(value)) {
            value = ''
          }
          return <li key={it} className="collection-item">
            {t(`user.${it}`)}: {value}
          </li>
        })}
      </ul>
    </div>
    <div className="modal-footer">
      <a href="#!" onClick={e => {
        e.preventDefault()
        setUser(null)
      }} className="modal-close waves-effect waves-green btn-flat">{t('user_modal.close_btn')}</a>
    </div>
  </div>
}

export default UserModal
