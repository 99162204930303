import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {CommonInputProps} from "./DefaultInput";

const GenderSelect = ({change, value}: CommonInputProps) => {
    const {t} = useTranslation()
    return <>
        <label className="active">{t('gender')}</label>
        <select onChange={(e: ChangeEvent<HTMLSelectElement>): void => change('gender', e.target.value)}
                required={true} defaultValue={value} className="browser-default">
            <option value="-1" disabled>{t('gender')}</option>
            <option value="0">{t('gender.male')}</option>
            <option value="1">{t('gender.female')}</option>
            <option value="2">{t('gender.other')}</option>
        </select>
    </>
}
export default GenderSelect
