import {ReactElement, useState} from 'react'
import M from 'materialize-css'
import {apiRequest, getErrorMessage, PATH_USER_REQUEST_REMOVAL} from '../../common/backend'
import PageLoader from '../../common/component/PageLoader'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

interface DeleteUserModalProps {
    user: User | null,
    remove: (user: User) => void,
    close: () => void
}

const DeleteUserModal = ({user, remove, close}: DeleteUserModalProps): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false)
    const {t} = useTranslation()

    const removeUser = () => {
        setLoading(true)
        apiRequest(PATH_USER_REQUEST_REMOVAL + '?user=' + user!!.id, (res: {
            data: { date: string | null; message: any }
        }): void => {
            user!!.removal_requested = res.data.date
            remove(user!!)
            M.toast({html: res.data.message, classes: 'rounded green large'})
            // @ts-ignore
        }, err => {
            M.toast({
                html: t('user_remove_modal.error') + getErrorMessage(err),
                classes: 'rounded red large'
            })
        }, {}, 'get')!!
            .finally((): void => {
                setLoading(false)
            })
    }

    return <>
        {loading && <PageLoader zIndex={9000}/>}
        <Dialog open={user !== null} onClose={close}>
            <DialogTitle>
                {user?.first_name} {user?.last_name} #{user?.id}
            </DialogTitle>
            <DialogContent>
                <p>{t('user_remove_modal.header')}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    {t('user_remove_modal.no_btn')}
                </Button>
                <Button onClick={removeUser}>
                    {t('user_remove_modal.yes_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default DeleteUserModal
