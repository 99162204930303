import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { backendAddress, DEBUG } from './backend'

const availableLanguages = ['uk', 'en']

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    backend: {
      loadPath: backendAddress + 'site/translation?lang={{lng}}'
    },
    fallbackLng: {
      ru: ['uk'],
      'ru-RU': ['uk'],
      default: ['en']
    },
    detection: {
      checkWhitelist: true // options for language detection
    },
    debug: DEBUG,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false // no need for react. it escapes by default
    },
  })
