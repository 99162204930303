import React, { useEffect, useState } from 'react'
import PageLoader from '../../../common/component/PageLoader'
import { apiRequest, PATH_RACE_UPDATE, PATH_RACE_VIEW } from '../../../common/backend'
import M from 'materialize-css'
import FixedSaveButton from '../../../common/component/FixedSaveButton'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { useCurrencyList } from '../../../context/CurrencyListContext'

const TYPE_BY_DATE = 0
const TYPE_BY_SLOTS = 1

const PricePage = (props) => {
  const raceId = props.raceId
  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()

  const [price, setPrice] = useState(null)
  const [type, setType] = useState(-1)

  const currencies = useCurrencyList()

  useEffect(() => {
    if (price === null) {
      apiRequest(PATH_RACE_VIEW + raceId, res => {
        setPrice(JSON.parse(res.data['price']))
        setType(parseInt(res.data.price_type))
        setLoading(false)
      }, () => {}, undefined, 'get')
    }
    M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {})

    console.log(t('month.january'))
    $('.datepicker').each((_, it) => {
      M.Datepicker.init($(it), {
        format: 'yyyy-mm-dd',
        defaultDate: new Date(price[$(it).attr('price')]),
        setDefaultDate: true,
        minDate: new Date(),
        i18n: {
          months: [t('month.january'), t('month.february'), t('month.march'), t('month.april'), t('month.may'), t('month.june'), t('month.july'), t('month.august'), t('month.september'), t('month.october'), t('month.november'), t('month.december')],
          monthsShort: [t('month.january.short'), t('month.february.short'), t('month.march.short'), t('month.april.short'), t('month.may.short'), t('month.june.short'), t('month.july.short'), t('month.august.short'), t('month.september.short'), t('month.october.short'), t('month.november.short'), t('month.december.short')],
          weekdays: [t('weekday.sunday'), t('weekday.monday'), t('weekday.tuesday'), t('weekday.wednesday'), t('weekday.thursday'), t('weekday.friday'), t('weekday.saturday')],
          weekdaysShort: [t('weekday.sunday.short'), t('weekday.monday.short'), t('weekday.tuesday.short'), t('weekday.wednesday.short'), t('weekday.thursday.short'), t('weekday.friday.short'), t('weekday.saturday.short')],
          weekdaysAbbrev: [t('weekday.sunday.abbrev'), t('weekday.monday.abbrev'), t('weekday.tuesday.abbrev'), t('weekday.wednesday.abbrev'), t('weekday.thursday.abbrev'), t('weekday.friday.abbrev'), t('weekday.saturday.abbrev')],
          cancel: t('cancel')
        }
      })
      $(it).change(() => price[$(it).attr('price')][$(it).attr('name')] = $(it).val())
    })
  })

  const loading = () => {
    if (isLoading) {
      return <PageLoader/>
    }
    return ''
  }

  const savePrice = () => {
    setLoading(true)
    apiRequest(PATH_RACE_UPDATE + raceId, () => {
      setLoading(false)
      M.toast({ html: t('race_price.save_success'), classes: 'rounded green large' })
    }, err => {
      setLoading(false)
      M.toast({ html: t('race_price.save_fail') + err.response.data[0].message, classes: 'rounded red large' })
    }, { 'price': JSON.stringify(price), 'price_type': type }, 'put')
  }

  if (price === null) {
    return <PageLoader/>
  }

  return <div style={{
    maxWidth: '100%',
    width: '70%',
    margin: 'auto'
  }}>
    {loading()}
    <FixedSaveButton action={savePrice}/>
    <ul className="collection with-header">
      <li className="collection-header">
        <h4>{t('race_price.header')}</h4>
        <div className="row">
          <div className="input-field col s3 right">
            <label className="active">{t('race_price.price_calculation_type')}</label>
            <select className="browser-default" value={type} onChange={e => setType(parseInt(e.target.value))}>
              <option disabled value={-1}>{t('race_price.price_calculation_type.unknown')}</option>
              <option value={TYPE_BY_DATE}>{t('race_price.price_calculation_type.by_date')}</option>
              <option value={TYPE_BY_SLOTS}>{t('race_price.price_calculation_type.by_slots')}</option>
            </select>
          </div>
        </div>
      </li>
      {price.map((it, i) => {
        return <li key={'price-' + i} className="collection-item">
          <div className="row">
            <div className="col s4">
              <div className="col s2">
                <span>{t('race_price.price')}</span>
              </div>
              {currencies.map((currency) => {
                return <div key={currency} className="input-field col s3">
                  <input min="0" max="9999999" type="number"
                         onChange={e => price[i]['price'][currency] = parseInt($(e.target).val())} id={'price-for-' + i}
                         defaultValue={it['price'][currency]}/>
                  <label htmlFor={'price-for-' + currency} className="active">{currency}</label>
                </div>
              })}
            </div>
            <div className="input-field col s3" hidden={type !== TYPE_BY_DATE}>
              <input minLength="3" maxLength="255" price={i}
                     className="validate" required="required"
                     type="date"
                     id="date_start" name="date_start"
                     onChange={e => price[i]['date_start'] = e.target.value}
                     defaultValue={it['date_start']}/>
              <label htmlFor="date_start" className="active">{t('race_price.date_from')}</label>
            </div>
            <div className="input-field col s3" hidden={type !== TYPE_BY_DATE}>
              <input minLength="3" maxLength="255" price={i}
                     className="validate" required="required"
                     id="date_end" name="date_end"
                     type="date"
                     onChange={e => price[i]['date_end'] = e.target.value}
                     defaultValue={it['date_end']}/>
              <label htmlFor="date_end" className="active">{t('race_price.date_to')}</label>
            </div>
            <div className="input-field col s2" hidden={type !== TYPE_BY_SLOTS}>
              <input onChange={e => price[i]['members_max'] = parseInt(e.target.value)} type="number" min="0"
                     max="99999999"
                     className="validate" required="required"
                     id="members_max" name="members_max"
                     defaultValue={it['members_max']}/>
              <label htmlFor="members_max" className="active">{t('race_price.max_participants')}</label>
            </div>
            <div className="input-field col s2">
              <button onClick={() => {
                const list = JSON.parse(JSON.stringify(price))
                list.splice(i, 1)
                setPrice(list)
              }} className="btn waves-effect waves-red red">
                {t('race_price.delete_btn')}
                <i className="material-icons right">delete</i>
              </button>
            </div>
          </div>
        </li>
      })}
    </ul>
    <br/>
    <span className="center">
    <a style={{ textAlign: 'center' }} onClick={() => {
      const list = JSON.parse(JSON.stringify(price))
      list.push({
        price: Object.fromEntries(currencies.map((it) => [it, 0])),
        date_start: '',
        date_end: '',
        members_max: 0
      })
      console.log(list)
      setPrice(list)
    }} className="btn-floating btn-large green"><i
      className="material-icons">add</i></a>
      </span>
    <br/>
  </div>
}

export default PricePage
