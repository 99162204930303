import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {apiRequest, PATH_GET_CURRENCIES} from '../common/backend'

const CurrencyListContext = React.createContext<string[] | null>(null)

const useCurrencyList = (): string[] => {
    const context = useContext(CurrencyListContext)
    if (!context) throw new Error('useCurrencyList must be used inside CurrencyListContext')
    return context
}

interface CurrencyListContextProviderProps {
    children: ReactElement | ReactElement[]
}

const CurrencyListContextProvider = ({children}: CurrencyListContextProviderProps): ReactElement => {
    const [list, setList] = useState<string[]>([])

    useEffect((): void => {
        apiRequest(PATH_GET_CURRENCIES, (res: { data: string[] }) => setList(res.data))
    }, [])

    return <CurrencyListContext.Provider children={children} value={list}/>
}
export {useCurrencyList}
export default CurrencyListContextProvider
