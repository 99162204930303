import React, { useRef, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'
import { backendAddress, getErrorMessage } from '../common/backend'
import { useAuth } from '../context/AuthContext'
import M from 'materialize-css'
import i18n from 'i18next'
import PageLoader from '../common/component/PageLoader'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

const Login = () => {
  const login = useRef()
  const password = useRef()
  const loginError = useRef()

  const { t } = useTranslation()

  const { authorized } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleLogin = (e) => {
    e.preventDefault()
    const data = new FormData
    data.set('username', login.current.value)
    data.set('password', password.current.value)
    setLoading(true)
    axios({
      method: 'post',
      url: backendAddress + 'user/login',
      data: data,
      headers: { 'Accept-Language': i18n.language }
    })
      .then(function (response) {
        if (response.data['errors']) {
          $(login.current).removeClass('valid').addClass('invalid')
          $(loginError.current).attr('data-error', response.data['errors'][Object.keys(response.data['errors'])[0]])
        } else {
          localStorage.setItem('api-key', response.data['token'])
          window.location.reload()
        }
      })
      .catch(function (error) {
        M.toast({ html: getErrorMessage(error), classes: 'rounded red large' })
        console.log(error)
      }).finally(() => {
      setLoading(false)
    })
  }

  return <>
    {loading && <PageLoader zIndex={9000}/>}
    <Dialog open={authorized !== true} fullWidth={true}>
      <DialogTitle>
        {t('login_modal.header')}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <form onSubmit={handleLogin.bind(this)} action="#" style={{ maxWidth: '450px' }}>
          <div className="input-field">
            <i className="material-icons prefix">person</i>
            <input className="validate" ref={login} required="required" minLength="3" maxLength="255"
                   type="text" id="name"/>
            <label htmlFor="name">{t('login_modal.username', {}, 'messages')}</label>
            <span ref={loginError} className="helper-text" data-error={t('login_modal.username_error')}/>
          </div>
          <br/>
          <div className="input-field">
            <i className="material-icons prefix">lock</i>
            <input ref={password} required="required"
                   minLength="8"
                   maxLength="255" type="password" id="password"/>
            <label htmlFor="password">{t('login_modal.password', {}, 'messages')}</label>
            <span className="helper-text" data-error={t('login_modal.password_error')}/>
          </div>
          <br/>
          <button type="submit" className="btn btn-large btn-register waves-effect waves-light"
                  name="action">{t('login_modal.login_btn')}<i className="material-icons right">done</i>
          </button>
        </form>
      </DialogContent>
    </Dialog>
  </>
}
export default Login
