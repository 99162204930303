import React, {
    FormEvent,
    HTMLInputTypeAttribute,
    ReactElement,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react'
import PageLoader from '../../../common/component/PageLoader'
import M from 'materialize-css'
import {chunk, clone, findIndex} from 'lodash'
import GenderSelect from './input/GenderSelect'
import DefaultInput, {CommonInputProps} from './input/DefaultInput'
import RaceInput from './input/RaceInput'
import {
    apiRequest,
    getErrorMessage,
    PATH_EVENT_VIEW,
    PATH_PARTICIPANT_ID,
    PATH_PARTICIPANT_INDEX
} from '../../../common/backend'
import EmailInput from './input/EmailInput'
import {getAge} from '../../../common/commons'
import ImmutableInput from './input/ImmutableInput'
import ShirtSizeInput from './input/ShirtSizeInput'
import {useAuth} from '../../../context/AuthContext'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {Add, Save} from '@mui/icons-material'
import $ from 'jquery'
import ML from "../../../common/multilang";
import i18n from "i18next";
import NovaposhtaSelect from "../../../common/component/NovaposhtaSelect";

type ParticipantProperty = keyof Participant;

const fields: ParticipantProperty[] = [
    'chip_number',
    'start_number',
    'planned_time',
    'first_name',
    'last_name',
    'first_name_latin',
    'last_name_latin',
    'club',
    'city',
    'shirt_size',
    'phone',
    'email',
    'birthday',
    'gender',
    'sos_contact',
    'race',
    // 'merchandise'
]

const notRequired: ParticipantProperty[] = [
    'chip_number',
    'planned_time',
    'club'
]

const optional: string[] = [
    'shirt_size',
    'first_name_latin',
    'last_name_latin',
    'np_area_ref',
    'np_city_ref',
    'np_warehouse_ref',
]

const customType: { [k: string]: HTMLInputTypeAttribute } = {
    'chip_number': 'number',
    'birthday': 'date',
    'phone': 'tel'
}

const customInput: { [k: string]: (props: CommonInputProps) => ReactElement } = {
    'gender': GenderSelect,
    'race': RaceInput,
    'email': EmailInput,
    // 'merchandise': MerchandiseInput,
    'start_number': ImmutableInput,
    'shirt_size': ShirtSizeInput
}

interface InputProps {
    id: string;
    desc: string;
    change: (name: string, value: string | number | Race) => void;
    races: RaceList;
    value: string | number;
    immutable: boolean;
    race: Race | null;
    required: boolean;
}

const Input = (props: InputProps): ReactElement => {
    const {id, desc, change, races, race, value, immutable, required} = props

    if (id === 'merchandise') {
        return <></>
    }

    let Input = customInput[id] ?? DefaultInput
    return <Input id={id} desc={desc} change={change} immutable={immutable} races={races}
                  required={required} value={value} type={customType[id] ?? 'text'}/>
}

const defaultState = {
    payed: true,
    price_final: 0,
    promocode: null,
    age_category: 'Не найдена',
    gender: 0,
    first_name: '',
    last_name: '',
    email: '',
    start_number: '',
    chip_number: 0,
    race_id: 0,
    planned_time: '',
    city: '',
    phone: '',
    club: '',
    birthday: '',
    age: -1,
    shirt_size: '',
    sos_contact: '',
    merchandise: ''
}

interface ParticipantModalProps {
    open: boolean;
    initial: Participant;
    close: () => void;
    edit?: boolean;
    afterCreate?: (participant: Participant) => void;
}

type RaceList = { [k: number]: Race }

const ParticipantModal = (props: ParticipantModalProps) => {
    const {
        open,
        initial,
        close,
        edit = false,
        afterCreate = (): void => {
        }
    } = props
    const [loading, setLoading] = useState<boolean>(false)

    const {t} = useTranslation()

    const [races, setRaces] = useState<RaceList | null>(null)
    const [race, setRace] = useState<Race | null>(null)

    const state = useRef<Participant>(clone(initial))
    const [_, setUpdate] = useState<Object>({})

    const {user} = useAuth()

    const submitRef = useRef<HTMLInputElement | null>(null)

    // reset state on close
    useEffect((): void => {
        if (loading) {
            return
        }
        if (!open) {
            close()
            state.current = clone(defaultState) as Participant
        }
    }, [open, loading])
    // load new state
    useLayoutEffect((): void => {
        state.current = clone(initial || defaultState)
        if (!state.current.race_id) {
            state.current.race_id = 0
        }
        if (!edit) {
            Object.assign(state.current, defaultState, initial)
        }
        setUpdate({})
    }, [initial])

    // load races for selection or current race
    useEffect((): void => {
        if (!user || !initial || !initial.event_id) {
            return
        }

        const params: Object = {
            showRaces: 1,
            loadMerch: 1,
            showCustomFields: 1,
        }

        apiRequest(PATH_EVENT_VIEW + initial.event_id + '?' + $.param(params), (res: {
            data: RaceEvent
        }): void => {
            const races: { [k: number]: Race } = {}

            for (const race of (res.data as RaceEvent).races!) {
                // Assign event name to race
                race.event_name = res.data.name
                // Decode years range
                race.years_range = JSON.parse(race.years_range as string)
                // if participant already assigned to race - preserve only one selected
                // if not (creating new participant) - load only races with date_start in future to which user have access
                if (race.id === initial.race_id || (!initial.race_id && race.future && (user.isAdmin || res.data.created_by === user.id))) {
                    races[race.id] = race
                }
            }

            setRaces(races)
            if (edit) {
                setRace(races[initial.race_id])
            }
            // @ts-ignore
        }, (e): void => {
            console.log(e)
        }, undefined, 'GET')!!
            .finally((): void => {
                setLoading(false)
            })
    }, [user, initial])

    const onChange = (id: string, val: string | number | Race): void => {
        switch (id) {
            case 'birthday':
            case 'race_id':
            case 'gender':
                // Set age category, so all 3 values required
                if (id === 'birthday') {
                    state.current['birthday'] = val as string
                    state.current['age'] = getAge(val as string)
                }
                if (id === 'gender') {
                    state.current['gender'] = val as number
                }

                let currentRace: Race | null = race
                if (id === 'race_id') {
                    setRace(val as Race)
                    state.current['event_id'] = (val as Race).event
                    currentRace = race
                    val = (val as Race).id
                }
                if (currentRace && state.current['gender']) {
                    const ageCategory: RaceAgeCategory[] = (currentRace.years_range as {
                        [k: string]: RaceAgeCategory[]
                    })[state.current['gender']]

                    const age: number = state.current['age'] = getAge(val as string)

                    const index: number = findIndex(ageCategory, (it: RaceAgeCategory): boolean => {
                        return age >= it.min && age <= it.max
                    })

                    if (index !== -1) {
                        state.current['age_category'] = ageCategory[index].name
                    }
                }
                break
        }
        setUpdate({})
        // @ts-ignore
        state.current[id] = val
    }

    const saveParticipant = (): void => {
        setLoading(true)
        let path
        if (edit) {
            path = PATH_PARTICIPANT_ID + state.current['id']
        } else {
            path = PATH_PARTICIPANT_INDEX
        }
        apiRequest(path, (res: { data: Participant }): void => {
            let text
            if (edit) {
                text = t('participant_body.saved')
            } else {
                text = t('participant_body.created')
            }
            M.toast({html: text, classes: 'rounded green large'})

            if (!edit) {
                close()
                afterCreate(res.data)
                state.current = {} as Participant
                setRaces(null)
                setRace(null)
                setLoading(false)
            }
            // @ts-ignore
        }, (res): void => {
            M.toast({html: getErrorMessage(res), classes: 'rounded red large'})
        }, state.current, edit ? 'PUT' : 'POST')!!
            .finally((): void => {
                setLoading(false)
            })
    }

    return <>
        {(loading || (races === null && initial !== null)) && open && <PageLoader zIndex={9000}/>}
        <Dialog open={open} onClose={close} fullWidth={true}>
            <DialogTitle>
                {edit ? t('participant_body.editing_header') : t('participant_body.creating_header')}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e: FormEvent<HTMLFormElement>): void => {
                    e.preventDefault()
                    saveParticipant()
                }} action="#" autoComplete="none">
                    <input autoComplete="none" name="hidden" type="text" style={{display: 'none'}}/>
                    {chunk(fields, 2).map((coll: ParticipantProperty[]): ReactElement => {
                        return <div key={coll.toString()} className="row">
                            {coll.map((id: ParticipantProperty): ReactElement => {
                                if (id === 'start_number' && !edit) {
                                    return <div key={id} className="input-field col s6"/>
                                }
                                let value = (state.current || {})[id]
                                if (id === 'race') {
                                    value = state.current?.race_id ?? ''
                                }
                                let required: boolean = !notRequired.includes(id)
                                if (optional.includes(id)) {
                                    required = race?.required_fields.includes(id) ?? false
                                }
                                return <div key={id} className="input-field col s6">
                                    <Input value={value as string | number} id={id}
                                           desc={t(`participant_body.${id}`)} race={race}
                                           races={races || {}} immutable={edit} required={required}
                                           change={onChange}/>
                                </div>
                            })}
                        </div>
                    })}
                    <NovaposhtaSelect required={race?.required_fields.includes('np') ?? false}
                                      areaDefault={state.current?.np_area_ref}
                                      areaOnChange={(e: any): void => {
                                          state.current.np_area_ref = e.target.value
                                      }}
                                      cityDefault={state.current?.np_city_ref}
                                      cityOnChange={(e: any): void => {
                                          state.current.np_city_ref = e.target.value
                                      }}
                                      warehouseDefault={state.current?.np_warehouse_ref}
                                      warehouseOnChange={(e: any): void => {
                                          state.current.np_warehouse_ref = e.target.value
                                      }}
                    />
                    {chunk(race?.custom_fields!, 3).map((fields: RaceCustomField[], i: number): JSX.Element | null => {
                        if (!state.current.custom_fields) {
                            return null
                        }
                        return <div key={`custom_${i}`} className="row">
                            {fields.map((field: RaceCustomField): JSX.Element => {
                                return <div key={field.id} className="input-field col s4">
                                    <Input value={state.current.custom_fields[field.id.toString()]}
                                           id={`custom_${field.id}`}
                                           desc={ML.getStringForLanguage({
                                               multilangString: field.name,
                                               language: i18n.language
                                           }) ?? ''} race={race}
                                           races={races || {}} immutable={edit}
                                           required={race!.required_fields.includes(field.id)}
                                           change={(_: string, val: string | number | Race): void => {
                                               state.current.custom_fields[field.id.toString()] = val as string
                                           }}/>
                                </div>
                            })}
                        </div>
                    })}
                    <input ref={submitRef} type="submit" hidden={true} style={{display: 'none'}}
                           id="submit-participant"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    {t('participant_body.close_btn')}
                </Button>
                <Button onClick={(): void => submitRef.current!.click()} startIcon={edit ? <Save/> : <Add/>}>
                    {edit ? t('participant_body.save_btn') : t('participant_body.create_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default ParticipantModal;
