import { useEffect, useState } from 'react'
import {
  apiRequest,
  PATH_MERCHANDISE_INDEX,
  PATH_RACE_GET_MERCHANDISE,
  PATH_RACE_UPDATE
} from '../../../common/backend'
import PageLoader from '../../../common/component/PageLoader'
import FixedSaveButton from '../../../common/component/FixedSaveButton'
import M from 'materialize-css'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import ML from '../../../common/multilang'

const MerchandisePage = (props) => {

  const raceId = props.raceId
  const [isLoading, setLoading] = useState(true)
  const [merch, setMerch] = useState(null)
  const [allMerch, setAllMerch] = useState(null)
  const [available, setAvailable] = useState(null)
  const { t } = useTranslation()

  const updateAvailableMerch = (usedMerch) => {
    setAvailable(allMerch.filter(it => {
      return usedMerch.find(s => JSON.stringify(it) === JSON.stringify(s)) === undefined
    }))
  }

  useEffect(() => {
    if (merch === null) {
      apiRequest(PATH_RACE_GET_MERCHANDISE + raceId, res => {
        setMerch(res.data)
        setLoading(false)
      })
    }
    if (allMerch === null) {
      apiRequest(PATH_MERCHANDISE_INDEX, res => {
        setAllMerch(res.data)
      }, undefined, undefined, 'GET')
    }
    if (allMerch !== null && merch !== null && available === null) {
      updateAvailableMerch(merch)
    }
  })

  const lng = useTranslation().i18n.language

  if (isLoading || merch === null || allMerch === null || available === null) {
    return <PageLoader/>
  }

  const save = () => {
    setLoading(true)
    apiRequest(PATH_RACE_UPDATE + raceId, res => {
      setLoading(false)
      M.toast({ html: t('race_merch.saving_success'), classes: 'rounded green large' })
    }, err => {
      setLoading(false)
      M.toast({ html: t('race_merch.saving_error') + err.response.data[0].message, classes: 'rounded red large' })
    }, {
      available_merchandise: JSON.stringify(merch.map(it => parseInt(it['id'])))
    }, 'put')
  }

  return <div style={{
    maxWidth: '100%',
    width: '50%',
    margin: 'auto'
  }}>
    <FixedSaveButton action={save}/>
    <h4 className="center">{t('race_merch.header')}</h4>
    <br/>
    <ul className="collection">
      {merch.map((it, i) => <li className="collection-item"
                                key={it['id']}>
        {ML.getStringForLanguage({ multilangString: it['name'], language: lng })}
        <i onClick={() => {
          const list = JSON.parse(JSON.stringify(merch))
          list.splice(i, 1)
          setMerch(list)
          updateAvailableMerch(list)
        }} className="material-icons secondary-content" style={{ cursor: 'pointer' }}>delete</i></li>)
      }
    </ul>

    <h5>{t('race_merch.add_btn')}</h5>
    <br/>
    <div className="row">
      <div className="col s2"/>
      <div className="col s5">
        <select name="add-merch" className="browser-default" defaultValue="-1">
          <option value="-1"/>
          {available.map(it => <option key={it['id']} value={it['id']}>{ML.getStringForLanguage({ multilangString:it['name'],language:lng })}</option>)}
        </select>
      </div>
      <div className="col s3">
        <a onClick={() => {
          let add = $('select[name="add-merch"]').val()
          if (available.length === 0 || add === '-1') {
            M.toast({ html: 'Выберите мерч', classes: 'rounded red large' })
            return
          }
          const list = JSON.parse(JSON.stringify(merch))
          list.push(allMerch.find(it => it['id'] === parseInt(add)))
          setMerch(list)
          updateAvailableMerch(list)
        }} className="btn-floating btn-large green"><i
          className="material-icons">add</i>
        </a>
      </div>
    </div>
    <br/>
  </div>
}

export default MerchandisePage
