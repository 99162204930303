import Body from '../Body'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PageLoader from '../../../common/component/PageLoader'
import useML from '../../../hook/UseML'

const EditPage = (props) => {

  const { handleChange, handleUpdate, state, errRefs } = props
  const { t } = useTranslation()

  const { availableLanguages, setLanguage, language } = useML()

  if (availableLanguages === null) {
    return <PageLoader/>
  }

  return <form id="race-body" onSubmit={e => handleUpdate(e)} action="#">
    <span>
      <select className="browser-default" value={language} onChange={e => setLanguage(e.target.value)}>
        {availableLanguages.map(it => <option key={it} value={it}>{it}</option>)}
      </select>
    </span>
    <Body edit={true} language={language} state={state} errRefs={errRefs} handleChange={handleChange}/>
    <br/>
    <div className="input-field">
      <button type="submit" className="btn btn-large btn-register waves-effect waves-light" name="action">
        {t('race_edit.save_btn')}
        <i className="material-icons right">done</i>
      </button>
    </div>
  </form>
}
export default EditPage
