interface StatusLoaderProps {
    zIndex?: number
}

const defaultZIndex: number = 2

const StatusLoader = ({zIndex = defaultZIndex}: StatusLoaderProps) => {
    return <div className="status-loader" style={{zIndex}}>
        <div className="preloader-wrapper small active">
            <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                    <div className="circle"/>
                </div>
                <div className="gap-patch">
                    <div className="circle"/>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"/>
                </div>
            </div>
        </div>
    </div>
}

export default StatusLoader