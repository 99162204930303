import React, { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { apiRequest, getErrorMessage, PATH_MERCHANDISE_UPDATE, PATH_MERCHANDISE_VIEW } from '../../common/backend'
import PageLoader from '../../common/component/PageLoader'
import Body from './Body'
import $ from 'jquery'
import M from 'materialize-css'
import { useTranslation } from 'react-i18next'
import { isString } from 'lodash'

const Edit = () => {

  const id = useParams().id
  const [isLoading, setLoading] = useState(true)

  const errRefs = {
    'name': useRef(),
    'price': useRef(),
    'max_per_race': useRef(),
    'icon': useRef(),
  }

  const { t } = useTranslation()
  const state = useRef(null)

  const handleChange = (name, e, obj = false) => {
    state.current[name] = isString(e) || obj ? e : $(e.target).val()
  }

  useState(() => {
    if (state.current === null) {
      apiRequest(PATH_MERCHANDISE_VIEW + id, res => {
        state.current = res.data
        setLoading(false)
      }, undefined, undefined, 'GET')
    }
  })

  const handleUpdate = (e) => {
    e.preventDefault()

    setLoading(true)
    apiRequest(PATH_MERCHANDISE_UPDATE + id, () => {
      $('#update-merch input').removeClass('valid').removeClass('invalid')
      setLoading(false)
      M.toast({ html: t('merch.edit.saving_success'), classes: 'rounded green large' })
    }, r => {
      setLoading(false)
      for (const err of r.response.data) {
        console.log(err['field'] + ' - ' + err['message'])
        $(errRefs[err['field']].current).attr('data-error', err['message'])
        $('#update-merch input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
      }
      M.toast({ html: t('merch.edit.saving_fail') + ': ' + getErrorMessage(r), classes: 'rounded red large' })
    }, state.current, 'put')
  }

  if (isLoading) {
    return <PageLoader/>
  }

  return <div className="center">
    <h4>{t('merch.edit.header', { id: id })}</h4>
    <br/>
    <div style={{
      maxWidth: '100%',
      width: '70%',
      margin: 'auto'
    }}>
      <form id="update-merch" onSubmit={handleUpdate.bind(this)}>
        <Body edit={true} handleChange={handleChange} state={state} errRefs={errRefs}/>
        <div className="input-field">
          <button type="submit" className="btn btn-large btn-register waves-effect waves-light" name="action">
            {t('merch.edit.save_btn')}
            <i className="material-icons right">done</i>
          </button>
        </div>
      </form>
    </div>
  </div>
}
export default Edit
