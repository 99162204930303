import React, { useEffect, useRef, useState } from 'react'
import NovaposhtaSelect from '../../common/component/NovaposhtaSelect'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { range } from 'lodash/util'
import M from 'materialize-css'
import { apiRequest, getErrorMessage, PATH_GET_LANGUAGE_LIST, PATH_USER_CREATE } from '../../common/backend'
import { clone, isString } from 'lodash'
import { getCountries } from '../../common/commons'
import PageLoader from '../../common/component/PageLoader'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Add } from '@mui/icons-material'

const defaultState = {
  'shirtSize': 'XS',
  'country': '-1',
  'gender': '0',
  'day': '1',
  'month': '1',
  'year': '1940',
  'language': 'en'
}

const CreateUserModal = ({ open, close, add }) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const errRefs = {
    'username': useRef(),
    'email': useRef(),
    'phone': useRef(),
    'first_name': useRef(),
    'secondName': useRef(),
    'last_name': useRef(),
    'country': useRef(),
    'city': useRef(),
    'club': useRef(),
    'team': useRef(),
    'sos': useRef(),
    'password': useRef(),
    'passwordRepeat': useRef(),
    'language': useRef()
  }
  //Нет смысла заново рендерить компонент заново при изменении инпутов, поэтому не useState
  //Так же вместо создания десятка useRef храним данные здесь
  const state = useRef(clone(defaultState))

  const submitRef = useRef()

  const createUser = () => {
    setLoading(true)
    const data = clone(state.current)
    data['birthday'] = data['year'] + '-' + data['month'] + '-' + data['day']
    apiRequest(PATH_USER_CREATE, res => {
      close()
      M.toast({ html: t('user_creation_modal.success'), classes: 'rounded green large' })
      add(res.data)
    }, err => {
      M.toast({ html: t('user_creation_modal.error') + ': ' + getErrorMessage(err), classes: 'rounded red large' })
    }, data, 'post')
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!open) {
      state.current = clone(defaultState)
    }
  }, [open])

  const handleChange = (name, e) => {
    state.current[name] = isString(e) ? e : $(e.target).val()
  }

  const setNpArea = (e) => {
    state.current['npArea'] = $(e.target).find('option:selected').text()
    state.current['npAreaRef'] = $(e.target).find('option:selected').val()
  }
  const setNpCity = (e) => {
    state.current['npCity'] = $(e.target).find('option:selected').text()
    state.current['npCityRef'] = $(e.target).find('option:selected').val()
  }

  const [list, setList] = useState(null)
  const [lng, setLng] = useState('en')

  useEffect(() => {
    apiRequest(PATH_GET_LANGUAGE_LIST, (res) => {
      setList(res.data)
    })
  }, [])

  if (!open) {
    return null
  }

  return <>
    {loading && <PageLoader zIndex={9000}/>}
    <Dialog open={open} onClose={close} fullScreen={true}>
      <DialogTitle>
        {t('creating_new_user.header')}
      </DialogTitle>
      <DialogContent>
        <form id="update-profile" onSubmit={e => {
          e.preventDefault()
          createUser()
        }} action="#">
          <div className="row">
            <div className="input-field col s4">
              <i className="material-icons prefix">person</i>
              <input onChange={handleChange.bind(this, 'username')} minLength="3" maxLength="255"
                     className="validate" required="required" type="text"
                     id="r-login" name="username" value={state.current['username']}/>
              <label htmlFor="r-login" className="active">{t('user_creation_modal.login')}</label>
              <span ref={errRefs['username']} className="helper-text"
                    data-error={t('user_creation_modal.login_error')}/>
            </div>
            <div className="input-field col s3">
              <i className="material-icons prefix">email</i>
              <input name="email" className="validate" required="required"
                     defaultValue={state.current['email']}
                     onChange={handleChange.bind(this, 'email')}
                     type="email"
                     id="email"/>
              <label htmlFor="email" className="active">EMail</label>
              <span ref={errRefs['email']} className="helper-text"
                    data-error={t('user_creation_modal.invalid_email')}/>
            </div>
            <div className="input-field col s3">
              <i className="material-icons prefix">phone</i>
              <input name="phone" className="validate" required="required"
                     defaultValue={state.current['phone']}
                     onChange={handleChange.bind(this, 'phone')}
                     type="text"
                     id="phone"/>
              <label htmlFor="phone" className="active">{t('user_creation_modal.phone')}</label>
              <span ref={errRefs['phone']} className="helper-text"
                    data-error={t('user_creation_modal.phone_error')}/>
            </div>
            <div className="input-field col s2">
              <select className="browser-default" value={lng}
                      onChange={e => {
                        setLng(e.target.value)
                        state.current.language = e.target.value
                      }}>
                {Object.entries(list || {}).map(([code, name]) => {
                  return <option key={code} value={code}>{name}</option>
                })}
              </select>
              {/*<input name="phone" className="validate" required="required"
                     defaultValue={state.current['phone']}
                     onChange={handleChange.bind(this, 'phone')}
                     type="text"
                     id="phone"/>
              <label htmlFor="phone" className="active">{t('user_creation_modal.phone')}</label>
              <span ref={errRefs['phone']} className="helper-text"
                    data-error={t('user_creation_modal.phone_error')}/>*/}
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s4">
              <select defaultValue={state.current['year']} id="year" className="browser-default"
                      name="year"
                      required="required"
                      onChange={handleChange.bind(this, 'year')}>
                <option value="" disabled>{t('user_creation_modal.birth_year')}</option>
                {range(1940, 2023).map(it => <option key={it} value={it}>{it}</option>)}
              </select>
            </div>
            <div className="input-field col s4">
              <select defaultValue={state.current['month']} className="browser-default"
                      required="required"
                      onChange={handleChange.bind(this, 'month')}>
                <option value="" disabled>{t('user_creation_modal.month')}</option>
                <option value="1">{t('month.january')}</option>
                <option value="2">{t('month.february')}</option>
                <option value="3">{t('month.march')}</option>
                <option value="4">{t('month.april')}</option>
                <option value="5">{t('month.may')}</option>
                <option value="6">{t('month.june')}</option>
                <option value="7">{t('month.july')}</option>
                <option value="8">{t('month.august')}</option>
                <option value="9">{t('month.september')}</option>
                <option value="10">{t('month.october')}</option>
                <option value="11">{t('month.november')}</option>
                <option value="12">{t('month.december')}</option>
              </select>
            </div>
            <div className="input-field col s4">
              <select onChange={handleChange.bind(this, 'day')} defaultValue={state.current['day']}
                      required="required"
                      className="browser-default" name="day"
                      id="day">
                <option value="" disabled>{t('user_creation_modal.day')}</option>
                {range(1, 31).map(it => <option key={it} value={it}>{it}</option>)}
              </select>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s6">
              <i className="material-icons prefix">badge</i>
              <input name="first_name" className="validate" required="required"
                     onChange={handleChange.bind(this, 'first_name')}
                     type="text"
                     defaultValue={state.current['first_name']}
                     id="first_name" minLength="3"/>
              <label htmlFor="first_name" className="active">
                {t('user_creation_modal.first_name')}
              </label>
              <span ref={errRefs['first_name']} className="helper-text"
                    data-error={t('user_creation_modal.first_name_error')}/>
            </div>
            <div className="input-field col s6">
              <i className="material-icons prefix">badge</i>
              <input name="last_name" className="validate" required="required"
                     onChange={handleChange.bind(this, 'last_name')}
                     type="text"
                     defaultValue={state.current['last_name']}
                     id="last_name" minLength="3"/>
              <label htmlFor="last_name" className="active">
                {t('user_creation_modal.second_name')}
              </label>
              <span ref={errRefs['last_name']} className="helper-text"
                    data-error={t('user_creation_modal.second_nam_error')}/>
            </div>
            {/*<div className="input-field col s4">
              <i className="material-icons prefix">badge</i>
              <input name="secondName" className="validate" required="required"
                     onChange={handleChange.bind(this, 'secondName')}
                     type="text"
                     defaultValue={state.current['secondName']}
                     id="secondName" minLength="3"/>
              <label htmlFor="secondName" className="active">{t('user_creation_modal.surname')}</label>
              <span ref={errRefs['secondName']} className="helper-text"
                    data-error={t('user_creation_modal.surname_error')}/>
            </div>*/}
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s6">
              <select name="country" className="browser-default" required="required"
                      onChange={handleChange.bind(this, 'country')}
                      defaultValue={state.current['country']}
                      id="country">
                <option value={-1}></option>
                {getCountries().map((it) => {
                  return <option key={it} value={it.replace('country.', '')}>{t(it)}</option>
                })}
              </select>
              <label htmlFor="country" className="active">{t('user_creation_modal.country')}</label>
              <span ref={errRefs['country']} className="helper-text"
                    data-error={t('user_creation_modal.country_error')}/>
            </div>
            <div className="input-field col s6">
              <i className="material-icons prefix">apartment</i>
              <input name="city" className="validate" required="required"
                     defaultValue={state.current['city']}
                     onChange={handleChange.bind(this, 'city')}
                     type="text"
                     id="city"/>
              <label htmlFor="city" className="active">{t('user_creation_modal.city')}</label>
              <span ref={errRefs['city']} className="helper-text" data-error={t('user_creation_modal.city_error')}/>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s6">
              <select defaultValue={state.current['gender']} onChange={handleChange.bind(this, 'gender')}
                      required="required"
                      className="browser-default">
                <option value="" disabled>{t('gender')}</option>
                <option value="0">{t('gender.male')}</option>
                <option value="1">{t('gender.female')}</option>
              </select>
            </div>
            <div className="input-field col s6">
              <select defaultValue={state.current['shirtSize']} className="browser-default"
                      onChange={handleChange.bind(this, 'shirtSize')}>
                <option value="" disabled>{t('shirt_size')}</option>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
                <option value="XXXL">XXXL</option>
              </select>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s6">
              <i className="material-icons prefix">people_outline</i>
              <input defaultValue={state.current['club']} className="validate" name="club" id="club"
                     onChange={handleChange.bind(this, 'club')}
                     type="text"/>
              <label htmlFor="club" className="active">{t('user_creation_modal.club')}</label>
              <span ref={errRefs['club']} className="helper-text" data-error={t('user_creation_modal.club_error')}/>
            </div>
            <div className="input-field col s6">
              <i className="material-icons prefix">groups</i>
              <input defaultValue={state.current['team']} name="team" className="validate" id="team"
                     onChange={handleChange.bind(this, 'team')}
                     type="text"/>
              <label htmlFor="team" className="active">{t('user_creation_modal.team')}</label>
              <span ref={errRefs['team']} className="helper-text" data-error={t('user_creation_modal.team_error')}/>
            </div>
          </div>
          <br/>
          <NovaposhtaSelect areaDefault={state.current['npAreaRef']}
                            cityDefault={state.current['npCityRef']}
                            warehouseDefault={state.current['npWarehouseRef']}
                            areaOnChange={setNpArea} cityOnChange={setNpCity}
                            warehouseOnChange={e => {
                              state.current['npWarehouse'] = $(e.target).find('option:selected').text()
                              state.current['npWarehouseRef'] = $(e.target).find('option:selected').val()
                            }}/>
          <br/>
          <div className="row">
            <div className="input-field col s12">
              <i className="material-icons prefix">contact_support</i>
              <input name="fio" required="required" className="validate" id="sos"
                     onChange={handleChange.bind(this, 'sos')}
                     defaultValue={state.current['sos']}
                     type="text"/>
              <label htmlFor="sos" className="active">{t('user_creation_modal.sos')}</label>
              <span ref={errRefs['sos']} className="helper-text" data-error={t('user_creation_modal.sos_error')}/>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="input-field col s12">
              <i className="material-icons prefix">mode_edit</i>
              <textarea id="about-me" className="materialize-textarea"
                        onChange={handleChange.bind(this, 'about')}
                        defaultValue={state.current['about'] ?? ''}/>
              <label className="active" htmlFor="about-me">{t('user_creation_modal.about')}</label>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col s6">
              <input required id="password" placeholder={t('user_creation_modal.password')} minLength="8"
                     name="password"
                     onChange={handleChange.bind(this, 'password')} defaultValue={state.current['password'] ?? ''}/>
              <label className="active" htmlFor="password">{t('user_creation_modal.password')}</label>
            </div>
            <div className="col s6">
              <input required id="repeat_password" placeholder={t('user_creation_modal.repeat_password')}
                     minLength="8"
                     name="repeat_password"
                     onChange={handleChange.bind(this, 'password_repeat')}
                     defaultValue={state.current['password_repeat'] ?? ''}/>
              <label className="active" htmlFor="repeat_password">{t('user_creation_modal.password')}</label>
            </div>
          </div>
          <div className="input-field">
            <input ref={submitRef} hidden={true} type="submit" name="submit"/>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          {t('user_creation_modal.close_btn')}
        </Button>
        <Button startIcon={<Add/>} onClick={()=>{
          submitRef.current.click()
        }}>
          {t('user_creation_modal.create_btn')}
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

export default CreateUserModal
