import React, { useLayoutEffect, useState } from 'react'
import PageLoader from '../../../common/component/PageLoader'
import {
  apiRequest,
  backendAddress,
  getErrorMessage,
  PATH_EVENT_INDEX,
  PATH_PARTICIPANT_EXPORT,
  PATH_PARTICIPANT_INDEX
} from '../../../common/backend'
import M from 'materialize-css'
import Participant from './Participant'
import ParticipantModal from '../edit/ParticipantModal'
import { ceil, clone, flatten, isEmpty, isNumber } from 'lodash'
import { range } from 'lodash/util'
import { paginateArray } from '../../../common/commons'
import axios from 'axios'
import DeleteConfirmation from './ParticipantDeleteConfirmation'
import { useTranslation } from 'react-i18next'
import ML from '../../../common/multilang'
import i18n from 'i18next'
import $ from 'jquery'
import { Button } from '@mui/material'
import { Add, Download } from '@mui/icons-material'
import { useAuth } from '../../../context/AuthContext'

const List = ({ eventId = null }) => {
  const [loading, setLoading] = useState(true)

  const [list, setList] = useState(/** @type Participant[] */[])
  const [events, setEvents] = useState(/** @type RaceEvent[] */[])
  const [races, setRaces] = useState(/** @type Race[] */[])

  const [event, setEvent] = useState(/** @type string|number */eventId ? eventId : '')
  const [race, setRace] = useState(/** @type string|number */'')
  const [search, setSearch] = useState(/** @type string */'')
  const [startNumber, setStartNumber] = useState(/** @type string */'')
  const [dateStart, setDateStart] = useState(/** @type string */'')
  const [dateEnd, setDateEnd] = useState(/** @type string */'')
  const [email, setEmail] = useState(/** @type string|number */'')

  const [page, setPage] = useState(1)
  const perPage = 35

  const role = useAuth().user?.role

  const { t } = useTranslation()

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editParticipant, setEditParticipant] = useState(/** @type ?Participant */null)

  const [deleteParticipant, setDeleteParticipant] = useState(/** @type ?Participant */null)

  useLayoutEffect(() => {
    let path = PATH_PARTICIPANT_INDEX + '?load_merch=1&load_linked=1' + (role === 2 ? '&my=1' : '')
    if (eventId) {
      path += '&event=' + eventId
    }
    const promises = []

    promises.push(
      apiRequest(path, res => {
        setList(res.data)
      }, e => {
        M.toast({ html: getErrorMessage(e), classes: 'rounded red large', displayLength: 15000 })
      }, {}, 'GET'))
    promises.push(
      apiRequest(PATH_EVENT_INDEX + '?showRaces=1' + (role === 2 ? '&my=1' : ''), res => {
        setRaces(flatten(res.data.map(it => it.races)))
        setEvents(res.data)
      }, e => {
        M.toast({ html: getErrorMessage(e), classes: 'rounded red large', displayLength: 15000 })
      }, {}, 'GET')
    )

    Promise.all(promises).finally(() => setLoading(false))
  }, [])

  useLayoutEffect(() => {
    if (eventId) {
      return
    }
    setRace('')
  }, [event])

  const getToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return yyyy + '-' +  mm + '-' + dd;
  }

  const getFiltered = () => {
    let result = list
    if (!isEmpty(email)) {
      result = result.filter(it => it.email.toLowerCase().includes(email.toLowerCase()))
    }
    if (!isEmpty(search)) {
      result = result.filter(participant => {
        return ['first_name', 'last_name'].some(param => participant[param]?.toLowerCase()?.includes(search.toLowerCase()))
      })
    }
    if (!isEmpty(startNumber)) {
      result = result.filter(participant => {
        return participant.start_number?.includes(startNumber)
      })
    }
    return result.filter(it => {
      if (isNumber(race) && it.race_id !== race) {
        return false
      }
      if (isNumber(event) && it.event_id !== event) {
        return false
      }
      if (!isEmpty(dateStart) || !isEmpty(dateEnd)) {
        let from = (isEmpty(dateStart) ? '1970-01-01' : dateStart) + ' 00:00:00';
        let to = (isEmpty(dateEnd) ? getToday() : dateEnd) + ' 23:59:59';
        return it.created_at >= from && it.created_at <= to
      }
      return true
    })
  }
  const getPaginated = () => paginateArray(getFiltered(), 35, page)

  const pageMax = ceil(getFiltered().length / perPage)

  const previousPage = () => {
    const newPage = page - 1
    setPage(newPage < 1 ? 1 : newPage)
  }
  const nextPage = () => {
    const newPage = page + 1
    setPage(newPage > pageMax ? pageMax : newPage)
  }

  const afterCreate = (/** @type Participant */added) => {
    const newList = clone(list)
    added.event = events.find(it => it.id === added.event_id)
    added.race = races.find(it => it.id === added.race_id)
    newList.push(added)
    setList(newList)
    setCreateModalOpen(false)
    if (!eventId) {
      setEvent(added.event_id)
    }
    setRace(added.race_id)
    setEmail(added.email)
  }

  const downloadFile = async () => {
    //Validate Event ID
    if (!isNumber(event)) {
      M.toast({ html: t('participant_list.choose_event_error'), classes: 'rounded red large' })
      return
    }

    //Validate Dates
    if (!isEmpty(dateStart) && dateStart > getToday()) {
      M.toast({ html: t('participant_list.date_start_in_future_error'), classes: 'rounded red large' })
      return
    }
    if (!isEmpty(dateStart) && !isEmpty(dateEnd) && dateStart > dateEnd) {
      M.toast({ html: t('participant_list.date_end_before_start_error'), classes: 'rounded red large' })
      return
    }

    try {
      const data = { event }
      if (race) {
        data.race = race
      }
      data.dateStart = dateStart;
      data.dateEnd = dateEnd;

      const query = $.param(data)
      const response = await axios.get(backendAddress + PATH_PARTICIPANT_EXPORT + query, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('api-key'),
          'Accept-Language': i18n.language
        }
      })
      const contentDisposition = response.headers['content-disposition']
      let filename = 'export.xlsx'

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/)
        if (match && match[1]) {
          filename = match[1]
          filename = decodeURIComponent(filename)
          filename = filename.replace('+', ' ')
        }
      }

      const fileContent = response.data
      const url = URL.createObjectURL(fileContent)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      M.toast({ html: getErrorMessage(error), classes: 'rounded red large' })
    }
  }

  const language = useTranslation().i18n.language

  const openParticipantCreationModal = () => {
    if (!isNumber(event)) {
      M.toast({ html: t('participant_list.choose_event_error'), classes: 'rounded red large' })
      return
    }
    if (!races.some(it => it.event === event && it.future)) {
      M.toast({
        html: t('participant_list.no_future_races_error', {
          name: ML.getStringForLanguage({
            multilangString: events.find(it => it.id === event).name,
            returnFirst: true,
            returnAllIfNotMultilang: true
          })
        }), classes: 'rounded red large'
      })
      return
    }
    setCreateModalOpen(true)
  }

  if (loading) {
    return <PageLoader/>
  }

  return <div style={{
    maxWidth: '100%',
    width: '80%',
    margin: 'auto'
  }}>
    <ParticipantModal
      initial={{ event_id: isNumber(event) ? event : '', race_id: isNumber(race) ? race : '', custom_fields: {} }}
      open={createModalOpen} afterCreate={afterCreate} close={() => setCreateModalOpen(false)}/>
    <ParticipantModal edit={true} initial={editParticipant} open={editParticipant !== null}
                      close={() => setEditParticipant(null)}/>
    <DeleteConfirmation success={id => setList(list.filter(it => it.id !== id))}
                        close={() => setDeleteParticipant(null)} participant={deleteParticipant}/>
    <div className="row">
      <div className="row">
        {eventId === null ? <div className="input-field col">
          <label className="active">{t('participant_list.event')}</label>
          <select className="browser-default" value={event} onChange={e => setEvent(parseInt(e.target.value) || '')}>
            <option value="">-</option>
            {events.map(it => {
              return <option value={it.id} key={it.id}>
                {ML.getStringForLanguage({ multilangString: it.name, language, returnFirst: true })}
              </option>
            })}
          </select>
        </div> : null}
        <div className="input-field col">
          <label className="active">{t('participant_list.race')}</label>
          <select onChange={(e) => {
            let val = e.target.value
            if (isEmpty(val)) {
              setRace('')
            } else {
              setRace(parseInt(e.target.value))
            }
          }} className="browser-default">
            <option value="">{isNumber(event) ? ' - ' : t('participant_list.choose_event_option')}</option>
            {races.filter(it => it.event === event).map(it => {
              return <option key={it.id} value={it.id}>
                {ML.getStringForLanguage({ multilangString: it.name, language, returnFirst: true })}
              </option>
            })}
          </select>
        </div>
        <div className="input-field col s3">
          <input id="start_number_filter" type="number" min={0} max={9999999} value={startNumber}
                 onChange={(e) => {
                   setPage(1)
                   setStartNumber(e.target.value || '')
                 }}/>
          <label htmlFor="start_number_filter">{t('participant_list.start_number')}</label>
        </div>
        <div className="input-field col">
          <input value={email} onChange={e => setEmail(e.target.value)} id="email" type="email"/>
          <label htmlFor="email">{t('participant_list.email_search')}</label>
        </div>
        <div className="input-field col s3">
          <input minLength="3" maxLength="255"
                 className="validate"
                 type="date"
                 id="date_start" name="date_start"
                 onChange={(e) => {
                   if (e.target.value > getToday()) {
                     M.toast({ html: t('participant_list.date_start_in_future_error'), classes: 'rounded red large' })
                   }
                   setDateStart(e.target.value)
                 }}
                 defaultValue=''/>
          <label htmlFor="date_start" className="active">{t('race_price.date_from')}</label>
        </div>
        <div className="input-field col s3">
          <input minLength="3" maxLength="255"
                 className="validate"
                 id="date_end" name="date_end"
                 type="date"
                 onChange={e => setDateEnd(e.target.value)}
                 defaultValue=''/>
          <label htmlFor="date_end" className="active">{t('race_price.date_to')}</label>
        </div>
        <div className="input-field col">
          <Button startIcon={<Add/>} color="primary" size="large" onClick={openParticipantCreationModal}>
            {t('participant_list.create_button')}
          </Button>
        </div>
        <div className="input-field col right">
          <Button startIcon={<Download/>} color="primary" size="large" onClick={downloadFile}>
            {t('participant_list.export_button')}
          </Button>
        </div>
      </div>
      <table className="striped">
        <thead>
        <tr>
          {['event', 'distance', 'start_number', 'surname_name', 'promo_code', 'final_sum', 'edit', 'merch', 'delete', 'created_at'].map(it =>
            <th key={it}>{t(`participant_list.${it}`)}</th>
          )}
        </tr>
        </thead>
        <tbody>
        {getPaginated().map(it => <Participant openDelete={it => setDeleteParticipant(it)}
                                               openEdit={it => setEditParticipant(it)} key={it.id} data={it}/>)}
        </tbody>
      </table>
      <ul className="pagination">
        <li className={page === 1 ? 'disabled' : 'waves-effect'}>
          <a href="#!" onClick={e => {
            e.preventDefault()
            previousPage()
          }}><i className="material-icons">chevron_left</i></a>
        </li>
        {range(1, pageMax + 1).map(it => {
          return <li key={it} className={it === page ? 'active' : ''}>
            <a onClick={e => {
              e.preventDefault()
              setPage(it)
            }} href="#!">{it}</a>
          </li>
        })}
        <li className={page === pageMax ? 'disabled' : 'waves-effect'}>
          <a href="#!" onClick={e => {
            e.preventDefault()
            nextPage()
          }}><i className="material-icons">chevron_right</i></a>
        </li>
      </ul>
    </div>
  </div>
}
export default List
