import TextEditor from '../../../common/component/TextEditor'
import {
    apiRequest,
    PATH_GET_LANGUAGE_LIST,
    PATH_TEXT_BLOCK_CREATE,
    PATH_TEXT_BLOCK_DELETE,
    PATH_TEXT_BLOCK_UPDATE,
    PATH_TEXT_BLOCK_VIEW
} from '../../../common/backend'
import React, {ChangeEvent, FormEvent, MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react'
import PageLoader from '../../../common/component/PageLoader'
import $ from 'jquery'
import M from 'materialize-css'
import {useParams} from 'react-router'
import {useTranslation} from 'react-i18next'
import {isString} from 'lodash'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DeleteForever, Done} from "@mui/icons-material";
import TranslatableTextInput from "../../../common/component/TranslatableTextInput";

interface TextPageProps {
    id: number | 'new',
    add: (textBlock: TextBlock) => void,
    remove: (id: number) => void,
}

interface EditorRef {
    save: (name: string | null, event: number | null, race: number | null) => Promise<void>,
    getHtml: () => string
}

const TextPage = ({id, add, remove}: TextPageProps): ReactElement => {
    let isNew: boolean = id === 'new'
    const {t} = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(!isNew)
    const [removeOpen, setRemoveOpen] = useState<boolean>(false)
    const editorRef: MutableRefObject<EditorRef | null> = useRef<EditorRef>(null)

    const state: MutableRefObject<TextBlock> = useRef<TextBlock>({
        name: '',
        text: '',
        race: parseInt(useParams().id as string),
        immutable: false
    })

    const [language, setLanguage] = useState<string>(useTranslation().i18n.language)

    const [list, setList] = useState<string[] | null>(null)
    useEffect((): void => {
        apiRequest(PATH_GET_LANGUAGE_LIST, (res: { data: { [k: string]: string } }): void => {
            setList(Object.keys(res.data))
        })
    }, [])

    useEffect((): void => {
        if (!isNew) {
            apiRequest(PATH_TEXT_BLOCK_VIEW + id, (res: { data: TextBlock }): void => {
                state.current = res.data
                setLoading(false)
                // @ts-ignore
            }, undefined, undefined, 'get')
        }
    }, [id])

    const save = (): void => {
        setLoading(true)
        const promise: Promise<void> = editorRef.current!!.save(null, null, state.current.race)
        promise.then((): void => {
            if (isNew) {
                apiRequest(PATH_TEXT_BLOCK_CREATE, (res: { data: TextBlock }): void => {
                    add(res.data)
                    M.toast({html: t('race_text.creation_success'), classes: 'rounded green large'})
                    // @ts-ignore
                }, (): void => {
                    M.toast({html: t('race_text.creation_error'), classes: 'rounded red large'})
                }, state.current)
            } else {
                apiRequest(PATH_TEXT_BLOCK_UPDATE + id, (): void => {
                    M.toast({html: t('race_text.save_success'), classes: 'rounded green large'})
                    // @ts-ignore
                }, (): void => {
                    M.toast({html: t('race_text.save_fail'), classes: 'rounded red large'})
                }, state.current, 'put')
            }
        }).finally((): void => {
            setLoading(false)
        })
    }

    const handleChange = (name: 'text' | 'name', e: string | ChangeEvent<HTMLInputElement>): void => {
        state.current[name] = isString(e) ? e : $(e.target!!).val()!!
    }

    const removeTextBlock = (): void => {
        setLoading(true)
        apiRequest(PATH_TEXT_BLOCK_DELETE + id, (): void => {
            remove(id as number)
            // @ts-ignore
        }, (e): void => {
            console.log(e)
        }, undefined, 'delete')!!
            .finally((): void => {
                setLoading(false)
            })
    }

    if (isLoading) {
        return <PageLoader/>
    }

    return <div style={{
        maxWidth: '100%',
        width: '80%',
        margin: 'auto'
    }}>
        <Dialog open={removeOpen} onClose={(): void => setRemoveOpen(false)}>
            <DialogTitle>
                {t('race_text.delete_dialog.header')}
            </DialogTitle>
            <DialogContent>
                {t('race_text.delete_dialog.text')}
            </DialogContent>
            <DialogActions>
                <Button onClick={(): void => setRemoveOpen(false)}>
                    {t('race_text.delete_dialog.cancel_btn')}
                </Button>
                <Button onClick={removeTextBlock}>
                    {t('race_text.delete_dialog.delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
        <form id={'text-block-' + id} onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault()
            save()
        }}>
            <div className="row">
        <span>
          <select className="browser-default" value={language}
                  onChange={(e: ChangeEvent<HTMLSelectElement>): void => setLanguage(e.target.value)}>
            {list?.map((it: string): ReactElement => <option key={it} value={it}>{it}</option>)}
          </select>
        </span>
                {!isNew && !state.current.immutable &&
                  <Button onClick={(): void => setRemoveOpen(true)} color='primary' endIcon={<DeleteForever/>}>
                      {t('race_text.delete_btn')}
                  </Button>
                }
                <div className="input-field col s12">
                    <TranslatableTextInput initialValue={state.current.name} classes='' update={(it: string): void => {
                        handleChange('name', it)
                    }} immutable={state.current.immutable} language={language} name={t('race_text.name')}/>
                </div>
                <div className="input-field col s12">
                    <span className="h4">{t('race_text.text')}</span>
                    <br/>
                    {/* @ts-ignore */}
                    <TextEditor ref={editorRef} triggerName="text" handleChange={handleChange}
                                language={language} defaultText={state.current.text}
                    />
                </div>
            </div>
            <br/>
            <div className="input-field">
                <Button type='submit' endIcon={<Done/>}>
                    {t('race_text.save_btn')}
                </Button>
            </div>
        </form>
    </div>
}
export default TextPage
