import React from 'react'
import {CommonInputProps} from "./DefaultInput";

const ImmutableInput = ({id, desc, type, value}: CommonInputProps) => {
    return <>
        <i className="material-icons prefix">format_list_numbered</i>
        <input id={`input-${id}`} role="presentation" readOnly={true} autoComplete="none" name={id} type={type}
               value={value || ''}/>
        <label htmlFor={`input-${id}`} className="active">{desc}</label>
    </>
}
export default ImmutableInput