import React, {ReactElement} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";

const containerStyle = {
    width: (window.innerWidth - 50) + 'px',
    height: (window.innerHeight - 90) + 'px'
};

const defaultCenter: google.maps.LatLngLiteral = {
    lat: 50.0051452, lng: 36.2298394,
}

interface LocationSelectorProps {
    center: google.maps.LatLngLiteral | null;
    width: number; // px
    height: number; // px
    onMarkerDrag: (loc: google.maps.LatLng) => void;
}

const LocationSelector = ({
                              center,
                              height,
                              width,
                              onMarkerDrag,
                          }: LocationSelectorProps): ReactElement => {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
    })
    center ??= defaultCenter

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    }, [])

    return isLoaded ?
        <GoogleMap
            mapContainerStyle={{width:`${width} px`,height:`${height}px`}}
            center={center!}
            zoom={10}
            onLoad={onLoad}
        >
            <Marker draggable={true} position={center!} onDragEnd={(e: google.maps.MapMouseEvent): void => {
                const pos: google.maps.LatLng | null = e.latLng
                if (pos) {
                    onMarkerDrag(pos)
                }
            }}/>
        </GoogleMap> : <></>
}

export default React.memo(LocationSelector)
