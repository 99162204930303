//Страница создания ивента
import Body from './Body'
import React, { useRef, useState } from 'react'
import { apiRequest, PATH_EVENT_CREATE } from '../../common/backend'
import $ from 'jquery'
import PageLoader from '../../common/component/PageLoader'
import { useNavigate } from 'react-router'
import { getCurrentDateTime } from '../../common/commons'
import { useTranslation } from 'react-i18next'
import { isString } from 'lodash'

const Create = () => {
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  const errRefs = {
    'name': useRef(),
    'sponsor': useRef(),
    'description': useRef(),
    'schedule': useRef(),
    'city': useRef(),
    'address': useRef(),
    'date_register_start': useRef(),
    'date_register_end': useRef(),
    'date_start_at': useRef()
  }
  const descriptionRef = useRef()
  const scheduleRef = useRef()

  const state = useRef({
    date_register_end: getCurrentDateTime(),
    date_register_start: getCurrentDateTime(),
    date_start_at: getCurrentDateTime(),
    description: '',
    schedule: '',
  })

  const nav = useNavigate()

  const handleCreate = (e) => {
    e.preventDefault()
    console.log(state.current)
    setLoading(true)
    const promise = Promise.all([descriptionRef.current.save(null, null, state.current.race), scheduleRef.current.save(null, null, state.current.race)])
    promise.then(() => {
      apiRequest(PATH_EVENT_CREATE, res => {
        $('#create-event input').removeClass('valid').removeClass('invalid')
        setLoading(false)
        nav('/admin/events/edit/' + res.data['id'])
      }, r => {
        for (const err of r.response.data) {
          $(errRefs[err['field']].current).attr('data-error', err['message'])
          $('#create-event input[name="' + err['field'] + '"]').removeClass('valid').addClass('invalid')
        }
        setLoading(false)
      }, state.current, 'post')
    })
  }

  // noinspection DuplicatedCode
  const handleChange = (name, e) => {
    let value = isString(e) ? e : $(e.target).val()
    if (name.includes('date')) {
      value = value + ' ' + state.current[name].split(' ')[1]
    }
    if (name.includes('time')) {
      name = name.replace('time', 'date')
      value = state.current[name].split(' ')[0] + ' ' + value + ':00'
    }
    state.current[name] = value
  }

  const loading = () => {
    if (isLoading) {
      return <PageLoader/>
    } else {
      return ''
    }
  }

  const language = useTranslation().i18n.language

  return <>
    {isLoading && <PageLoader/>}
    <div className="center">
      <h4>{t('event.create.header')}</h4>
      <br/>
      <div style={{
        maxWidth: '100%',
        width: '80%',
        margin: 'auto'
      }}>
        <form id="create-event" onSubmit={handleCreate.bind(this)} action="#">
          <Body language={language} descriptionRef={descriptionRef} scheduleRef={scheduleRef} state={state}
                errRefs={errRefs} handleChange={handleChange}/>
          <div className="input-field">
            <button type="submit" className="btn btn-large btn-register waves-effect waves-light"
                    name="action">
              {t('event.create.create_btn')}
              <i className="material-icons right">done</i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </>
}
export default Create
