import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import axios from 'axios'
import { backendAddress } from '../backend'
import ML from '../multilang'

function readFileAsBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

const TextEditor = forwardRef(({ handleChange, triggerName, defaultText, id = '', language }, ref) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
    ML.getStringForLanguage({ multilangString: defaultText, language }) || ''
  ))))
  const allText = useRef(defaultText)
  const imagesToUpload = useRef({})

  const onEditorStateChange = (editorState) => {
    // $('#text-editor-content-' + id).val(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    let newValue = allText.current

    newValue = ML.updateLanguage(newValue, draftToHtml(convertToRaw(editorState.getCurrentContent())), language)
    allText.current = newValue
    // console.log(newValue)
    handleChange(triggerName, newValue)
    setEditorState(editorState)
  }

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
      ML.getStringForLanguage({ multilangString: defaultText, language }) || ''
    ))))
  }, [language])

  const uploadCallback = (file) => new Promise(
    async (resolve) => {
      const base64 = await readFileAsBase64(file)
      imagesToUpload.current[base64] = file
      resolve({ data: { link: base64 } })
    }
  )

  /**
   * @param name {string|null} Название файла
   * @param event {number|null} ИД ивента (опционально)
   * @param race {number|null} ИД забега (опционально)
   * @return {void} ХТМЛ элемент для сохранения на сервере, массив промисов (один для каждого файла)
   * Необходимо дождаться загрузки их всех и использовать новый хтмл код, где base64 будет заменено на ссылки
   */
  const uploadImages = async (name = null, event = null, race = null) => {
    const el = document.createElement('div', {})
    el.innerHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const promises = []
    el.querySelectorAll('img').forEach(it => {
      if (it.src.includes('base64')) {
        const data = new FormData
        data.set('file', imagesToUpload.current[it.src])
        if (event) {
          data.set('event', event.toString())
        }
        if (name) {
          data.set('name', name)
        }
        if (race) {
          data.set('race', race.toString())
        }
        const request = axios.post(
          backendAddress + 'file',
          data,
          {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('api-key') }
          }
        ).then(res => it.src = res.data.link)
        promises.push(request)
      }
    })
    await Promise.all(promises)
    onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
      ML.getStringForLanguage({ multilangString: el.innerHTML, language }) || ''
    ))))
  }

  useImperativeHandle(ref, () => {
    return {
      save: uploadImages,
      setText: (e) => console.log(e),
      getHtml: () => draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }
  })

  return <div className="editor">
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        image: {
          uploadCallback,
          previewImage: true,
          alt: { present: true, mandatory: false },
          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        },
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true }
      }}
    />
    <textarea hidden="hidden" id={'text-editor-content-' + id}/>
  </div>
})

export default TextEditor
