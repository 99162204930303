import i18n from 'i18next'
import worker from '../web-worker/decryptFileWorker'
import {SymmetricKey} from "./crypt";

// different functions used in one or several places

const getCurrentDateTime = (): string => {
    const currentDate: Date = new Date()
    //yyyy-mm-dd HH-mm-ss
    let seconds: number | string = currentDate.getSeconds()
    if (seconds < 10) seconds = '0' + seconds
    let minutes: number | string = currentDate.getMinutes()
    if (minutes < 10) minutes = '0' + minutes
    return currentDate.getFullYear() + '-'
        + (currentDate.getMonth() + 1) + '-'
        + currentDate.getDate() + ' '
        + currentDate.getHours() + ':'
        + minutes + ':'
        + seconds
}

/**
 * @param number Number
 * @param percent Percent of number to get
 * @return percent of total, getPercentage(100, 10) = 10
 */
function getPercentage(number: number, percent: number): number {
    return Math.round(number / 100 * percent)
}

/**
 * @return string[]
 */
function getCountries(): string[] {
    const translations = i18n.getResourceBundle(i18n.language, 'translation')
    return Object.keys(translations).filter((it: string): boolean => {
        return it.startsWith('country.')
    }).sort()
}

function sleep(millis: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, millis));
}

function generateRandomString(length: number): string {
    if (length <= 0) {
        throw new Error('length <= 0')
    }
    const arr: Uint8Array = new Uint8Array((length || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, (dec: number): string => {
        return dec.toString(16).padStart(2, '0')
    }).join('').toString()
}

/**
 * @param array
 * @param itemsPerPage
 * @param currentPage Current page should start from 1
 */
function paginateArray<T>(array: T[], itemsPerPage: number, currentPage: number): T[] {
    if (currentPage < 1) {
        throw Error(`currentPage should start from 1, ${currentPage} given`)
    }
    if (itemsPerPage <= 0) {
        throw Error('itemsPerPage <= 0')
    }
    const startIndex: number = (currentPage - 1) * itemsPerPage
    const endIndex: number = startIndex + itemsPerPage
    return array.slice(startIndex, endIndex)
}

function getAge(dateString: string): number {
    const today: Date = new Date()
    const birthDate: Date = new Date(dateString)
    if (today <= birthDate) {
        return 0
    }
    let age: number = today.getFullYear() - birthDate.getFullYear()
    const m: number = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

export {
    getCurrentDateTime,
    generateRandomString,
    paginateArray,
    getAge,
    getCountries,
    getPercentage,
    sleep,
}