import {Fab} from "@mui/material";
import {Save} from "@mui/icons-material";
import {CSSProperties, ReactElement} from "react";

interface FixedSaveButtonProps {
    action: () => void
}

const style: CSSProperties = {
    position: 'fixed',
    right: '10px',
    bottom: '10px'
}

const FixedSaveButton = ({action}: FixedSaveButtonProps): ReactElement => {
    return <Fab color='primary' sx={style} onClick={action}>
        <Save/>
    </Fab>
}

export default FixedSaveButton
